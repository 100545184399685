import React from 'react';
import { IModalProps } from '../modal.types';
import { Modal } from '../Modal';
import { ForexInstruction } from '../../Instructions/ForexInstruction/ForexInstruction';

export const ForexInstructionModal: React.FC<IModalProps> = ({
	isOpen,
	onClose
}) => {
	if (!isOpen) {
		return null;
	}

	return <Modal onClose={onClose}>
		<ForexInstruction close={onClose} />
	</Modal>;
};
