import React from 'react';
import { IUpdateFormData } from '../Forms/UpdateKeyForm/updateKeyForm.types';
import { ICommissionParams } from 'types/apiKeys.types';

export enum EKeyStatuses {
	WORKING = 'WORKING',
	STOPPED = 'STOPPED',
	WAITING = 'WAITING',
}

export interface IKeyStatus {
	status: EKeyStatuses,
	statusText: string;
	actionText: string;
	action: (e: React.MouseEvent<HTMLButtonElement>) => void;
	actionDescription: string;
}

export interface IKeyProps {
	id: number;
	keyStatus: IKeyStatus | null;
	autoPayCommission: boolean;
	name: string;
	expired: string;
	publicKey: string;
	lastCheckCommission: number;
	payDate?: number | undefined;
	usdtCommission?: number;
	fccCommission?: number;
	changeAutoPayCommission: () => void;
	needUpdate: boolean;
	isActive?: boolean;
	is_blocked: boolean;
	updateKey?: () => void;
	autoExtension?: boolean;
	changeAutoExtension: (autoExtension: boolean) => void;
	extendActivation: () => void;
	payCommission?: () => void;
}
