import { RootState } from 'store/store';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { IBalance, IConversionRequest } from 'types/balance.types';
import { EBalanceSymbol, IGetCode, IReport } from 'types/common.types';
import { ICreateHoldRequest, IHold, IHoldCommission, ITransactionFinance, IWallet } from 'types/bank.types';

export const bankAPI = createApi({
	reducerPath: 'bankAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: `https://${CONFIG.api}/bank/`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).auth.token;
			if (token) {
				headers.set('authorization', `Bearer ${token}`);
			}
			return headers;
		}
	}),
	tagTypes: ['Balance', 'Holds'],
	endpoints: (build) => ({
		fetchBalance: build.query<IBalance[], null>({
			query: () => ({
				url: 'balance/'
			}),
			transformResponse(res: IBalance[]) {
				const arr = [EBalanceSymbol.USDT, EBalanceSymbol.FT];
				return res.filter((b: IBalance) => arr.includes(b.token_symbol));
			},
			providesTags: result => ['Balance']
		}),
		conversion: build.mutation<number, IConversionRequest>({
			query: (body: IConversionRequest) => ({
				url: 'conversion/',
				method: 'POST',
				body,
			}),
			transformResponse(res: number) {
				return res;
			},
			invalidatesTags: ['Balance']
		}),
		fetchHolds: build.query<IHold[], null>({
			query: () => ({
				url: 'hold/'
			}),
			providesTags: result => ['Holds']
		}),
		fetchHoldCommissions: build.query<IHoldCommission[], null>({
			query: () => ({
				url: 'hold_commission/',
			}),
		}),
		wallets: build.query<IWallet[], null>({
			query: () => ({
				url: 'wallets/'
			})
		}),
		getReports: build.query<IReport[], null>({
			query: () => ({
				url: 'profit/'
			})
		}),
		getTransactionAll: build.query<ITransactionFinance[], null>({
			query: () => ({
				url: '/transactions/'
			})
		}),
		getCode: build.mutation<{sent: boolean}, IGetCode>({
			query: (body: IGetCode) => ({
				url: 'withdraw_code/',
				method: 'POST',
				body,
			}),
		}),
		setHold: build.mutation<IHold, ICreateHoldRequest>({
			query: (body: ICreateHoldRequest) => ({
				url: 'hold/',
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Holds']
		}),
	})
	
});
