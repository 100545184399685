import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { checkToken } from '../../helpers/token';
import {
	Route,
	Routes,
	useNavigate
} from 'react-router-dom';
import { Main } from '../Main/Main';
import { Login } from '../Login/Login';
import { Layout } from '../../components/Layout/Layout';
import { Refil } from '../Refil/Refil';
import { Finance } from '../Finance/Finance';
import { Partners } from '../Partners/Parftners';
import { Politics } from '../Politics/Politics';
import { useTranslation } from 'react-i18next';
import { Spot } from '../Spot/Spot';
import { FuturesPage } from '../Futures/Futures';
import { ProfilePage } from '../Profile/Profile';
import { ForexPage } from '../Forex/Forex';
import { HoldsPage } from '../Holds/Holds';
import { Reports } from '../Reports/Reports';
import { Documents } from '../Documents/Documents';
import { TradingFutures } from '../TradingFutures/TradingFutures';
import { TradingSpot } from '../TradingSpot/TradingSpot';
import { userAPI } from 'services/userService';

const Exchange = React.lazy(() => import('../Exchange/Exchange'));

export const App: React.FC = () => {

	const {i18n} = useTranslation();
	const token = useSelector((state: RootState) => state.auth.token);
	const navigate = useNavigate();

	const {data: profile, isLoading: profilePending, refetch: refetchProfile} = userAPI.useFetchProfileQuery('');
	const [changeProfileLang] = userAPI.useChangeProfileLangMutation();

	React.useEffect(() => {
		const locationArr = location.pathname.split('/');
		if (locationArr[1] === 'assets') {
			return;
		}
		 const lang = locationArr[1];
		 if (!lang) {
			navigate(`${i18n.language}/`);
		 }

		if (lang && lang === 'ru' || lang === 'en') {
			i18n.changeLanguage(lang);
		} else {
			navigate(`${i18n.language}/${locationArr.slice(1).join('/')}`);
		}

	}, []);

	React.useEffect(() => {
		const locationArr = location.pathname.split('/');
		if (locationArr[1] === 'assets') {
			return;
		}
		if (locationArr[1] !== i18n.language) {
			navigate(`${i18n.language}/${locationArr.slice(2).join('/')}`);
		}

	}, [i18n.language]);

	React.useEffect(() => {
		const locationArr = location.pathname.split('/');
		if (
			locationArr[1] === 'assets' ||
			locationArr[2] === 'holds' ||
			locationArr[2] === 'reports' ||
			locationArr[2] === 'documents' ||
			locationArr[2] === 'change-password') {
			return;
		}
		const expiredTime = checkToken(token);
		let timeout: NodeJS.Timeout | number | null = null;
		console.log('token expired in ', expiredTime / 1000 / 60/60, ' hours');
		if (token && expiredTime > 0) {
			timeout = setTimeout(() => {
				console.log('expired');
			}, expiredTime);
		} else {
			navigate(`${i18n.language}/login`, {replace: true});
		}
		return () => {
			if (typeof timeout === 'number') {
				clearTimeout(timeout);
			}
		};
	}, []);

	React.useEffect(() => {
		if (!profile) {
			return;
		}

		if (profile.lang) {
			i18n.changeLanguage(profile.lang);
		} else {
			changeProfileLang('ru');
		}
	}, [profile]);

	return <React.StrictMode>
		<React.Suspense fallback='loading'>
			<Routes>
				<Route path=':lang/' element={<Layout />}>
					<Route index element={<Main />} />
					<Route path='profile' element={<ProfilePage />} />
					<Route path='refil' element={<Refil />} />
					<Route path='finance/*' element={<Finance />} />
					<Route path='partners' element={<Partners />} />
					<Route path='politics' element={<Politics />} />
					<Route path='spot' element={<Spot />}></Route>
					<Route path='futures' element={<FuturesPage />} />
					<Route path='forex' element={<ForexPage />} />
					<Route path='trading' element={<TradingFutures />} />
					<Route path='trading-spot' element={<TradingSpot />} />
					{/*<Route path='admin' element={<Admin />} />*/}
				</Route>
				<Route path=':lang/login/*' element={<Login />} />
				{/*<Route path=':lang/change-password/*' element={<ChangePasswordPage />} />*/}
				<Route path=':lang/holds' element={<HoldsPage />} />
				<Route path=':lang/reports' element={<Reports />} />
				<Route path=':lang/documents' element={<Documents />} />
			</Routes>
		</React.Suspense>

	</React.StrictMode>;
};
