import React, { useEffect, useMemo, useState } from 'react';
import styles from './SpotOrderList.module.scss';
import cn from 'classnames';
import { spotAPI } from 'services/spotService';
import { Text, Headline, Button } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';
import { Loader } from 'src/components/Loader/Loader';
import { SpotOrder } from '../SpotOrder/SpotOrder';
import { MobileSpotOrder } from '../SpotOrder/MobileSpotOrder';
import { CrossIcon16, DollarIcon16 } from 'forly-kit/dist/icons';
import { ConfirmModal } from '../../../components/Modals/ConfirmModal/ConfirmModal';
import { ISpotOrderList } from './SpotOrderList.types';

const List: React.FC<ISpotOrderList> = ({
	cancelOrder,
	cancelOrders,
	sellAll,
}) => {
	const {data: orders, refetch: refetchOrders} = spotAPI.useGetSpotOrdersQuery(null);
	const {data: keys} = spotAPI.useFetchSpotKeysQuery(null);
	// const [sellAll, {isSuccess: successClose, isError: closeError, isLoading: sellLoading}] = spotAPI.useSellAllMutation();
	// const [deleteAll, {isSuccess: deleteAllSuccess, isError: deleteError, isLoading: closeLoading}] = spotAPI.useDeleteOrdersMutation();

	const [closeType, setCloseType] = useState<string>('');
	const [error, setError] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	// useEffect(() => {
	// 	if (deleteAllSuccess) {
	// 		refetchOrders();
	// 		setCloseType('');
	// 	}
	// }, [deleteAllSuccess]);

	// useEffect(() => {
	// 	if (successClose) {
	// 		refetchOrders();
	// 		setCloseType('');
	// 	}
	// }, [successClose]);

	// useEffect(() => {
	// 	if (closeError) {
	// 		setError('Не удалось закрыть ордера');
	// 	}
	// }, [closeError]);
	//
	// useEffect(() => {
	// 	if (deleteError) {
	// 		setError('Не удалось продать все');
	// 	}
	// }, [deleteError]);

	// const getTokenId = useMemo((): (number | null) => {
	// 	if (!keys || !keys.length) {
	// 		return null;
	// 	}
	//
	// 	const key = keys.find((k) => k.is_active);
	// 	if (!key) {
	// 		return null;
	// 	}
	// 	return key.id;
	// }, [keys]) ;

	const setLoadingHandler = () => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
			closeConfirm();
		}, 1500);
	};

	const openSellModal = () => {
		setCloseType('SELL');
	};

	const sellAllHandler = () => {
		setError('');
		sellAll();
		setLoadingHandler();
		// if (getTokenId) {
		// 	sellAll(getTokenId);
		// }
	};

	const openDeleteModal = () => {
		setCloseType('CLOSE');
	};

	const deleteAllHandler = () => {
		setError('');
		cancelOrders();
		setLoadingHandler();
		// if (getTokenId) {
		// 	deleteAll(getTokenId);
		// }
	};

	const closeConfirm = () => {
		setCloseType('');
		setError('');
	};

	if (!orders) {
		return <Loader />;
	}
    
	return <>
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<Headline type='h2'>Список ордеров</Headline>
				<Button
					variant='tertiary'
					text='Продать все'
					icon={{component: DollarIcon16}}
					onClick={openSellModal}
				/>
				<Button
					variant='tertiary'
					text='Отмена всех ордеров'
					icon={{component: CrossIcon16}}
					onClick={openDeleteModal}
				/>
			</div>
			<div className={cn('card cardContent')}>
				<div className={styles.wrapperList}>
					<Text size='s' color={colors.neutral600}>Валютная пара</Text>
					<Text size='s' color={colors.neutral600}>Статус</Text>
					<Text size='s' color={colors.neutral600}>Тип ордeра</Text>
					<Text size='s' color={colors.neutral600}>Направлeниe</Text>
					<Text size='s' color={colors.neutral600}>Объем</Text>
					<Text size='s' color={colors.neutral600}>Цена</Text>
					<Text size='s' color={colors.neutral600}>Дата</Text>
					<Text className={styles.emptyHeader} size='s' color={colors.neutral600}></Text>
					<div className={styles.divider}></div>
					{orders.map((o) => <SpotOrder key={o.id} order={o} cancelOrder={cancelOrder}/>)}
				</div>

				<div className={styles.mobileWrapper}>
					{orders.map((o) => <MobileSpotOrder key={o.id} order={o} cancelOrder={cancelOrder}/>)}
				</div>
			</div>
		</div>

		<ConfirmModal
			isOpen={!!closeType}
			onClose={closeConfirm}
			confirm={closeType === 'SELL' ? sellAllHandler : deleteAllHandler}
			title={closeType === 'SELL' ? 'Продать все' : 'Закрыть все ордера'}
			subtitle={closeType === 'SELL' ? 'Все монеты будут проданы за busd' : 'Все ордера будут закрыты'}
			error={error}
			isLoading={isLoading}
		/>
	</>;
};

export const SpotOrderList = React.memo(List);
