// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mobileOrder--ddCB2{display:grid;grid-template-columns:repeat(2, auto);margin-right:24px;column-gap:16px;row-gap:8px}.divider--yK6J4{width:100%;height:1px;background:#ced5f5;grid-column:1/-1}@media (max-width: 1365px){.closeBtn--YLb5o{grid-column:1/-1;justify-self:end}}@media (max-width: 768px){.closeBtn--YLb5o{grid-column:1/-1;justify-self:start}}\n", "",{"version":3,"sources":["webpack://./../src/pages/TradingSpot/SpotOrder/SpotOrder.module.scss"],"names":[],"mappings":"AAIA,oBACC,YAAa,CACb,qCAAsC,CACtC,iBAAkB,CAClB,eAAgB,CAChB,WAAY,CACZ,gBAGA,UAAW,CACX,UAAW,CACX,kBAAmB,CACnB,gBAAiB,CACjB,2BAGA,iBACC,gBAAiB,CACjB,gBAAiB,CACjB,CAGF,0BACC,iBACC,gBAAiB,CACjB,kBAAmB,CACnB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.mobileOrder {\n\tdisplay: grid;\n\tgrid-template-columns: repeat(2, auto);\n\tmargin-right: 24px;\n\tcolumn-gap: 16px;\n\trow-gap: 8px;\n}\n\n.divider {\n\twidth: 100%;\n\theight: 1px;\n\tbackground: #ced5f5;\n\tgrid-column: 1/-1;\n}\n\n@media (max-width: 1365px) {\n\t.closeBtn {\n\t\tgrid-column: 1/-1;\n\t\tjustify-self: end;\n\t}\n}\n\n@media (max-width: 768px) {\n\t.closeBtn {\n\t\tgrid-column: 1/-1;\n\t\tjustify-self: start;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileOrder": "mobileOrder--ddCB2",
	"divider": "divider--yK6J4",
	"closeBtn": "closeBtn--YLb5o"
};
export default ___CSS_LOADER_EXPORT___;
