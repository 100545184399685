import React, { useState } from 'react';
import styles from './autoCommissionConfirmModal.module.scss';
import { IAutoCommissionConfirmModal } from './autoCommissionConfirm.types';
import { Modal } from '../../Modal';
import { Button, Headline, Text} from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';

const ModalComponent: React.FC<IAutoCommissionConfirmModal> = ({
	onClose,
	isOpen,
	title,
	action,
	subtitle,
	cancel,
	actionText
}) => {

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string>('');

	const confirmHandler = async () => {
		setIsLoading(true);

		const res = await action();
		// @ts-ignore
		if (res && res.data) {
			onClose();
		}

		// @ts-ignore
		if (res && res.error) {
			// @ts-ignore
			setError(res.error.data.detail);
		}

		setIsLoading(false);
	};

	if (!isOpen) {
		return null;
	}

	return <Modal onClose={onClose}>
		<div className={styles.wrapper}>
			<div className={styles.header}>
				<Headline type='h3'>
					{title}
				</Headline>
				<Text size='m' color={colors.neutral600}>
					{subtitle}
				</Text>
			</div>
			{error && <Text className={styles.error} size='s' color={colors.danger}>{error}</Text>}
			{!cancel
				? <Button onClick={confirmHandler} variant='primary' text={actionText} fill disabled={isLoading} />
				: <div className={styles.footer}>
					<Button onClick={onClose} variant='primary' text={cancel} fill disabled={isLoading} />
					<Button onClick={confirmHandler} variant='secondary' text={actionText} fill disabled={isLoading} />
				</div>
			}

		</div>
	</Modal>;
};

export const AutoCommissionConfirmModal = React.memo(ModalComponent);
