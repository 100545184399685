import React, { useEffect, useState } from 'react';
import styles from './confirmCode.module.scss';
import { IConfirmCode } from './confirmCode.types';
import { Modal } from '../Modal';
import { Button, Headline, Input, Text } from 'forly-kit';
import { useTranslation } from 'react-i18next';
import { bankAPI } from 'services/bankService';

const ModalComponent: React.FC<IConfirmCode> = ({
	onClose,
	type,
	isOpen,
	error,
	confirm,
	isPending,
	cancel
}) => {
	const {t} = useTranslation();
	const [code, setCode] = useState<string>('');
	const [lostTime, setLostTime] = useState<number>(60);
	const [localTimer, setLocalTimer] = useState<any>(null);

	const [getCode, {isLoading: codePending, isSuccess: successGetCode}] = bankAPI.useGetCodeMutation();

	const createTimer = () => {
		setLostTime(60);
		const timer = setInterval(() => {
			setLostTime((prev) => prev - 1);
		}, 1000);
		setLocalTimer(timer);
	};

	useEffect(() => {
		if (isOpen) {
			createTimer();
		}
	}, [isOpen]);

	useEffect(() => {
		if (lostTime <=0) {
			clearInterval(localTimer);
			setLocalTimer(null);
		}
	}, [lostTime]);

	const changeHandler = (e: any) => {
		const value = e.currentTarget.value;
		setCode(value);
	};

	const resendCode = () => {
		getCode({
			is_withdraw: false,
			type
		});
		createTimer();
	};

	const confirmHandler = () => {
		confirm(code);
	};

	if (!isOpen) {
		return null;
	}

	return <Modal onClose={onClose}>
		<div className={styles.wrapper}>
			<Headline type='h3'>
				{t('confirmHoldTile')}
			</Headline>
			<Text size='m'>
				{
					type === 'telegram'
						? t('telegramCodeConfirm')
						: t('phoneCodeConfirm')
				}
			</Text>
			<div className={styles.input}>
				<Input
					label={type === 'telegram' ? t('inputTelegramCode') : t('phoneCode')}
					desc={(lostTime && !error) ? t('timerConfirmCode', {value: lostTime}) : ''}
					onChange={changeHandler}
					status={error ? 'error' : null}
					value={code}
					errorMsg={lostTime && error ? t('wrongCode', {value: lostTime}) : ''}
				/>
				{!lostTime && <Button text='send' variant='tertiary' onClick={resendCode}/>}
			</div>


			<div className={styles.footer}>
				<Button text={t('confirm')} variant='primary' onClick={confirmHandler} disabled={isPending || !code} />
				<Button text={t('back')} variant='secondary' onClick={cancel} disabled={isPending} />
			</div>

		</div>
	</Modal>;
};

export const ConfirmCodeModal = React.memo(ModalComponent);
