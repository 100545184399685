import React from 'react';
import styles from './successModal.module.scss';
import { ISuccessModa } from './successModal.types';
import { Modal } from '../Modal';
import { CheckIcon } from 'forly-kit/dist/icons';
import { Button, Headline, Text } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';

const ModalSuccess: React.FC<ISuccessModa> = ({
	onClose,
	isOpen,
	title,
	description
}) => {

	if (!isOpen) {
		return null;
	}

	return <Modal onClose={onClose}>
		<div className={styles.successModalWrapper}>
			<CheckIcon className={styles.icon} color={colors.success}/>
			<Headline className={styles.title} type='h3' color={colors.success}>
				{title}
			</Headline>
			<Text className={styles.desc} size='m' color={colors.neutral600}>
				{description}
			</Text>
		</div>
		<Button variant='secondary' text='Закрыть' onClick={onClose} fill />
	</Modal>;
};

export const SuccessModal = React.memo(ModalSuccess);
