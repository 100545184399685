// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--Qc5vN{display:grid;grid-template-columns:1fr;justify-items:center}.title--oHjYV{margin-bottom:8px}.subtitle--EgLxA{margin-bottom:32px}\n", "",{"version":3,"sources":["webpack://./../src/components/CreatePartnerProgram/createPartnerProgram.module.scss"],"names":[],"mappings":"AAIA,gBACE,YAAa,CACb,yBAA0B,CAC1B,oBAAqB,CACtB,cAGC,iBAAkB,CACnB,iBAGC,kBAAmB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.wrapper {\n  display: grid;\n  grid-template-columns: 1fr;\n  justify-items: center;\n}\n\n.title {\n  margin-bottom: 8px;\n}\n\n.subtitle {\n  margin-bottom: 32px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--Qc5vN",
	"title": "title--oHjYV",
	"subtitle": "subtitle--EgLxA"
};
export default ___CSS_LOADER_EXPORT___;
