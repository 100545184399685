import React, { useEffect, useState } from 'react';
import forexStyles from './addForexKey.module.scss';
import { IAddForexKeyForm, IAddForexKeyFormData } from './addForexKeyForm.types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Headline, Input, Text } from 'forly-kit';
import styles from '../AddKeyForm/addKeyForm.module.scss';
import { ArrowCircleRevertIcon, CheckIcon } from 'forly-kit/dist/icons';
import cn from 'classnames';
import { forexKeysAPI } from 'services/forexKeysService';
import { colors } from 'forly-kit/dist/colors';
import { activationKeysAPI } from 'services/activationKeysService';

const Form:React.FC<IAddForexKeyForm> = ({
	cancel,
	success
}) => {
	const {t} = useTranslation();
	const [successAdded, setSuccessAdded] = useState<boolean>(false);

	const [addKey, {isLoading: addKeyPending, isSuccess: sucessAddKey}] = forexKeysAPI.useAddKeyMutation();
	const {
		refetch: refetchCountActivations
	} = activationKeysAPI.useFetchActivationCountQuery(null);

	useEffect(() => {
		if (sucessAddKey) {
			refetchCountActivations();
			setSuccessAdded(true);
		}
	}, [sucessAddKey]);

	const {
		register,
		formState: { errors },
		handleSubmit
	} = useForm<IAddForexKeyFormData>({
		mode: 'onChange',
	});

	return <>
		{!successAdded && <div className={forexStyles.formWrapper}>
			<Headline type='h2' className={forexStyles.formTitle}>
				{t('forex.addKeyTitle')}
			</Headline>
			<div className={forexStyles.listItem}>
				<Text size='m'>
					1.
				</Text>
				<Text size='m'>
					{t('forex.addKeyItem1')}
				</Text>
				<Text size='m'>
					2.
				</Text>
				<Text size='m'>
					{t('forex.addKeyItem2')}
				</Text>
			</div>
			<form className={styles.formWrapper} onSubmit={handleSubmit(addKey)}>
				<Input
					label={t('keys.fields.nameKey')}
					desc={t('keys.fields.nameKeyDesc')}
					register={() =>
						register('name', {
							required: {
								value: true,
								message: t('errors.requiredField')
							},
							maxLength: {
								value: 30,
								message: t('errors.maxLengthString', {length: 30})
							}
						})
					}
					errorMsg={errors.name?.message || ''}
					status={errors.name ? 'error' : null}
				/>
				<Input
					label={t('accountNumber')}
					desc={t('accountNumberDesc')}
					register={() =>
						register('key', {
							required: 'required',
						})
					}
					errorMsg={errors.key ? `${t('errors.requiredField')}` : ''}
					status={errors.key ? 'error' : null}
				/>
				<div className={cn(styles.footer, forexStyles.footer)}>
					<Button type='submit' text={t('keys.addKey')} variant='primary' disabled={addKeyPending} />
					<Button
						type='button'
						text={t('backToInstruction')}
						variant='tertiary'
						onClick={cancel}
						icon={{component: ArrowCircleRevertIcon}}
						disabled={addKeyPending}
					/>
				</div>
			</form>
		</div>}

		{successAdded && <div className={forexStyles.successWrapper}>
			<Headline type='h3' color={colors.success} className={forexStyles.successTitle}>
				<CheckIcon color={colors.success} />
				{t('forex.successAddKeyTitle')}
			</Headline>
			<Button variant='secondary' text={t('exchangePage.success_exchange_button')} onClick={success}/>
		</div>}

	</>
	;
};

export const AddForexKeyForm = React.memo(Form);
