// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dropdown--qdfSY{background:#ffffff;box-shadow:0px 0px 8px rgba(0,0,0,0.1);border-radius:8px}.header--Qrj_L{padding:16px 24px;display:grid;grid-template-columns:1fr 24px;align-items:center;cursor:pointer}.header--Qrj_L .icon--pc52G{transition:transform 0.3s ease}.header--Qrj_L .icon--pc52G.flipped--gaHhq{transform:rotate(180deg)}.content--YrY9X{background:#ffffff;overflow:hidden;padding:16px 24px;opacity:0;transition:all 0.5s ease}\n", "",{"version":3,"sources":["webpack://./../src/components/DropdownCard/dropdownCard.module.scss"],"names":[],"mappings":"AAIA,iBACC,kBAAmB,CACnB,sCAA0C,CAC1C,iBAAkB,CAClB,eAGA,iBAAkB,CAClB,YAAa,CACb,8BAA+B,CAC/B,kBAAmB,CACnB,cAAe,CALhB,4BAQE,8BAA+B,CARjC,2CAWG,wBAAyB,CACzB,gBAKF,kBAAmB,CACnB,eAAgB,CAChB,iBAAkB,CAClB,SAAU,CACV,wBAAyB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.dropdown {\n\tbackground: #ffffff;\n\tbox-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);\n\tborder-radius: 8px;\n}\n\n.header {\n\tpadding: 16px 24px;\n\tdisplay: grid;\n\tgrid-template-columns: 1fr 24px;\n\talign-items: center;\n\tcursor: pointer;\n\n\t.icon {\n\t\ttransition: transform 0.3s ease;\n\n\t\t&.flipped {\n\t\t\ttransform: rotate(180deg);\n\t\t}\n\t}\n}\n\n.content {\n\tbackground: #ffffff;\n\toverflow: hidden;\n\tpadding: 16px 24px;\n\topacity: 0;\n\ttransition: all 0.5s ease;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": "dropdown--qdfSY",
	"header": "header--Qrj_L",
	"icon": "icon--pc52G",
	"flipped": "flipped--gaHhq",
	"content": "content--YrY9X"
};
export default ___CSS_LOADER_EXPORT___;
