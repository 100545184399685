import { EBalanceSymbol } from 'types/common.types';

export const forexReg = /^https:\/\/(rbfxdirect|my.roboforex).com\/[a-z]+(\/lk)?\/\?[a-z]+=[a-z0-9]+/g;
// eslint-disable-next-line max-len
export const traderReg = /^https:\/\/my(\d)?\.roboforex\.(org|com)\/(ru\/|en\/)?(register-)?copyfx\/((providers\/show\/[a-z0-9]+)|(\?trader=[0-9]+&partner_token=[a-z0-9]+))/g;
export const binanceReg = /^https:\/\/((www.)?(accounts.)?binance.(com|me)\/[a-z]+\/register\?ref=[0-9a-zA-Z]+)|((www.)?.binance.me\/en\/activity\/referral-entry\/CPA\?fromActivityPage=true&ref=[0-9_A-Za-z]+)/g;

export const tokensLabel = {
	[EBalanceSymbol.FT]: 'FCC',
	[EBalanceSymbol.USDT]: 'BUSD',
	[EBalanceSymbol.BUSD]: 'BUSD',
	[EBalanceSymbol.BTC]: 'BTC',
	[EBalanceSymbol.ETH]: 'ETH'
};
