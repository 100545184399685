import React, { useState } from 'react';
import styles from './modalConfirmExchange.module.scss';
import { IModalConfirmExchange } from './modalConfirmExchange.types';
import { Modal } from '../Modal';
import {Headline, Text, Button} from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';
import { formatNumber } from '../../../helpers/formatNumber';
import { useTranslation } from 'react-i18next';
import { tokensLabel } from '../../../helpers/common';

export const ModalConfirmExchange: React.FC<IModalConfirmExchange> = ({
	onClose,
	confirmAction,
	isOpen,
	balance,
	symbol,
	changeValue
}) => {
	const {t} = useTranslation();
	
	const [isProcess, setIsProcess] = useState<boolean>(false);
	
	const confirmHandler = async () => {
		setIsProcess(true);
		await confirmAction();
		setIsProcess(false);
		onClose();
	};
	
	if (!isOpen) {
		return null;
	}
	
	return <Modal onClose={onClose}>
		<div className={styles.confirmExchangeWrapper}>
			<Headline type='h2'>{t('exchangePage.confirm_exchange_title')}</Headline>
			<Text size='m'>{t('exchangePage.confirm_exchange_subtitle')}</Text>
			<div className={styles.infoBlock}>
				<div>
					<Headline type='h4' color={colors.primary800}>
						{t('exchangePage.balance_lost')}
					</Headline>
					<Headline type='h3' color={colors.primary800}>
						{formatNumber(balance - changeValue, 2)} {tokensLabel[symbol]}
					</Headline>
				</div>
			</div>
			<Button
				text={t('exchangePage.exchange')}
				fill
				onClick={confirmHandler}
				disabled={isProcess}
			/>
		</div>
	</Modal>;
};
