import { Button, Input, Text } from 'forly-kit';
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './FuturePosition.module.scss';
import { IEditStopPriceForm, IFuturePositionProps } from './FuturePosition.types';
import { colors } from 'forly-kit/dist/colors';
import { EditIcon } from 'forly-kit/dist/icons';
import { useForm } from 'react-hook-form';
import { changeNumberInput } from 'src/helpers/patterns';
import { IFutureOrder } from 'types/trading.types';
import { ICreateOrderData } from '../tradingFutures.types';
import { futuresAPI } from 'services/futuresService';
import { ConfirmFuturesOrderModal } from 'src/components/Modals/ConfirmFuturesOrderModal/ConfirmFuturesOrderModal';
import { Loader } from 'src/components/Loader/Loader';
import { ClosePositionModal } from 'src/components/Modals/ClosePositionModal/ClosePositionModal';

const Position: React.FC<IFuturePositionProps> = ({
	position,
	refetchPositions,
	closePosition,
	createOrder,
	closeOrder
}) => {
	const {
		register,
		setValue,
		handleSubmit
	} = useForm<IEditStopPriceForm>({
		mode: 'onChange',
	});

	const [createOrderData, setCreateOrderData] = useState<ICreateOrderData[] | null>(null);
	const [queue, setQueue] = useState<number>(0);
	// const [errorMessage, setErrorMessage] = useState<string>('');
	const [showEditForm, setShowEditForm] = useState<boolean>(false);
	const [showClosePositionModal, setShowClosePostionModal] = useState<boolean>(false);
	const [closePositionPending, setClosePositionPending] = useState<boolean>(false);
	const [createOrderPending, setCreateOrderPending] = useState<boolean>(false);

	const takeProfitPrice = position.take_profit?.stop_price || 0;
	const stopLossPrice = position.stop_loss?.stop_price || 0;

	const closePositionHandler = (quantity: number) => {
		setClosePositionPending(true);
		closePosition(position.symbol_name);
		setTimeout(() => {
			setClosePositionPending(false);
			closeCloseModal();
		}, 1000);
	};

	const changetakeProfitHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = changeNumberInput(e.currentTarget.value);
		setValue('takeProfit', value);
	};

	const changeStopLossHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = changeNumberInput(e.currentTarget.value);
		setValue('stopLoss', value);
	};

	const generateDataToChange = (data: IEditStopPriceForm) => {
		const newOrders: ICreateOrderData[] = [];
		const takeProfit = +data.takeProfit;
		const stopLoss = +data.stopLoss;
		
		if (takeProfit !== position.take_profit?.stop_price) {
			newOrders.push({
				close_position: true,
				stop_price: takeProfit,
				symbol_name: position.symbol_name,
				type: 'TAKE_PROFIT_MARKET',
				side: position.amount > 0 ? 'SELL' : 'BUY'
			});
		}
		
		if (stopLoss !== position.stop_loss?.stop_price) {
			newOrders.push({
				close_position: true,
				stop_price: stopLoss,
				symbol_name: position.symbol_name,
				type: 'STOP_MARKET',
				side: position.amount > 0 ? 'SELL' : 'BUY'
			});
		}

		if (newOrders.length) {
			setCreateOrderData(newOrders);
		}
	};

	const closeCreateOrderModal = () => {
		setCreateOrderData([]);
	};

	const showCloseModal = () => {
		setShowClosePostionModal(true);
	};

	const closeCloseModal = () => {
		setShowClosePostionModal(false);
	};

	const changeStopPrice = async () => {
		if (createOrderData) {
			setCreateOrderPending(true);
			createOrderData.forEach((order) => {
				setQueue((prev) => prev+1);
				if (order.type === 'TAKE_PROFIT_MARKET' && position.take_profit) {
					closeOrder(position.take_profit.connected_to || 0, position.take_profit.orig_type);
				}

				if (order.type === 'STOP_MARKET' && position.stop_loss) {
					closeOrder(position.stop_loss.id || 0, position.stop_loss.orig_type);
				}

				if (order.stop_price) {
					createOrder({
						...order,
						symbol_name: order.symbol_name
					});
				}

				setQueue((prev) => prev-1);

				if (queue === 0) {
					setTimeout(() => {
						setCreateOrderPending(false);
						closeCreateOrderModal();
						closeForm();
					}, 1000);
				}
			});
		}
	};

	const showForm = () => {
		setShowEditForm(true);
	};

	const closeForm = () => {
		setShowEditForm(false);
	};

	return <>
		<div className={styles.position}>
			{closePositionPending && <Loader internal/>}
			<div className={styles.header}>
				<div className={styles.name}>
					<Text size='m'>{position.symbol_name}</Text>
					<Text 
						size='m'
						color={position.amount > 0 ? colors.success : colors.danger}
					>
						{position.amount > 0 ? 'Покупка' : 'Продажа'}
					</Text>
				</div>
				<Button variant='tertiary' text='Закрыть позицию' onClick={showCloseModal} />
			</div>
			<div className={styles.info}>
				<div className={styles.edit}>
					<Text 
						size='m' 
						color={colors.neutral600}
						className={styles.boldText}
					>TP/SL:</Text>
					<div className={styles.editedField}>
						<Text size='m'>{`${takeProfitPrice || '---'}/${stopLossPrice || '---'}`}</Text>
						<div className={styles.icon} onClick={showForm}>
							<EditIcon />
						</div>
					</div>
				</div>

				<div>
					<Text 
						size='m' 
						color={colors.neutral600}
						className={styles.boldText}
					>Размер:</Text>
					<Text size='m'>
						{position.amount}
					</Text>
				</div>

				<div>
					<Text 
						size='m' 
						color={colors.neutral600}
						className={styles.boldText}
					>Цена входа:</Text>
					<Text size='m'>
						{position.entry_price}
					</Text>
				</div>

				<div>
					<Text 
						size='m' 
						color={colors.neutral600}
						className={styles.boldText}
					>Маржа:</Text>
					<Text size='m'>
						{position.isolated_wallet}
					</Text>
				</div>

				<div>
					<Text 
						size='m' 
						color={colors.neutral600}
						className={styles.boldText}
					>PNL:</Text>
					<Text size='m'>
						{position.unrealized_pnl}
					</Text>
				</div>
			</div>

			<form className={cn(styles.form, {
				[styles.visible]: showEditForm
			})} onSubmit={handleSubmit(generateDataToChange)}>
				<Input 
					register={() => register('takeProfit', {
						value: takeProfitPrice || 0,
						onChange: changetakeProfitHandler
					})}
					label='Тейк-профит'
				/>

				<Input 
					register={() => register('stopLoss', {
						value: stopLossPrice || 0,
						onChange: changeStopLossHandler
					})}
					label='Стоп-лосс'
				/>
				<Button type='submit' 
					className={cn(styles.saveBtn, styles.btn)} 
					variant='primary' 
					text='Сохранить' 
					onClick={showForm}
				/>
				<Button 
					type='button' 
					className={cn(styles.btn, styles.cancelBtn)} 
					variant='tertiary' 
					text='Отменить' 
					onClick={closeForm} 
				/>
			</form>
		</div>
	
		<ConfirmFuturesOrderModal 
			isOpen={!!(createOrderData && createOrderData.length)}
			onClose={closeCreateOrderModal}
			order={createOrderData}
			createOrder={changeStopPrice}
			isPending={createOrderPending}
			errorMessage={''}
		/>

		<ClosePositionModal 
			isOpen={showClosePositionModal}
			onClose={closeCloseModal}
			closePosition={closePositionHandler}
			isLoading={closePositionPending}
		/>
	</>;
};

export const FuturePosition = React.memo(Position);
