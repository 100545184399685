import { Text } from 'forly-kit';
import React from 'react';
import cn from 'classnames';
import styles from './tabs.module.scss';
import { ITabsProps } from './tabs.types';
import { colors } from 'forly-kit/dist/colors';

const Component: React.FC<ITabsProps> = ({
	tabs,
	activeTab,
	action,
	className
}) => {
	return <div className={cn(styles.tabsContainer, className)}>
		{tabs.map((tab) => {
			return <div 
				className={cn(styles.tab, {
					[styles.active]: activeTab === tab
				})}
				key={tab.value}
				onClick={() => action(tab.value)}
			>
				<Text size='m' color={activeTab === tab ? colors.white : colors.primary900}>
					{tab.label}
				</Text>
			</div>;
		})}
	</div>;
};

export const Tabs = React.memo(Component);