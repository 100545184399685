import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const __translations = {
	ru: {translation: require('../public/locales/ru/translation.json')},
	en: {translation: require('../public/locales/en/translation.json')},
	tr: {translation: require('../public/locales/tr/translation.json')},
};

const browserLng =  window.navigator.language;
const lng = localStorage.getItem('i18nexLng');
if (!lng) {
	localStorage.setItem('i18nextLng', browserLng.split('-')[0]);
} else {
	localStorage.setItem('i18nextLng', lng.split('-')[0]);
}

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',
		debug: true,
		resources: __translations,
		interpolation: {
			escapeValue: false,
		}
	});

export default i18n;
