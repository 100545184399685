// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalWrapper--mGmAz{max-width:626px}.title--rMyj9{margin-bottom:32px;text-align:center}.title--rMyj9.success--bGH_F{display:grid;grid-template-columns:24px auto;justify-content:center;column-gap:8px;align-items:center}.content--CP6wH{margin-bottom:16px;display:grid;grid-template-columns:repeat(2, 1fr);column-gap:16px;row-gap:16px;align-items:start}.balance--FnTAQ{display:grid;grid-template-columns:1fr;row-gap:8px}.footer--Sx7Ca{display:grid;grid-template-columns:repeat(2, 1fr);column-gap:16px;align-items:center}.successButton--ItJ6C{grid-column:1/-1}\n", "",{"version":3,"sources":["webpack://./../src/components/Modals/Commissions/PayCommissionModal/payCommissionModal.module.scss"],"names":[],"mappings":"AAIA,qBACC,eACD,CAAC,cAGA,kBAAmB,CACnB,iBAAkB,CAFnB,6BAKE,YAAa,CACb,+BAAgC,CAChC,sBAAuB,CACvB,cAAe,CACf,kBAAmB,CACnB,gBAID,kBAAmB,CACnB,YAAa,CACb,oCAAqC,CACrC,eAAgB,CAChB,YAAa,CACb,iBAAkB,CAClB,gBAGA,YAAa,CACb,yBAA0B,CAC1B,WAAY,CACZ,eAGA,YAAa,CACb,oCAAqC,CACrC,eAAgB,CAChB,kBAAmB,CACnB,sBAGA,gBAAiB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.modalWrapper {\n max-width: 626px\n}\n\n.title {\n margin-bottom: 32px;\n text-align: center;\n\n &.success {\n  display: grid;\n  grid-template-columns: 24px auto;\n  justify-content: center;\n  column-gap: 8px;\n  align-items: center;\n }\n}\n\n.content {\n margin-bottom: 16px;\n display: grid;\n grid-template-columns: repeat(2, 1fr);\n column-gap: 16px;\n row-gap: 16px;\n align-items: start;\n}\n\n.balance {\n display: grid;\n grid-template-columns: 1fr;\n row-gap: 8px;\n}\n\n.footer {\n display: grid;\n grid-template-columns: repeat(2, 1fr);\n column-gap: 16px;\n align-items: center;\n}\n\n.successButton {\n grid-column: 1/-1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": "modalWrapper--mGmAz",
	"title": "title--rMyj9",
	"success": "success--bGH_F",
	"content": "content--CP6wH",
	"balance": "balance--FnTAQ",
	"footer": "footer--Sx7Ca",
	"successButton": "successButton--ItJ6C"
};
export default ___CSS_LOADER_EXPORT___;
