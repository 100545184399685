import React, { useEffect, useRef, useState } from 'react';
import styles from '../partners.module.scss';
import { IPartnerLevel } from './PartnerLevel.types';
import { Button, Headline } from 'forly-kit';
import { partnersAPI } from 'services/partnersService';
import { Loader } from '../../../components/Loader/Loader';
import { PartnerCard } from '../../../components/PartnerCard/PartnerCard';
import cn from 'classnames';
import { CircleArrowIcon } from 'forly-kit/dist/icons';
import { useTranslation } from 'react-i18next';

const scrollStep = 126*3;

const Level: React.FC<IPartnerLevel> = ({
	activePartnerId,
	leaderId,
	click,
	level
}) => {
	const {t} = useTranslation();
	const [scrollPosition, setScrollPosition] = useState<number>(0);
	const [activeNext, setActiveNext] = useState<boolean>(true);
	const [activePrev, setActivePrev] = useState<boolean>(false);

	const levelRef = useRef<HTMLDivElement>(null);

	const {data: partners, isLoading: partnersPending} = partnersAPI.useFetchPartnersByIdQuery(leaderId);

	useEffect(() => {
		const clientWidth = levelRef.current?.clientWidth || 0;
		const scrollWidth = levelRef.current?.scrollWidth || 0;

		if (!partners || !partners[0].referrals.length || clientWidth >= scrollWidth) {
			setActiveNext(false);
		}
	}, [partners]);

	const scrollLevelRight = () => {
		if (!activePrev) {
			setActivePrev(true);
		}
		const elem = document.getElementById('level-1');
		const clientWidth = levelRef.current?.clientWidth || 0;
		const scrollWidth = levelRef.current?.scrollWidth || 0;
		let resultScroll = scrollPosition + scrollStep;

		if (resultScroll + clientWidth >= scrollWidth) {
			resultScroll = scrollWidth - clientWidth;
			setScrollPosition(resultScroll);
			setActiveNext(false);
		} else {
			setScrollPosition(resultScroll);
		}
		elem?.setAttribute('style', `transform: translateX(${-resultScroll}px)`);
	};

	const scrollLevelLeft = () => {
		if (!activeNext) {
			setActiveNext(true);
		}
		const elem = document.getElementById('level-1');
		let resultScroll = scrollPosition - scrollStep;
		if (resultScroll <= 0) {
			resultScroll = 0;
			setScrollPosition(0);
			setActivePrev(false);
		} else {
			setScrollPosition(resultScroll);
		}
		elem?.setAttribute('style', `transform: translateX(${-resultScroll}px)`);
	};

	if (partnersPending) {
		return <Loader />;
	}

	if (!partners || !partners[0].referrals.length) {
		return null;
	}

	return <div className={styles.levelsBlockItem}>
		{partners && partners[0].referrals.length && <Headline type='h4' className={styles.levelTitle}>
			{t('partners.level', {level: level})}
			<div className={styles.arrowBlock}>
				<Button
					className={cn(styles.revert, styles.actionBtn)}
					variant='tertiary'
					text=''
					icon={{component: CircleArrowIcon}}
					onClick={scrollLevelLeft}
					disabled={!activePrev}
				/>
				<Button
					className={styles.actionBtn}
					variant='tertiary'
					text=''
					icon={{component: CircleArrowIcon}}
					onClick={scrollLevelRight}
					disabled={!activeNext}
				/>
			</div>
		</Headline>
		}
		<div className={styles.level} id={`level-${level}`}>
			{partnersPending && <Loader internal/>}
			{partners && partners[0].referrals.map((partner) => {
				return <PartnerCard
					className={styles.card}
					isActive={partner.user_profile.user_id === activePartnerId}
					key={partner.user_profile.user_id}
					partner={partner}
					click={click}
					level={level}
				/>;
			})}
		</div>
	</div>;
};

export const PartnerLevel = React.memo(Level);
