import React from 'react';
import ReactDOM from 'react-dom';
import styles from './modal.module.scss';
import { CloseIcon } from 'forly-kit/dist/icons';
import { IModalRoot } from './modal.types';

let modalContainer = document.getElementById('modal');

const ModalContainer: React.FC = (props) => {
	if (!modalContainer) {
		modalContainer = document.createElement('div');
		modalContainer.setAttribute('id', 'modal');
	}
	return ReactDOM.createPortal(props.children, modalContainer);
};

export const Modal: React.FC<IModalRoot> = ({onClose, children}) => {
	
	const closeClickHandler = () => {
		onClose();
	};
	
	return <ModalContainer>
		<div className={styles.modalWrapper}>
			<div className={styles.modal}>
				<div className={styles.closeIcon} onClick={closeClickHandler}>
					<CloseIcon />
				</div>
				
				{children}
			</div>
		</div>
	</ModalContainer>;
};

export { ModalContainer };
