import React, { useState } from 'react';
import styles from './addKeyBlock.module.scss';
import { Button, Headline, Text } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';
import { InstructionsLinks } from '../InstructionsLinks/InstructionsLinks';
import { PlusIcon } from 'forly-kit/dist/icons';
import { IAddKeyBlock } from './addKeyBlock.types';
import { AddKeyForm } from '../Forms/AddKeyForm/AddKeyForm';
import { IAddKeyFormData } from '../Forms/AddKeyForm/addKeyForm.types';
import { Loader } from '../Loader/Loader';
import { useTranslation } from 'react-i18next';

const AddKey: React.FC<IAddKeyBlock> = ({
	cancelAddKey,
	addKey,
	isForex,
	Instruction,
	instructionModal
}) => {
	const {t} = useTranslation();
	const [showInstruction, setShowInstruction] = useState<boolean>(false);
	const [isShowForm, setIsShowForm] = useState<boolean>(false);
	const [error, setError] = useState<string>('');
	const [addProcess, setAddProcess] = useState<boolean>(false);

	const showForm = () => {
		setIsShowForm(true);
	};

	const addKeyHandler = async (data: IAddKeyFormData) => {
		if (!addKey) {
			return;
		}
		setAddProcess(true);
		const res = await addKey(data);
		setAddProcess(false);
		const errMsg = res.error?.data?.detail;

		if (!errMsg) {
			hideForm();
			return;
		}

		if (errMsg === 'Wrong public or secret') {
			setError('Проверьте ключи и повторите попытку');
		}

		if (errMsg === 'Token already exist') {
			setError('Этот ключ уже добавлен');
		}
	};

	const openInstruction = () => {
		setShowInstruction(true);
	};

	const closeInsruction = () => {
		setShowInstruction(false);
		setIsShowForm(true);
	};

	const hideForm = () => {
		if (error) {
			setError('');
		}
		setIsShowForm(false);
		if (cancelAddKey) {
			cancelAddKey();
		}
	};

	return <div className='card cardContent'>
		{addProcess && <Loader internal /> }
		{!showInstruction && <>
			<div className={styles.addKeyWrapper}>
				<Headline type='h2' color={colors.primary800}>
					{t('keys.pinApiKey')}
				</Headline>
				{
					instructionModal?.Instruction && <InstructionsLinks
						Instruction={instructionModal.Instruction}
					/>
				}
				{isShowForm && !isForex &&
					<AddKeyForm addKey={addKeyHandler} cancel={hideForm} />
				}

				{error && <Text size='m' color={colors.danger}>{error}</Text>}
				{!isShowForm &&
					<div className={styles.footer}>
						<Button
							variant='primary'
							icon={{ component: PlusIcon }}
							text={t('keys.addApiKey')}
							fill
							onClick={Instruction ? openInstruction : showForm}
						/>
						{cancelAddKey && <Button variant='secondary' text={t('cancelAction')} fill onClick={cancelAddKey} />}
					</div>
				}
			</div>

		</>}
		{showInstruction && Instruction && <Instruction close={closeInsruction} />}
	</div>;
};

export const AddKeyBlock = React.memo(AddKey);
