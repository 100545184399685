import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ILanguageSwitcher } from './languageSwitcher.types';
import { Dropdown } from 'forly-kit';
import {
	IDropdownItem
} from 'forly-kit/dist/components/Dropdown/Dropdown.types';
import { useTranslation } from 'react-i18next';
import { userAPI } from 'services/userService';

export const LanguageSwitcher: React.FC<ILanguageSwitcher> = ({className}) => {
	const [changeProfileLang] = userAPI.useChangeProfileLangMutation();

	const languageData: IDropdownItem[] = [
		{
			id: 1,
			label: 'Русский',
			value: 'ru',
		},
		{
			id: 2,
			label: 'English',
			value: 'en',
		}
	];
	const {i18n} = useTranslation();
	const classes = classNames(className);
	const [activeItem, setActiveItem] = useState<IDropdownItem>(languageData[0]);
	const [activeItemLabel, setActiveItemLabel] = useState<string>('');
	
	useEffect(() => {
		const active = languageData.find((item) => {
			const {value} = item;
			if (typeof value === 'string') {
				return value === i18n.language;
			}
			return false;
		});
		setActiveItem(active ? active :languageData[0] );
		if (i18n.language === 'ru') {
			setActiveItemLabel('Рус');
		}
		if (i18n.language === 'en') {
			setActiveItemLabel('Eng');
		}
	}, [i18n.language]);
	
	const changeLanguageHandler = (value: string | number): void => {
		if (typeof value === 'string') {
			i18n.changeLanguage(value);
			changeProfileLang(value);
		}
	};
	
	return <Dropdown 
		className={classes}  
		data={languageData} 
		change={changeLanguageHandler} 
		activeItem={activeItem}
		activeItemLabel={activeItemLabel}
	/>;
};
