import React from 'react';
import cn from 'classnames';
import styles from './EditStepIndicator.module.scss';
import { IEditStepIndicator } from './EditStepIndicator.types';
import { Headline } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';

const Indicator: React.FC<IEditStepIndicator> = ({
	step,
	className
}) => {

	const isActive = (currentStep: number): boolean => {
		return currentStep <= step;
	};

	return <div className={styles.indicatorWrapper}>
		<div className={cn(styles.indicatorStep, {[styles.active]: isActive(1)})}>
			<Headline type='h1' color={isActive(1) ? colors.white : colors.neutral400}>
				1
			</Headline>
		</div>
		<div className={cn(styles.indicatorDivider, {[styles.active]: isActive(2)})}></div>
		<div className={cn(styles.indicatorStep, {[styles.active]: isActive(2)})}>
			<Headline type='h1' color={isActive(2) ? colors.white : colors.neutral400}>
				2
			</Headline>
		</div>
		<div className={cn(styles.indicatorDivider, {[styles.active]: isActive(3)})}></div>
		<div className={cn(styles.indicatorStep, {[styles.active]: isActive(3)})}>
			<Headline type='h1' color={isActive(3) ? colors.white : colors.neutral400}>
				3
			</Headline>
		</div>
	</div>;
};

export const EditStepIndicator = React.memo(Indicator);
