import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './reports.module.scss';
import { HeaderCommon } from '../../components/HeaderCommon/HeaderCommon';
import { Headline } from 'forly-kit';
import { ArrowIcon } from 'forly-kit/dist/icons';
import { bankAPI } from 'services/bankService';
import { IReport } from 'types/common.types';
import cn from 'classnames';
import { colors } from 'forly-kit/dist/colors';
import { formatNumber } from '../../helpers/formatNumber';
import { format, getMonth } from 'date-fns';
import { Loader } from '../../components/Loader/Loader';
import { useTranslation } from 'react-i18next';
import { getDateFnsLocale } from '../../helpers/date';

export const Reports: React.FC = () => {
	const {t, i18n} = useTranslation();
	const {data: reports, isLoading: reportsPending} = bankAPI.useGetReportsQuery(null);
	const [activeReport, setActiveReport] = useState<IReport | null>(null);
	const [showList, setShowList] = useState<boolean>(false);
	const listRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (reports?.length) {
			setActiveReport(reports[reports.length - 1]);
		}
	}, [reports]);

	useEffect(() => {
		if (listRef.current && reports) {
			if (showList) {
				const height = listRef.current.scrollHeight + 10;
				listRef.current.setAttribute('style', `height: ${height < 200 ? height : 200}px; border: 1px solid ${colors.primary900}`);
			} else {
				listRef.current.removeAttribute('style');
			}
		}
	}, [showList]);

	const titleDate = useMemo(() => {
		if (!activeReport) {
			return '';
		}
		const date = new Date(activeReport.year, activeReport.month, 0);
		return `${format(date, 'LLLL', {locale: getDateFnsLocale()})}, ${format(date, 'yyyy')}`
	}, [i18n.language, activeReport]);

	const toggleList = () => {
		setShowList((prev) => !prev);
	};

	const sumIncome = useMemo(() => {
		if (activeReport) {
			const {fc_income, act_income, ff_income} = activeReport;
			return fc_income + act_income + ff_income;
		}
		return 0;
	}, [activeReport]);

	const sumFiatOutcome = useMemo(() => {
		if (activeReport) {
			const {market_outcome, tech_outcome, adm_outcome, salary_outcome} = activeReport;
			return market_outcome + tech_outcome + adm_outcome + salary_outcome;
		}
		return 0;
	}, [activeReport]);

	const changeReport = (report: IReport) => {
		setActiveReport(report);
		setShowList(false);
	};

	if (reportsPending) {
		return <Loader />;
	}

	return <div className={styles.wrapper}>
		<HeaderCommon />

		<div className={styles.content}>
			<div className={styles.header}>
				<span>{t('reports.reportTitle')}</span>
				<div onClick={toggleList} className={styles.toggler}>
					<Headline type='h3' color='#7D829A'>
						{titleDate}
					</Headline>
				</div>
				<div className={styles.dropdown} id='dropdown' ref={listRef}>
					{reports?.map((r) => {
						const date = new Date(r.year, r.month, 0);
						return <div
							className={cn(styles.item, {[styles.active]: activeReport === r})}
							key={r.id}
							onClick={() => changeReport(r)}
						>
							<span>{format(date, 'LLLL', {locale: getDateFnsLocale()})}</span>
							<span>{format(date, 'yyyy')}</span>
						</div>;
					}) }
				</div>
			</div>
			{activeReport && <div className={styles.reportContent}>
				<Headline type='h2' className={styles.title} color='#7D829A'>
					{t('reports.income')}
					<ArrowIcon
						className={styles.iconAcc}
						color={colors.success}
					/>
				</Headline>
				<table>
					<tr>
						<td>{t('reports.commissionFrom', {from: 'Forly Crypto'})}</td>
						<td>{formatNumber(activeReport.fc_income, 2)} FCC</td>
					</tr>
					<tr>
						<td>{t('reports.commissionFrom', {from: 'Forly Futures'})}</td>
						<td>{formatNumber(activeReport.ff_income, 2)} FCC</td>
					</tr>
					<tr>
						<td>{t('reports.sellActivations')}</td>
						<td>{formatNumber(activeReport?.act_income || 0, 2)} FCC</td>
					</tr>
					<tr className={styles.total}>
						<td>{t('reports.total')}:</td>
						<td>{formatNumber(sumIncome, 2)} FCC</td>
					</tr>
				</table>

				<Headline type='h2' className={styles.title} color='#7D829A'>
					{t('reports.outcome')}
					<ArrowIcon
						className={styles.iconDesc}
						color={colors.danger}
					/>
				</Headline>
				<table>
					<tr>
						<td>{t('reports.agentsOutcome')}</td>
						<td>{formatNumber(activeReport.agent_outcome, 2)} FCC</td>
					</tr>
					<tr>
						<td>{t('reports.fiatOutcome')}</td>
						<td>{formatNumber(sumFiatOutcome / activeReport.fcc_rate, 2)} FCC</td>
					</tr>
				</table>

				<Headline type='h2' className={styles.title} color='#7D829A'>
					{t('reports.netProfit')}
					<ArrowIcon
						className={cn({
							[styles.iconAcc]: activeReport.value >= 0,
							[styles.iconDesc]: activeReport.value < 0
						})}
						color={activeReport.value >= 0 ? colors.success : colors.danger}
					/>
				</Headline>
				<span>{formatNumber(activeReport.value, 2)} FCC</span>

				<Headline type='h2' className={styles.title} color='#7D829A'>
					{t('reports.paidForToken')}:
				</Headline>
				<span>{formatNumber(activeReport.value > 0 ? activeReport.value/1000000 : 0, 4)} FCC</span>

				<Headline type='h2' className={cn(styles.title, styles.withSub)} color='#7D829A'>
					{t('reports.averageChange')}<br />
					<span className={styles.sub}>({t('reports.toFiatPay')})</span>
				</Headline>
				<span>{formatNumber(activeReport.fcc_rate, 2)} FCC</span>

				<Headline type='h2' className={styles.title} color='#7D829A'>
					{t('reports.fiatOutcome')}
				</Headline>
				<table className="right">
					<tr>
						<td>{t('reports.salary')}</td>
						<td>$ {activeReport.salary_outcome}</td>
					</tr>
					<tr>
						<td>{t('reports.tecnhical')}</td>
						<td>$ {activeReport.tech_outcome}</td>
					</tr>
					<tr>
						<td>{t('reports.administration')}</td>
						<td>$ {activeReport.adm_outcome}</td>
					</tr>
					<tr>
						<td>{t('reports.marketing')}</td>
						<td>$ {activeReport.market_outcome}</td>
					</tr>
				</table>

			</div> }

		</div>
	</div>;
};
