import React, { useState } from 'react';
import cn from 'classnames';
import styles from './sidebar.module.scss';
import { ISidebar } from './sidebar.types';
import { DownArrowIcon } from 'forly-kit/dist/icons';

export const Sidebar: React.FC<ISidebar> = ({className, children}) => {
	const [opened, setOpened] = useState<boolean>(false);
	const sidebarClasses = cn(
		className,
		'sidebar',
		styles.sidebar,
		{
			'opened': opened
		}
	);
	
	const openHandler = () => {
		setOpened((prev) => !prev);
	};
	
	return <div className={sidebarClasses}>
		<div className={styles.openButton} onClick={openHandler}>
			<DownArrowIcon className='arrowIcon'/>
		</div>
		<div className={styles.sidebarContent}>
			{children}
		</div>
		
	</div>;
};
