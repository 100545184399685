import React, { RefObject, useEffect, useRef, useState } from 'react';
import styles from './partners.module.scss';
import { partnersAPI } from 'services/partnersService';
import { PartnerCard } from '../../components/PartnerCard/PartnerCard';
import { IPartner } from 'types/partners.types';
import { PartnerLevel } from './PartnerLevel/PartnerLevel';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Button, Headline, Text } from 'forly-kit';
import {
	CircleArrowIcon,
	CopyIcon,
	EditIcon,
	FileIcon,
	FuturesLogoIcon,
	OutIcon,
	RoboforexLogoIcon,
	SpotLogoIcon
} from 'forly-kit/dist/icons';
import cn from 'classnames';
import { colors } from 'forly-kit/dist/colors';
import { RefLinksForm } from '../../components/Forms/RefLinksForm/RefLinksForm';
import { Loader } from '../../components/Loader/Loader';
import { CreatePartnerProgram } from '../../components/CreatePartnerProgram/CreatePartnerProgram';
import partnerProgramRu from 'src/assets/docs/partner_program_ru.pdf';
import partnerProgramEn from 'src/assets/docs/partner_program_en.pdf';

import avatar from 'src/assets/img/fakeAvatar.jpg';
import { useTranslation } from 'react-i18next';
import i18n from './../../i18n';

const scrollStep = 126 + 8;

export const Partners: React.FC = () => {
	const {t} = useTranslation();

	const {
		data: partners,
		isLoading: partnersPending,
		isSuccess: successPendingPartners,
		refetch: getPartners,
	} = partnersAPI.useFetchPartnersQuery(null);
	const {data: head, isLoading: headPending} = partnersAPI.useHeadQuery(null);

	const [hasProgram, setHasProgram] = useState<boolean>(false);
	const [levelActiveCards, setLevelActiveCards] = useState<number[]>([]);
	const [scrollPosition, setScrollPosition] = useState<number>(0);
	const [activeNext, setActiveNext] = useState<boolean>(true);
	const [activePrev, setActivePrev] = useState<boolean>(false);

	const levelRef = useRef<HTMLDivElement>(null);
	const binanceRef = useRef<HTMLDivElement>(null);
	const roboRef = useRef<HTMLDivElement>(null);
	const binanceHeaderRef = useRef<HTMLDivElement>(null);
	const RoboHeaderRef = useRef<HTMLDivElement>(null);
	const subdomainRef = useRef<HTMLSpanElement>(null);
	const registerRef = useRef<HTMLSpanElement>(null);

	useEffect(() => {
		if (successPendingPartners && partners.link && !hasProgram) {
			setHasProgram(true);
		}
	}, [successPendingPartners]);

	const changeActiveCard = (partner: IPartner, level: number) => {
		const activeLevels = [...levelActiveCards];
		activeLevels[level-1] = partner.user_profile.user_id;
		if (level < activeLevels.length) {
			setLevelActiveCards(activeLevels.slice(0, level));
		} else {
			setLevelActiveCards(activeLevels);
		}
		console.log(activeLevels);
	};
	
	const openCardHandler = (type: 'binance' | 'robo'): void => {
		const elem = type === 'binance' ? binanceRef : roboRef;

		const contentElem = elem.current?.getElementsByClassName(styles.content);
		if (!contentElem) {
			return;
		}
		if (contentElem[0].hasAttribute('style')) {
			contentElem[0].removeAttribute('style');
		} else {
			contentElem[0].setAttribute('style', `height: ${contentElem[0].scrollHeight + 24}px; padding: 0 24px 16px 24px;`);
		}
	};

	const scrollLevelRight = () => {
		if (!activePrev) {
			setActivePrev(true);
		}
		const elem = document.getElementById('level-1');
		const clientWidth = levelRef.current?.clientWidth || 0;
		const scrollWidth = levelRef.current?.scrollWidth || 0;
		let resultScroll = scrollPosition + scrollStep;

		if (resultScroll + clientWidth >= scrollWidth) {
			resultScroll = scrollWidth - clientWidth;
			setScrollPosition(resultScroll);
			setActiveNext(false);
		} else {
			setScrollPosition(resultScroll);
		}
		elem?.setAttribute('style', `transform: translateX(${-resultScroll}px)`);
	};

	const scrollLevelLeft = () => {
		if (!activeNext) {
			setActiveNext(true);
		}
		const elem = document.getElementById('level-1');
		let resultScroll = scrollPosition - scrollStep;
		if (resultScroll <= 0) {
			resultScroll = 0;
			setScrollPosition(0);
			setActivePrev(false);
		} else {
			setScrollPosition(resultScroll);
		}
		elem?.setAttribute('style', `transform: translateX(${-resultScroll}px)`);
	};

	const copyLink = (elem: RefObject<HTMLSpanElement>) => {
		navigator.clipboard.writeText(elem.current?.innerText || '');
	};

	if (partnersPending) {
		return <Loader />;
	}

	return <div className='contentWrapper'>
		<div className='content'>
			{!hasProgram && <CreatePartnerProgram successCreate={getPartners}/> }
			{hasProgram && <>
				<div className={styles.linksBlock}>
					<div className={cn('card', styles.linkCard)} ref={binanceRef}>
						<div className={styles.header} ref={binanceHeaderRef} onClick={() => openCardHandler('binance')}>
							<Headline type='h4'>
								{t('partners.refferalLinksOnBinance')}
							</Headline>
							<EditIcon color={colors.primary700}/>
						</div>
						<div className={styles.content}>
							<div className={styles.instructionLink}>
								<FileIcon color={colors.primary700}/>
								<Text size='button' color={colors.primary700}>
									{t('partners.whereLink', {platform: 'Binance'})}
								</Text>
							</div>
							<RefLinksForm type='binance' />
						</div>
					</div>

					<div className={cn('card', styles.linkCard)} ref={roboRef}>
						<div className={styles.header} ref={RoboHeaderRef} onClick={() => openCardHandler('robo')}>
							<Headline type='h4'>
								{t('partners.refferalLinksOnForex')}
							</Headline>
							<EditIcon color={colors.primary700}/>
						</div>
						<div className={styles.content}>
							<div className={styles.instructionLink}>
								<FileIcon color={colors.primary700}/>
								<Text size='button' color={colors.primary700}>
									{t('partners.whereLink', {platform: 'RoboForex'})}
								</Text>
							</div>
							<RefLinksForm type='robo' />
						</div>
					</div>
				</div>

				<div className={styles.levelsBlock}>
					<div className={styles.levelsBlockItem}>
						{partners && partners.referrals.length ? <Headline type='h4' className={styles.levelTitle}>
							{t('partners.level', {level: 1})}
							<div className={styles.arrowBlock}>
								<Button
									className={cn(styles.revert, styles.actionBtn)}
									variant='tertiary'
									text=''
									icon={{component: CircleArrowIcon}}
									onClick={scrollLevelLeft}
									disabled={!activePrev}
								/>
								<Button
									className={styles.actionBtn}
									variant='tertiary'
									text=''
									icon={{component: CircleArrowIcon}}
									onClick={scrollLevelRight}
									disabled={!activeNext}
								/>
							</div>
						</Headline> : null
						}
						<div className={styles.level} ref={levelRef} id='level-1'>
							{partners && partners.referrals.length ? partners.referrals.map((partner: IPartner) => {
								return <PartnerCard
									className={styles.card}
									isActive={partner.user_profile.user_id === levelActiveCards[0]}
									key={partner.user_profile.user_id}
									partner={partner}
									click={changeActiveCard}
									level={1}
								/>;
							}) : null}
						</div>
					</div>

					{levelActiveCards.map((id, _idx) => {
						return <PartnerLevel
							key={id}
							click={changeActiveCard}
							leaderId={levelActiveCards[_idx]}
							level={_idx+2}
							activePartnerId={levelActiveCards[_idx+1]}
						/>;
					})}
				</div>
			</> }

		</div>
		<Sidebar> 
			<>
				{!hasProgram &&  <>
					<div className={cn(styles.sidebarBlock, styles.block)}>
						<Headline type='h4' color={colors.neutral600}>
							{t('partnerProgramDesc')}
						</Headline>
						<a 
							className={styles.partnerProgramLink} 
							href={i18n.language === 'ru' ? partnerProgramRu : partnerProgramEn} 
							target="_blank" 
							rel="noreferrer"
						>
							<Text size='button' color={colors.primary700}>
								{t('moreDetailed')}
							</Text>
							<OutIcon color={colors.primary700} />
						</a>
					</div>
					<div className={styles.sidebarBlock}>
						<Headline type='h3'>
							{t('partners.createSubdomain')}
						</Headline>
						<Text size='m'>
							{t('partners.subdomainIs')}
						</Text>
					</div>
				</>
				}

				{
					hasProgram && partners && <>
						<div className={styles.refLinksBlock}>
							<div className={styles.sidebarBlock}>
								<Headline type='h4' color={colors.neutral600}>
									{t('partners.mySubdomain')}
								</Headline>
								<Text size='button'>
									<span ref={subdomainRef}>
										{partners.link}.{CONFIG.domain}
									</span>
								</Text>
								<Button
									variant='tertiary'
									icon={{component: CopyIcon}}
									text={t('copy')}
									onClick={() => copyLink(subdomainRef)}
								/>
							</div>
							<div className={styles.sidebarBlock}>
								<Headline type='h4' color={colors.neutral600}>
									{t('partners.registerLink')}
								</Headline>
								<Text size='button'>
									<span ref={registerRef}>
										{`${CONFIG.passportUrl}/${i18n.language}/sign-up/?ref=`}{partners.link}
									</span>
								</Text>
								<Button
									variant='tertiary'
									icon={{component: CopyIcon}}
									text={t('copy')}
									onClick={() => copyLink(registerRef)}
								/>
							</div>
						</div>
						<div className={styles.sidebarBlock}>
							<Headline type='h4' color={colors.neutral600}>
								{t('partners.myLeader')}
							</Headline>
							<div className={cn('card', styles.headCard)}>
								{headPending && <Loader internal/>}
								<img
									className={styles.avatar}
									src={head?.user_profile.avatar || avatar}
									alt=""
									width={48}
									height={48}
								/>
								<div className={styles.info}>
									<Headline type='h4'>
										{head?.user_profile.name || ''} {head?.user_profile.surname || ''}
									</Headline>
									<Text size='m'>
										{head?.user_profile.phone || t('noData')}
									</Text>
								</div>
								<div className={styles.assets}>
									<RoboforexLogoIcon color={head?.user_activations.FRX ? '#57CBFF' : '#C1C2C3'}/>
									<SpotLogoIcon color={head?.user_activations.CRP ? '#F3BA2F' : '#C1C2C3'}/>
									<FuturesLogoIcon color={head?.user_activations.FTR ? '#F3BA2F' : '#C1C2C3'}/>
								</div>
							</div>
						</div>
					</>}
			</>
		</Sidebar>
	</div>;
};

export default Partners;
