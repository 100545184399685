import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { RootState } from 'store/store';
import { IHead, IPartner, IPartners } from 'types/partners.types';

export const partnersAPI = createApi({
	reducerPath: 'partnersAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: `https://${CONFIG.api}/referrals/`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).auth.token;
			if (token) {
				headers.set('authorization', `Bearer ${token}`);
			}
			return headers;
		},
	}),
	tagTypes: ['partners'],
	endpoints: (build) => ({
		fetchPartners: build.query<IPartners, null>({
			query: () => ({
				url: ''
			}),
			providesTags: result => ['partners']
		}),
		fetchPartnersById: build.query<IPartners[], number>({
			query: (id) => ({
				url: `/users/${id}/`
			})
		}),
		changeRefLink: build.mutation({
			query: (link: string) => ({
				url: '/put/',
				method: 'PUT',
				body: {
					link
				}
			}),
			invalidatesTags: ['partners']
		}),
		head: build.query<IHead, null>({
			query: () => ({
				url: '/head/'
			})
		})
	})
});
