import { Button, Text } from 'forly-kit';
import React, { useEffect, useState } from 'react';
import styles from './SpotOrder.module.scss';
import { ISpotOrderProps } from './SpotOrder.types';
import { format } from 'date-fns';
import { spotAPI } from 'services/spotService';
import { ConfirmModal } from '../../../components/Modals/ConfirmModal/ConfirmModal';

const status: Record<string, string> = {
	NEW: 'Открыт',
	FILLED: 'Исполнен',
};
const type: Record<string, string> = {
	LIMIT: 'Лимитный',
	COC: 'COC',
	LIMIT_MAKER: 'Take profit',
	STOP_LOSS_LIMIT: 'Stop loss'
};
const side: Record<string, string> = {
	BUY: 'Покупка',
	SELL: 'Продажа',
};

const Component: React.FC<ISpotOrderProps> = ({
	order,
	cancelOrder
}) => {
	const {refetch: refetchOrders} = spotAPI.useGetSpotOrdersQuery(null);
	// const [deleteOrder,
	// 	{isSuccess: deleteOrderSuccess, isLoading: closeLoading, isError: closeError}
	// ] = spotAPI.useDeleteSpotOrderMutation();

	const [openedConfirm, setOpenedConfirm] = useState<boolean>(false);
	const [errorClose, setErrorClose] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(false);

	// useEffect(() => {
	// 	if (deleteOrderSuccess) {
	// 		refetchOrders();
	// 		closeConfirm();
	// 	}
	// }, [deleteOrderSuccess]);
	//
	// useEffect(() => {
	// 	if (closeError) {
	// 		setErrorClose('Не удалось закрыть ордер');
	// 	}
	// }, [closeError]);
	//
	const deleteOrderHandler = () => {
		cancelOrder(order.connected_to || 0, order.type);
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
			closeConfirm();
		}, 1500);
	};

	const openConfirm = () => {
		setOpenedConfirm(true);
	};

	const closeConfirm = () => {
		setOpenedConfirm(false);
	};

	return <>
		<Text size='s'>{order.symbol_name}</Text>
		<Text size='s'>{status[order.status]}</Text>
		<Text size='s'>{type[order.type]}</Text>
		<Text size='s'>{side[order.side]}</Text>
		<Text size='s'>{order.orig_qty}</Text>
		<Text size='s'>{order.price}</Text>
		<Text size='s'>{format(new Date(order.transact_time), 'dd.MM.yyyy HH:mm')}</Text>
		<Button 
			className={styles.closeBtn} 
			text='Отмена ордера' 
			variant='tertiary'
			onClick={openConfirm}
		/>
		<div className={styles.divider}></div>

		<ConfirmModal
			isOpen={openedConfirm}
			onClose={closeConfirm}
			confirm={deleteOrderHandler}
			title='Закрыть ордер'
			subtitle='Ордер будет закрыт'
			error={errorClose}
			isLoading={isLoading}
		/>
	</>;
};

export const SpotOrder = React.memo(Component);
