// Для страницы ФИНАНСЫ
// TODO Перенести с использованием Balance

import React, { useState } from 'react';
import styles from './balanceCardContent.module.scss';
import { DownloadIcon, PauseIcon, QuestionIcon, UploadIcon } from 'forly-kit/dist/icons';
import cn from 'classnames';
import { Button, Headline, Text } from 'forly-kit';
import { EBalanceSymbol } from 'types/common.types';
import { colors } from 'forly-kit/dist/colors';
import { IBalanceCardContent } from './balanceCardContent.types';
import { symbolIcon } from '../../helpers/symbolIcons';
import { formatNumber } from '../../helpers/formatNumber';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18n from './../../i18n';
import { ServiceModal } from '../Modals/ServiceModal/ServiceModal';

const BalanceCard: React.FC<IBalanceCardContent> = ({
	children,
	title,
	symbol,
	value,
	valueInUsdt,
	action
}) => {
	const {t} = useTranslation();
	const navigate = useNavigate();

	const [openServiceModal, setOpenServiceModal] = useState<boolean>(false);

	const goToRefil = () => {
		navigate(`/${i18n.language}/refil/`);
	};

	const openServiceModalHandler = () => {
		setOpenServiceModal(true);
	};

	const closeServiceModal = () => {
		setOpenServiceModal(false);
	};
	
	const SymbolIconComponent = React.useMemo(() => {
		return symbolIcon[symbol];
	}, [symbol]);
	
	return <>
		<div className='card cardContent'>
			<div className={cn(styles.cardContent, {
				[styles.withoutChildren]: !children
			})}>
				<header className={styles.header}>
					<SymbolIconComponent color={colors.primary700}/>
					{symbol === EBalanceSymbol.FT
						? <span className={cn(styles.headerText, styles.gradientText)}>{title}</span>
						: <Headline className={styles.headerText} type='h3' color={colors.primary700}>{title}</Headline>
					}
				
					{valueInUsdt !== undefined && <Text size='m'>{formatNumber(valueInUsdt, 2)} BUSD</Text> }
				</header>
				{symbol === EBalanceSymbol.FT
					? <span className={cn(styles.balance, styles.gradientText)}>{formatNumber(value, 2)} FCC</span>
					: <Headline
						className={styles.headerText}
						type='h1'
						color={colors.primary800}
					>
						{formatNumber(value, 2)} BUSD
					</Headline>
				}
			
				<div className={styles.toolbarActions}>
					{action && <div className={styles.action}>
						<Button text={t('createHold')} icon={{component: PauseIcon}} variant='tertiary' onClick={action} />
						<QuestionIcon className={styles.question}/>
						<div className={styles.helpCard}>
							<Text size='s'>
								{t('createHoldActionDescription')}
							</Text>
						</div>
					</div> }
					<Button 
						onClick={goToRefil} 
						className={styles.action} 
						text={t('refil')} 
						icon={{component: DownloadIcon}} 
						variant='tertiary' 
					/>
					<Button 
						className={styles.action} 
						text={t('withdraw')} 
						icon={{component: UploadIcon}} 
						variant='tertiary' 
						onClick={openServiceModalHandler}
					/>
				</div>
			</div>
		
			{children}
		</div>
		<ServiceModal 
			isOpen={openServiceModal}
			onClose={closeServiceModal}
		/>
	</>;
};

export const BalanceCardContent = React.memo(BalanceCard);
