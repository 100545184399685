import { DownArrowIcon } from 'forly-kit/dist/icons';
import React, { useMemo, useRef, useState } from 'react';
import cn from 'classnames';
import styles from './dropdownCard.module.scss';
import { IDropdownCardProps } from './dropdownCard.types';

export const DropdownCard: React.FC<IDropdownCardProps> = ({
	children,
	title,
	rerenderKey
}) => {

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const contentRef = useRef<HTMLDivElement>(null);

	const contentStyles = useMemo(() => {
		if (!isOpen) {
			return ({
				height: '0',
				padding: '0 24px'
			});
		}

		if (!contentRef.current) {
			return {};
		}
		
		return ({
			height: 'auto',
			maxHeight: contentRef ? `${contentRef.current.scrollHeight + 32}px` :'300px',
			padding: '16px 24px',
			opacity: 1,
		});
	}, [isOpen, rerenderKey]);

	const toggleDropdown = () => {
		setIsOpen((prev) => !prev);
	};

	return <div className={styles.dropdown}>
		<div className={styles.header} onClick={toggleDropdown}>
			{title}
			<DownArrowIcon className={cn(styles.icon, {
				[styles.flipped]: isOpen
			})}/>
		</div>
		<div ref={contentRef} className={styles.content} style={contentStyles}>
			{children}
		</div>
	</div>;
};

// export const DropdownCard = React.memo(Component);