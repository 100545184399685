import React, { useMemo } from 'react';
import styles from './desktopMenu.module.scss';
import { menuList } from '../../helpers/menu';
import { NavLink } from '../ui';
import { Text } from 'forly-kit';
import { useMatch, useResolvedPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { baseAuthAPI } from 'services/baseAuthService';

export const DesktopMenu: React.FC = () => {
	const {i18n, t} = useTranslation();
	const {data: user} = baseAuthAPI.useFetchUserQuery(null);

	const isTrader = useMemo(() => {
		if (!user) {
			return false;
		}
		const tradersGroup = user.groups.find((f) => f.name === 'traders');
		if (!tradersGroup) {
			return false;
		}

		const permission = tradersGroup.permissions.find((f) => f.codename === 'futures_trade');
		if (permission) {
			return true;
		}
		return false;
	}, [user]);
	
	return <div className={styles.menu}>
		{menuList.map((m) => {
			const resolved = useResolvedPath(m.path);
			const match = useMatch({ path: resolved.pathname, end: true });
			const {Icon} = m;

			if (m.path.includes('trading') && !isTrader) {
				return;
			}
			return <NavLink
				className={styles.linkItem}
				key={m.path}
				to={`/${i18n.language}${m.path ? '/'+m.path :''}`}
				Icon={Icon}
			>
				<Text className={styles.menuItem} size='m' color={match ? 'white' : ''}>
					{t(m.text)}
				</Text>
			</NavLink>;
		})}
	</div>;
};
