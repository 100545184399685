import React from 'react';
import styles from './footer.module.scss';
import { Text } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';
import { useTranslation } from 'react-i18next';
import { TelegramIcon, YoutubeIcon } from 'forly-kit/dist/icons';
import { useNavigate } from 'react-router-dom';

export const Footer: React.FC = () => {
	const {t, i18n} = useTranslation();
	const navigate = useNavigate();

	const navToHolds = () => {
		navigate(`/${i18n.language}/holds`);
	};

	const navToReport = () => {
		navigate(`/${i18n.language}/reports`);
	};

	const navToDocuments = () => {
		navigate(`/${i18n.language}/documents`);
	};

	return <div className={styles.footer}>
		<Text size='button' className={styles.phone} color={colors.primary700}>
			<a className={styles.link} href="tel:+78007079744">+7 (800) 707-97-44</a>
		</Text>
		<div className={styles.social}>
			<a href="https://t.me/forlyinvest" target='_blank' rel='noreferrer'>
				<TelegramIcon />
			</a>
			<a href="https://www.youtube.com/channel/UCDQNBeRIHdXKvWWa8PwTp7A" target='_blank' rel='noreferrer'>
				<YoutubeIcon />
			</a>
		</div>
		<Text size='button' className={styles.tech} color={colors.primary700}>
			<a className={styles.link} href="https://t.me/forlycapitalsupport_bot" target='_blank' rel='noreferrer'>
				{t('support')}
			</a>
		</Text>
		<Text size='button' color={colors.primary700}>
			<a 
				className={styles.link}
				href={`${CONFIG.sameSite}/${i18n.language}/holds/`} target='_blank' rel="noreferrer"
			>
				{t('usersHolds')}
			</a>
		</Text>

		<Text size='button' color={colors.primary700}>
			<a 
				className={styles.link}	
				href={`${CONFIG.sameSite}/${i18n.language}/reports/`} target='_blank' rel="noreferrer"
			>
				{t('report')}
			</a>
		</Text>

		<Text size='button'  color={colors.primary700}>
			<a 
				className={styles.link}
				href={`${CONFIG.sameSite}/${i18n.language}/documents/`} 
				target='_blank' 
				rel="noreferrer"
			>
				{t('documents')}
			</a>
			
		</Text>
		<Text size='s' className={styles.company} color={colors.neutral500}>
			® Forly US LP
		</Text>
	</div>;
};
