import React from 'react';
import cn from 'classnames';
import styles from './paginator.module.scss';
import { IPaginator } from './paginator.types';
import { Button } from 'forly-kit';
import { ArrowIcon } from 'forly-kit/dist/icons';

const Component: React.FC<IPaginator> = ({
	className,
	children,
	lastPage,
	currentPage,
	changePage
}) => {

	const prevPage = () => {
		changePage(currentPage - 1);
	};

	const nextPage = () => {
		changePage(currentPage + 1);
	};

	const changePageHandler = (page: number) => {
		changePage(page);
	};

	const Ellipsis = () => {
		if (lastPage < 2) {
			return null;
		}
		return <span className={styles.ellipsis}>...</span>;
	};

	return <div className={cn(className, styles.paginator)}>
		<div className={styles.content}>
			{children}
		</div>
		<div className={styles.pages}>
			{lastPage <= 6 && <>
				{lastPage !== 1 && <button className={cn(styles.button, styles.prevBtn, styles.reverseIcon)} onClick={prevPage} disabled={currentPage === 1}>
					<ArrowIcon />
				</button>}

				<span className={cn({
					[styles.active]:currentPage === 1
				})} onClick={() => changePageHandler(1)}>1</span>
				{lastPage >= 2 && <span className={cn({
					[styles.active]:currentPage === 2
				})} onClick={() => changePageHandler(2)}>2</span> }
				{lastPage >= 3 && <span className={cn({
					[styles.active]:currentPage === 3
				})} onClick={() => changePageHandler(3)}>3</span> }
				{lastPage >= 4 && <span className={cn({
					[styles.active]:currentPage === 4
				})} onClick={() => changePageHandler(4)}>4</span> }
				{lastPage >= 5 && <span className={cn({
					[styles.active]:currentPage === 5
				})} onClick={() => changePageHandler(5)}>5</span> }
				{lastPage >= 6 && <span className={cn({
					[styles.active]:currentPage === 6
				})} onClick={() => changePageHandler(6)}>6</span> }

				{lastPage !== 1 && <button className={cn(styles.button, styles.nextBtn)} onClick={nextPage} disabled={currentPage === lastPage}>
					<ArrowIcon />
				</button>}
			</>  }

			{lastPage > 6 && <>
				<button className={cn(styles.button, styles.prevBtn, styles.reverseIcon)} onClick={prevPage} disabled={currentPage === 1}>
					<ArrowIcon />
				</button>
				<span className={cn({
					[styles.active]:currentPage === 1
				})}
				onClick={() => changePageHandler(1)}>1</span>
				{currentPage <= 4
					? <span
						className={cn({[styles.active]:currentPage === 2})}
						onClick={() => changePageHandler(2)}
					>2</span>
					: <Ellipsis />
				}
				{lastPage >= 3 &&
					<span
						className={cn({[styles.active]:currentPage === 3})}
						onClick={() => changePageHandler(currentPage <= 3 ? 3 : currentPage-1)}
					>{currentPage <= 3 ? 3 : currentPage-1}</span>
				}
				{currentPage <= lastPage && lastPage >= 5 &&
					<span
						className={cn({[styles.active]:currentPage >= 4})}
						onClick={() => changePageHandler(currentPage <= 4 ? 4 : currentPage)}
					>{currentPage <= 4 ? 4 : currentPage}</span>
				}
				{currentPage >= 4 && currentPage+1 < lastPage &&
					<span
						onClick={() => changePageHandler(currentPage+1)}
					>{currentPage+1}</span>
				}
				{currentPage+1 <= lastPage-2 && lastPage > 5 &&
					<span className={styles.ellipsis}>...</span>
				}
				{lastPage !== currentPage &&
					<span
						className={cn({[styles.active]:currentPage === lastPage})}
						onClick={() => changePageHandler(lastPage)}
					>{lastPage}</span>
				}
				<button className={cn(styles.button, styles.nextBtn)} onClick={nextPage} disabled={currentPage === lastPage}>
					<ArrowIcon />
				</button>
			</>}
		</div>
	</div>;
};

export const Paginator = React.memo(Component);
