import React, { useEffect, useRef, useState } from 'react';
import styles from './forexInstructtion.module.scss';
import { IForexInstruction } from './ForexInstruction.types';
import { Button, Headline, Text } from 'forly-kit';
import { useTranslation } from 'react-i18next';
import { colors } from 'forly-kit/dist/colors';
import step2 from 'src/assets/img/instruction/roboforex/step2.jpg';
import step3 from 'src/assets/img/instruction/roboforex/step3.jpg';
import step5 from 'src/assets/img/instruction/roboforex/step5.jpg';
import step6 from 'src/assets/img/instruction/roboforex/step6.jpg';
import step7 from 'src/assets/img/instruction/roboforex/step7.jpg';
import step8 from 'src/assets/img/instruction/roboforex/step8.jpg';
import { Warning } from '../../Warning/Warning';
import { ArrowCircle16Icon, ArrowCircleRevertIcon } from 'forly-kit/dist/icons';
import { partnersAPI } from 'services/partnersService';
import { Loader } from '../../Loader/Loader';

export const ForexInstruction: React.FC<IForexInstruction> = ({
	close
}) => {
	const {data: headData, isLoading: headLoading} = partnersAPI.useHeadQuery(null);
	const ref = useRef<HTMLDivElement>(null);
	const {t} = useTranslation();
	const [step, setStep] = useState<number>(1);

	const registerLink = 'https://rbfxdirect.com/ru/lk/?a=hrkb';
	const verificationLink = 'https://my.roboforex.com/ru/profile/verification-of-the-documents/';
	const traiderLink = 'https://my.roboforex.com/en/copyfx/providers/show/224594/';

	useEffect(() => {
		ref.current?.scrollIntoView({behavior: 'smooth'});
	}, [step]);

	const nextStep = () => {
		setStep(prev => prev + 1);
	};

	const prevStep = () => {
		setStep(prev => prev - 1);
	};

	if (headLoading) {
		return <Loader />;
	}

	return <div>
		<Headline type='h2' className={styles.title}>
			<span ref={ref}>
				{t('instructions.forex.title')}
			</span>

		</Headline>
		<Headline type='h4' color={colors.neutral600}>
			{step === 1 ? t('instructions.forex.subtitle') : t('instructions.forex.subtitle2')}
		</Headline>
		<span className={styles.divider}></span>

		{step === 1 && <>
			<div className={styles.stepList}>
				<Headline type='h4'>
					1.
				</Headline>
				<div className={styles.step1Content}>
					<Headline type='h4'>
						{t('instructions.forex.step1.title')}
					</Headline>
					<a href={registerLink} target='_blank' rel="noreferrer">
						<Text size='button' color={colors.primary700}>
							{headData?.links?.link_robo || registerLink}
						</Text>
					</a>
				</div>
				<span className={styles.divider}></span>

				<Headline type='h4'>
					2.
				</Headline>
				<div className={styles.step2Content}>
					<Headline type='h4'>
						{t('instructions.forex.step2.title')}
					</Headline>
					<img src={step2} alt=''/>
				</div>
				<span className={styles.divider}></span>

				<Headline type='h4'>
					3.
				</Headline>
				<div className={styles.step2Content}>
					<Headline type='h4'>
						{t('instructions.forex.step3.title')}
					</Headline>
					<img src={step3} alt=''/>
				</div>
				<Warning
					className={styles.warning}
					text={t('instructions.forex.step3.warning', { returnObjects: true })}
					title={`${t('important')}!`}
				/>
				<span className={styles.divider}></span>

				<Headline type='h4'>
					4.
				</Headline>
				<div className={styles.step4Content}>
					<Headline type='h4'>
						{t('instructions.forex.step4.title')}
					</Headline>
					<div className={styles.table}>
						<Headline type='h4' color={colors.neutral500}>
							{t('instructions.forex.step4.table.label1')}
						</Headline>
						<Headline type='h4'>
							{t('instructions.forex.step4.table.value1')}
						</Headline>
						<Headline type='h4' color={colors.neutral500}>
							{t('instructions.forex.step4.table.label2')}
						</Headline>
						<Headline type='h4'>
							{t('instructions.forex.step4.table.value2')}
						</Headline>
						<Headline type='h4' color={colors.neutral500}>
							{t('instructions.forex.step4.table.label3')}
						</Headline>
						<Headline type='h4'>
							{t('instructions.forex.step4.table.value3')}
						</Headline>
						<Headline type='h4' color={colors.neutral500}>
							{t('instructions.forex.step4.table.label4')}
						</Headline>
						<Headline type='h4'>
							{t('instructions.forex.step4.table.value4')}
						</Headline>
						<Headline type='h4' color={colors.neutral500}>
							{t('instructions.forex.step4.table.label5')}
						</Headline>
						<Headline type='h4'>
							{t('instructions.forex.step4.table.value5')}
						</Headline>
					</div>
					<Text size='m'>
						<p>{t('instructions.forex.step4.closeRegister')}</p>
						<span>{t('instructions.forex.step4.afterConfirm')}</span>
					</Text>
				</div>
				<Warning
					className={styles.warning}
					text={t('instructions.forex.step4.warning')}
					title={`${t('important')}!`}
				/>
				<span className={styles.divider}></span>

				<Headline type='h4'>
					5.
				</Headline>
				<div className={styles.step1Content}>
					<Headline type='h4'>
						{t('instructions.forex.step5.title')}
					</Headline>
					<a href={verificationLink} target='_blank' rel='noreferrer'>
						<Text size='button' color={colors.primary700}>
							{verificationLink}
						</Text>
					</a>
					<img src={step5} alt=''/>
					<Text size='m'>
						<p>{t('instructions.forex.step5.confirmEmail')}</p>
						<span>{t('instructions.forex.step5.confirmEmailText')}</span>
					</Text>
				</div>
				<span className={styles.divider}></span>

				<Headline type='h4'>
					6.
				</Headline>
				<div className={styles.step2Content}>
					<Headline type='h4'>
						{t('instructions.forex.step6.title')}
					</Headline>
					<Text size='m'>
						{t('instructions.forex.step6.needFoto')}
					</Text>
					<div className={styles.list}>
						<Text size='m'>
							1.
						</Text>
						<Text size='m'>
							{t('instructions.forex.step6.loadPage')}
						</Text>
						<Text size='m'>
							2.
						</Text>
						<Text size='m'>
							{t('instructions.forex.step6.verification')}
						</Text>
					</div>
					<img src={step6} alt=''/>
				</div>
				<span className={styles.divider}></span>
			</div>

			<div className={styles.step1Footer}>
				<Button variant='primary' text={t('next')} onClick={nextStep} />
			</div>
		</> }

		{step === 2 && <>
			<div className={styles.stepList}>
				<Headline type='h4'>
				7.
				</Headline>
				<div className={styles.step2Content}>
					<Headline type='h4'>
						{t('instructions.forex.step7.title')}
					</Headline>
					<div className={styles.list}>
						<Text size='m'>
						1.
						</Text>
						<Text size='m'>
							{t('instructions.forex.step7.item1')}
						</Text>
						<Text size='m'>
						2.
						</Text>
						<Text size='m'>
							{t('instructions.forex.step7.item2')}
						</Text>
						<Text size='m'>
							3.
						</Text>
						<Text size='m'>
							{t('instructions.forex.step7.item3')}
						</Text>
						<Text size='m'>
							4.
						</Text>
						<Text size='m'>
							{t('instructions.forex.step7.item4')}
						</Text>
					</div>
					<img src={step7} alt=''/>
				</div>
				<Warning className={styles.warning} isPs text={t('instructions.forex.step7.warning')} title='P.S.' />
				<Text size='m' className={styles.fullWith}>
					<span>{t('instructions.forex.step7.input')}</span>
					<br />
					<br />
					<span><span>{t('instructions.forex.step7.afterInput')}</span></span>
				</Text>
				<span className={styles.divider}></span>

				<Headline type='h4'>
					8.
				</Headline>
				<div className={styles.step1Content}>
					<Headline type='h4'>
						{t('instructions.forex.step8.title')}
					</Headline>
					<a href={headData?.links?.link || traiderLink} target='_blank' rel='noreferrer'>
						<Text size='button' color={colors.primary700}>
							{headData?.links?.link || traiderLink}
						</Text>
					</a>
					<Text size='m' className={styles.mtb8}>
						{t('instructions.forex.step8.send')}
					</Text>
					<img src={step8} alt=''/>
				</div>
				<span className={styles.divider}></span>
			</div>
			<div className={styles.step2Footer}>
				<Button variant='secondary' text={t('back')} icon={{component: ArrowCircleRevertIcon}} onClick={prevStep} />
				<Button
					variant='primary'
					text={t('next')}
					onClick={close}
					icon={{component: ArrowCircle16Icon, position: 'right'}}
				/>
			</div>
		</>}
	</div>;
};
