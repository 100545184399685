import React from 'react';
import styles from './headerCommon.module.scss';
import { LogoBetaDesktopIcon, LogoBetaTabletIcon } from 'forly-kit/dist/icons';
import { colors } from 'forly-kit/dist/colors';

export const HeaderCommon: React.FC = () => {
	return <div className={styles.header}>
		<a href="">
			<LogoBetaDesktopIcon color={colors.primary900}/>
		</a>
	</div>;
};
