// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modalWrapper--sKoJf{max-width:386px}.title--IRCFL{margin-bottom:8px}.subtitle--hBEYY{margin-bottom:32px}.footer--ReX2q{display:grid;grid-template-columns:1fr;row-gap:8px}\n", "",{"version":3,"sources":["webpack://./../src/components/Modals/ActivationModals/ActivationExtensionModal/activationExtensionModal.module.scss"],"names":[],"mappings":"AAIA,qBACE,eAAgB,CACjB,cAGC,iBAAkB,CACnB,iBAGC,kBAAmB,CACpB,eAGC,YAAa,CACb,yBAA0B,CAC1B,WAAY","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.modalWrapper {\n  max-width: 386px;\n}\n\n.title {\n  margin-bottom: 8px;\n}\n\n.subtitle {\n  margin-bottom: 32px;\n}\n\n.footer {\n  display: grid;\n  grid-template-columns: 1fr;\n  row-gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalWrapper": "modalWrapper--sKoJf",
	"title": "title--IRCFL",
	"subtitle": "subtitle--hBEYY",
	"footer": "footer--ReX2q"
};
export default ___CSS_LOADER_EXPORT___;
