import React from 'react';
import styles from './ClosePositionModal.module.scss';
import { Modal } from '../Modal';
import { IClosePositionData, IClosePositionModal } from './ClosePositionModal.types';
import { useForm } from 'react-hook-form';
import { Button, Headline, Input } from 'forly-kit';
import { changeNumberInput } from 'src/helpers/patterns';

const Component: React.FC<IClosePositionModal> = ({
	isOpen,
	onClose,
	closePosition,
	isLoading
}) => {
	const {
		register,
		formState: { errors, isValid, isDirty },
		setValue,
		getValues,
		handleSubmit
	} = useForm<IClosePositionData>({
		mode: 'onChange',
	});

	const closePositionHandler = (data: IClosePositionData) => {
		closePosition(+data.quantity);
	};

	const changeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = changeNumberInput(e.currentTarget.value);
		setValue('quantity', +value);
	};

	if (!isOpen) {
		return null;
	}

	return <Modal
		onClose={onClose}
	>
		<div className={styles.wrapper}>
			<Headline type='h2'>
                Закрытие позиции
			</Headline>
			<form onSubmit={handleSubmit(closePositionHandler)}>
				<Input 
					label='Объем, %'
					register={() => register('quantity', {
						required: {
							value: true,
							message: 'Обязательно для заполнения'
						},
						max: {
							value: 100,
							message: 'Максимум 100'
						},
						min: {
							value: 0.001,
							message: 'Минимум 0,001'
						},
						onChange: changeQuantity
					})}
				/>
				<Button 
					variant='primary' 
					text='Закрыть позицию' 
					fill 
					disabled={!isDirty || !isValid || isLoading}
				/>
			</form>
		</div>
	</Modal>;
};

export const ClosePositionModal = React.memo(Component);
