import React, { useEffect, useState } from 'react';
import styles from './profileEditForm.module.scss';
import { userAPI } from 'services/userService';
import { Loader } from '../../Loader/Loader';
import { EditStepIndicator } from './EditStepIndicator/EditStepIndicator';
import { Button, Headline, Input, Text } from 'forly-kit';
import { baseAuthAPI } from 'services/baseAuthService';
import { useForm } from 'react-hook-form';
import { IEditForm, IProfileEditFormData } from './ProfileEditForm.types';
import { useTranslation } from 'react-i18next';
import { changeNumberInput, emailPattern } from '../../../helpers/patterns';
import { colors } from 'forly-kit/dist/colors';

// @ts-ignore
import avatar from 'src/assets/img/fakeAvatar.jpg';

const Form: React.FC<IEditForm> = ({
	editFinish
}) => {
	const {t} = useTranslation();
	const [step, setStep] = useState<number>(1);
	const [image, setImage] = useState<any>(null);
	const [birtday, setBirthday] = useState<string>('');

	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
	} = useForm<IProfileEditFormData>({
		mode: 'onChange',
	});

	const {data: profile} = userAPI.useFetchProfileQuery('');
	const [changeProfile, {isSuccess: successEdit}] = userAPI.useChangeProfileMutation();
	const [changeAvatar, {isSuccess: successEditAvatar}] = userAPI.useChangeProfileMutation();
	const {data: user, refetch: refetchUser} = baseAuthAPI.useFetchUserQuery(null);

	useEffect(() => {
		if (!profile) {
			return;
		}
		if (profile.birthday) {
			const [year, month, day] = profile.birthday.split('-');
			const value = [day, month, year].join('/');
			setBirthday(value);
			setValue('birthday', value);
		}
		if (profile.phone && profile.phone[0] !== '+') {
			setValue('phone', `+${profile.phone}`);
		}
	}, [profile]);

	useEffect(() => {
		if (successEdit) {
			refetchUser();
			editFinish();
		}
	}, [successEdit]);

	const changeDate = (elem: any) => {
		const value = elem.currentTarget.value;
		if (value.length < birtday.length) {
			setValue('birthday', value);
			setBirthday(value);
			return;
		}

		const [day, month, year] = value.split('/');

		if (day && day.match(/\D/)) {
			setValue('birthday', birtday);
			return;
		}

		if (month && month.match(/\D/)) {
			setValue('birthday', birtday);
			return;
		}

		if (year && year.match(/\D/)) {
			setValue('birthday', birtday);
			return;
		}

		if (day && +day > 31) {
			setValue('birthday', birtday);
			return;
		}
		if (month && +month > 12) {
			setValue('birthday', birtday);
			return;
		}
		if (year && +year > (new Date).getFullYear()) {
			setValue('birthday', birtday);
			return;
		}

		if (value.length === 2) {
			setValue('birthday', `${day}/`);
			setBirthday(`${day}/`);
			return;
		}

		if (value.length === 5) {
			setValue('birthday', `${[day, month].join('/')}/`);
			setBirthday(`${[day, month].join('/')}/`);
			return;
		}

		if (day && month && year) {
			setValue('birthday', [day, month, year].join('/'));
			setBirthday([day, month, year].join('/'));
			return;
		}

		setValue('birthday', value);
		setBirthday(value);
	};

	const changePhone = (elem: any) => {
		const value = elem.currentTarget.value;

		if (elem.length && elem[0] !== '+') {
			setValue('phone', `+${value}`);
		}
	};

	const changeTelegram = (elem: any) => {
		setValue('telegram_id', changeNumberInput(elem.currentTarget.value)+'');
	};

	const changeImage = (value: any) => {
		const file = value.currentTarget.files[0];
		const formData = new FormData();
		formData.append('avatar', file);
		changeAvatar(formData);
	};

	const nextStep = () => {
		setStep((prev) => prev + 1);
	};

	const prevStep = () => {
		setStep((prev) => prev - 1);
	};

	const saveProfile = (data: IProfileEditFormData) => {
		const [day, month, year] = data.birthday.split('/');
        const dataProfile = {
			...data,
			phone: data.phone.slice(1),
			birthday: [year, month, day].join('-')
		};
        if (image) {
            dataProfile.avatar = image;
        }
		changeProfile(dataProfile);
	};

	if (!profile) {
		return <Loader />;
	}

	return <form className={styles.form}>
		<EditStepIndicator step={step} />
		{step === 1 && <>
			<Headline className={styles.title} type='h2' color={colors.primary800}>
				{t('commonInfo')}
			</Headline>
			<Input
				label={t('surname')}
				register={() =>
					register('surname', {
						required: `${t('errors.requiredField')}`,
						value: profile.surname
					})
				}
				errorMsg={errors.surname?.message || ''}
				status={errors.surname ? 'error' : null}
			/>
			<Input
				label={t('name')}
				register={() =>
					register('name', {
						required: `${t('errors.requiredField')}`,
						value: profile.name
					})
				}
				errorMsg={errors.name?.message || ''}
				status={errors.name ? 'error' : null}
			/>
			<Input
				label={`${t('middleName')} ${t('noRequired')}`}
				register={() =>
					register('middle_name',{
						value: profile.middle_name
					})
				}
				errorMsg={errors.middle_name?.message || ''}
				status={errors.middle_name ? 'error' : null}
			/>
			<Input
				label={t('birthday')}
				register={() =>
					register('birthday', {
						onChange: changeDate,
						value: birtday
					})
				}
				errorMsg={errors.birthday?.message || ''}
				status={errors.birthday ? 'error' : null}
			/>
		</>}
		{step === 2 && <>
			<Headline className={styles.title} type='h2' color={colors.primary800}>
				{t('contacts')}
			</Headline>
			<Input
				label={t('email')}
				register={() =>
					register('email', {
						required: `${t('errors.requiredField')}`,
						pattern: {
							value: emailPattern,
							message: 'invalid'
						},
						value: user?.email || ''
					})
				}
				errorMsg={errors.email?.message || ''}
				status={errors.email ? 'error' : null}
			/>
			<Input
				label={`${t('backupEmail')} ${t('noRequired')}`}
				register={() =>
					register('backup_email', {
						pattern: {
							value: emailPattern,
							message: 'invalid'
						},
						value: profile?.backup_email || ''
					})
				}
				errorMsg={errors.backup_email?.message || ''}
				status={errors.backup_email ? 'error' : null}
			/>
			<Input
				label={t('phone')}
				register={() =>
					register('phone', {
						required: `${t('errors.requiredField')}`,
						value: profile.phone || '',
						onChange: changePhone
					})
				}
				errorMsg={errors.phone?.message || ''}
				status={errors.phone ? 'error' : null}
			/>
			<Input
				containerClass={styles.telegramInput}
				label={t('telegramInfo')}
				// desc={t('telegramIDDesc')}
				register={() =>
					register('telegram_id', {
						required: `${t('errors.requiredField')}`,
						value: profile.telegram_id || '',
						onChange: changeTelegram
					})
				}
				errorMsg={errors.telegram_id?.message || ''}
				status={errors.telegram_id ? 'error' : null}
			/>
			<div className={styles.telegramDesc}>
				<Text size='s' color={colors.neutral500}>
					1) Запустить бота <a href={CONFIG.telegram} target='_blank' rel="noreferrer">@Infoforlycapital_bot</a>
				</Text>
				<Text size='s' color={colors.neutral500}>
					2) Получить Chat ID в Telegram&nbsp;-&nbsp;боте
					<a href='https://t.me/username_to_id_bot' target='_blank' rel="noreferrer"> @username_to_id_bot</a>
				</Text>
			</div>

		</>}
		{step === 3 && <>
			<Headline className={styles.title} type='h2' color={colors.primary800}>
				{t('avatar')}
			</Headline>
			<img className={styles.avatarImg} src={profile.avatar || avatar}  width={192} height={192} alt=''/>
			<label className={styles.label} htmlFor='avatar'>
				<Text size="button" color={colors.primary700} >
					Загрузить изображение
				</Text>
			</label>
			<input className={styles.avatarInput} name='avatar' id='avatar' type='file' accept="image/*" onChange={changeImage}  />
		</>}
		{step === 1
			? <Button variant='primary' text={t('next')} onClick={nextStep} type='button' />
			: <div className={styles.footer}>
				<Button variant='secondary' text={t('back')} type='button' onClick={prevStep}/>
				<Button
					variant='primary'
					text={step === 2 ? t('next') : t('save')}
					type='button'
					onClick={step === 3 ? handleSubmit(saveProfile) : nextStep}
				/>
			</div>
		}
	</form>;
};

export const ProfileEditForm = React.memo(Form);
