import React, { useMemo } from 'react';
import { IBuyConfirmModal } from './activationModals.types';
import { Modal } from '../Modal';
import { Button, Headline } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';
import styles from './buyActivationModal.module.scss';
import { BusdIcon, ForlyTokenIcon } from 'forly-kit/dist/icons';
import { EBalanceSymbol } from 'types/common.types';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../../helpers/formatNumber';

const BuyConfirm: React.FC<IBuyConfirmModal> = ({
	onClose,
	isOpen,
	balance,
	price,
	payRequest,
}) => {
	const {t} = useTranslation();
	const payToken = useMemo(() => {
		return balance.token_symbol === EBalanceSymbol.FT ? 'FCC' : 'BUSD';
	}, [balance]);

	const TokenIcon = useMemo(() => {
		return payToken === 'FCC' ? ForlyTokenIcon : BusdIcon;
	}, [payToken]);

	if (!isOpen) {
		return null;
	}

	return <Modal onClose={onClose}>
		<Headline type='h3' className={styles.confirmTitle}>
			{t('buyActivationFrom', {token: payToken})}
		</Headline>

		<div className={styles.balanceBlock}>
			<div className={styles.priceItem}>
				<Headline type='h4' color={colors.neutral600}>
					{t('cost')} {payToken}
				</Headline>
				<Headline type='h1' className={styles.itemValue}>
					≈ {formatNumber(price, 2)}
					<TokenIcon className={styles.icon} color={colors.primary900} />
				</Headline>
			</div>

			<div className={styles.priceItem}>
				<Headline type='h4' color={colors.neutral600}>
					{t('balance')} {payToken}
				</Headline>
				<Headline type='h1' className={styles.itemValue}>
					{formatNumber(balance.value_free, 2)}
					<TokenIcon className={styles.icon} color={colors.primary900} />
				</Headline>
			</div>
		</div>

		<Button
			className={styles.confirmButton}
			variant='primary'
			text={t('buy')}
			fill
			onClick={() => payRequest(balance.token_symbol)}
		/>
	</Modal>;
};

export const BuyConfirmModal = React.memo(BuyConfirm);
