import React, { useState } from 'react';
import styles from './activationExtensionModal.module.scss';
import { IActivationExtensionModal } from './activationExtensionModal.types';
import { Modal } from '../../Modal';
import { Button, Headline, Text } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';
import { useTranslation } from 'react-i18next';

const ModalComponent: React.FC<IActivationExtensionModal> = ({
	isOpen,
	onClose,
	buyActivationHandler,
	useActivationHandler,
	freeActivations
}) => {
	const {t} = useTranslation();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const useExistingActivation = async () => {
		if (!useActivationHandler) {
			return;
		}
		setIsLoading(true);
		await useActivationHandler();
		onClose();
		setIsLoading(false);
	};

	if (!isOpen) {
		return null;
	}

	return <Modal onClose={onClose}>
		<div className={styles.modalWrapper}>
			<Headline className={styles.title} type='h3'>
				{t('keys.activations.extendActivation')}
			</Headline>
			<Text className={styles.subtitle} size='m' color={colors.neutral600}>
				{t('keys.activations.extendCurrentActivation')}
			</Text>
			<div className={styles.footer}>
				<Button
					variant='primary'
					fill
					text={t('keys.activations.extendCurrentActivationAction')}
					disabled={!freeActivations || isLoading}
					onClick={useExistingActivation}
				/>
				<Button
					variant='tertiary'
					text={t('keys.activations.extendNewActivation')}
					onClick={buyActivationHandler}
					disabled={isLoading}
				/>
			</div>
		</div>
	</Modal>;
};

export const ActivationExtensionModal = React.memo(ModalComponent);
