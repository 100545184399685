import { useEffect, useState } from 'react';

export const useWindowSize = (): {windowSize: number} => {
	const [windowSize, setWindowSize] = useState<number>(window.innerWidth);
	
	const resizeHandler = () => {
		setWindowSize(window.innerWidth);
	};
	
	useEffect(() => {
		return () => {
			window.removeEventListener('resize', resizeHandler);
		};
	}, []);
	
	window.addEventListener('resize', resizeHandler);
	
	
	
	return {windowSize};
};
