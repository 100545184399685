import { Button } from 'forly-kit';
import React, { useEffect, useState } from 'react';
import styles from './futureOrder.module.scss';
import { IFutureOrderProps } from './futureOrder.types';
import { Text } from 'forly-kit/dist';
import { colors } from 'forly-kit/dist/colors';
import { futuresAPI } from './../../../services/futuresService';
import { Loader } from 'src/components/Loader/Loader';

const Component: React.FC<IFutureOrderProps> = ({
	order,
	closeOrder
}) => {
	const [loading, setLoading] = useState<boolean>(false);
	// const [closeOrder, {isLoading: closePending, isSuccess: successClose}] = futuresAPI.useCloseOrderMutation();
	// const {refetch} = futuresAPI.useGetOrdersQuery(null);

	// useEffect(() => {
	// 	if (successClose) {
	// 		refetch();
	// 	}
	// }, [successClose]);
	
	const closeOrderHandler = () => {
		setLoading(true);
		closeOrder(order.connected_to || 0, order.orig_type);
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	};

	const getPrice = () => {
		if (order.orig_type === 'STOP_MARKET' || order.orig_type === 'TAKE_PROFIT_MARKET') {
			return order.stop_price;
		}

		return order.price;
	};

	return <div className={styles.order}>
		{loading && <Loader internal/>}
		<div className={styles.orderInfo}>
			<Text size='m' color={order.side === 'SELL' ? colors.danger : colors.success}>
				{order.side === 'SELL' ? 'Продажа' : 'Покупка'}
			</Text>
			<Text size='m'>
				{order.symbol_name}
			</Text>
		</div>
		<div className={styles.orderData}>
			<div>
				<Text className={styles.orderDataItem} size='m' color={colors.neutral600}>Объем: </Text>
				<Text className={styles.orderDataItem} size='m'>{order.orig_qty || 'на всю позицию'}</Text>
			</div>
			<div>
				<Text className={styles.orderDataItem} size='m' color={colors.neutral600}>Цена: </Text>
				<Text className={styles.orderDataItem} size='m'>{getPrice()}</Text>
			</div>
		</div>
		<Button variant='tertiary' text='Закрыть ордер' onClick={closeOrderHandler}/>
	</div>;
};

export const FutureOrder = React.memo(Component);
