import React, { useMemo } from 'react';
import cn from 'classnames';
import styles from './mobileMenu.module.scss';
import { IMobileMenu } from './menu.types';
import { useTranslation } from 'react-i18next';
import { menuList } from '../../helpers/menu';
import { useMatch, useResolvedPath } from 'react-router-dom';
import { NavLink } from '../ui';
import { Text } from 'forly-kit';
import { authService } from 'services/authService';
import { setCookie } from 'src/helpers/cookie';
import { ExitIcon } from 'forly-kit/dist/icons';
import { colors } from 'forly-kit/dist/colors';
import { baseAuthAPI } from 'services/baseAuthService';

export const MobileMenu: React.FC<IMobileMenu> = (
	{isOpen,
		closeMenu
	}
) => {
	const {i18n, t} = useTranslation();

	const {data: user} = baseAuthAPI.useFetchUserQuery(null);

	const isTrader = useMemo(() => {
		if (!user) {
			return false;
		}
		const tradersGroup = user.groups.find((f) => f.name === 'traders');
		if (!tradersGroup) {
			return false;
		}

		const permission = tradersGroup.permissions.find((f) => f.codename === 'futures_trade');
		if (permission) {
			return true;
		}
		return false;
	}, [user]);

	const logout = async () => {
		const res = await authService.logout();
		if (res) {
			setCookie('access', '');
			window.location.href = `${CONFIG.sameSite}/${i18n.language}/login/`;
		}
	};

	return <div className={cn(styles.menuWrapper, {
		[styles.opened]: isOpen
	})}>
		{menuList.map((m) => {
			const resolved = useResolvedPath(m.path);
			const match = useMatch({ path: resolved.pathname, end: true });
			const {Icon} = m;
			
			if (m.path.includes('trading') && !isTrader) {
				return;
			}
			
			return <NavLink
				className={cn(styles.linkItem, {
					[styles.activeLink]: match
				})}
				key={m.path}
				to={`/${i18n.language}${m.path ? '/'+m.path :''}`}
				Icon={Icon}
				isMobile
				onClick={closeMenu}
			>
				<Text className={styles.menuItem} size='s' color='white'>
					{t(m.text)}
				</Text>
			</NavLink>;
		})}
		<div className={styles.exitButton} onClick={logout}>
			<ExitIcon color={colors.white}/>
			<Text className={styles.exitTitle} size='s' color='white'>
				{t('exit')}
			</Text>
		</div>
	</div>;
};
