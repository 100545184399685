import React from 'react';
import styles from './futuresInstruction.module.scss';
import step1img from 'src/assets/img/instruction/futures/1.jpg';
import step2img from 'src/assets/img/instruction/futures/2.jpg';
import step3img from 'src/assets/img/instruction/futures/3.jpg';
import step4_1img from 'src/assets/img/instruction/futures/4_1.jpg';
import step4_2img from 'src/assets/img/instruction/futures/4_2.jpg';
import step5img from 'src/assets/img/instruction/futures/5.jpg';
import { Headline, Text } from 'forly-kit';
import { useTranslation } from 'react-i18next';
import { StringMap, TOptions } from 'i18next';
import { ShieldIcon } from 'forly-kit/dist/icons';
import { Warning } from '../../Warning/Warning';
import { partnersAPI } from 'services/partnersService';
import { Loader } from '../../Loader/Loader';

export const FuturesInstruction: React.FC = () => {
	const {data: headData, isLoading: headLoading} = partnersAPI.useHeadQuery(null);
	const {t} = useTranslation();
	const keyBasis = 'instructions.futures.';
	const getKey = (key: string, options?:  TOptions<StringMap>): string => {
		return t(keyBasis+key, options);
	};

	const registerLink = 'https://www.binance.com/ru/futures/ref/132426733';

	if (headLoading) {
		return <Loader />;
	}

	return <div className={styles.wrapper}>
		<Headline type='h2' className={styles.title}>
			{getKey('title')}
		</Headline>

		<ul className={styles.stepList}>
			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>1.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step1.text1')}
						<Text size='button'>
							<a href={headData?.links?.link_binance || registerLink} target='_blank' rel="noreferrer">
								{headData?.links?.link_binance || registerLink}
							</a>
						</Text>
						<Text size='m'>
							{getKey('step1.text2')}
						</Text>
					</Text>
					<img className={styles.mt32} src={step1img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>2.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step2.text1')}
					</Text>
					<img className={styles.mt32} src={step2img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>3.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step3.text1')}
					</Text>
					<img className={styles.mt32} src={step3img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>4.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step4.text1')}
					</Text>
					<Warning
						className={styles.mt16}
						title={t('important')}
						text={getKey('step4.warning')}
						theme='DARK'
					/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>4.1</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step4_1.text1')}
					</Text>
					<Warning
						className={styles.mt16}
						title={t('securityRemain')}
						text={getKey('step4_1.warning')}
						Icon={ShieldIcon}
					/>
					<img className={styles.mt32} src={step4_1img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>4.2</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step4_2.text1')}
					</Text>
					<Warning
						className={styles.mt16}
						title={t('important')}
						text={getKey('step4_2.warning')}
						Icon={ShieldIcon}
					/>
					<img className={styles.mt32} src={step4_2img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>5.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step5.text1')}
						<ul>
							<li>
								<Text size='m'>
									{getKey('step5.text2')}
								</Text>
							</li>
							<li>
								<Text size='m'>
									{getKey('step5.text3')}
								</Text>
							</li>
						</ul>
					</Text>
					<img className={styles.mt32} src={step5img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>6.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step6.text1')}
					</Text>
					<Warning
						className={styles.mt16}
						title={t('important')}
						text={getKey('step6.warning')}
						theme='DARK'
					/>
				</div>
			</li>

		</ul>
	</div>;
};
