import React from 'react';
import styles from './addKeyButton.module.scss';
import { PlusCircleIcon } from 'forly-kit/dist/icons';
import { colors } from 'forly-kit/dist/colors';
import { Headline } from 'forly-kit';
import { IAddKeyButton } from './addKeyButton.types';
import { useTranslation } from 'react-i18next';

const AddButton: React.FC<IAddKeyButton> = ({addKeyHandler}) => {
	const {t} = useTranslation();

	return <div className={styles.addKey} onClick={addKeyHandler}>
		<PlusCircleIcon color={colors.primary800}/>
		<Headline type='h4' color={colors.primary800}>
			{t('keys.addApiKey')}
		</Headline>
	</div>;
};

export const AddKeyButton = React.memo(AddButton);
