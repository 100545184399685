import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { RootState } from 'store/store';
import { IAddKeyFormData } from '../components/Forms/AddKeyForm/addKeyForm.types';
import { IChangeAutoCommissionRequest, IPayCommissionRequest, ISpotKey } from 'types/apiKeys.types';
import { IUpdateFormData } from '../components/Forms/UpdateKeyForm/updateKeyForm.types';
import { IFutureOrder, IOrder } from 'types/trading.types';
import { IFuturePosition } from './../types/trading.types';

export const futuresAPI = createApi({
	reducerPath: 'futuresAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: `https://${CONFIG.api}/futures/`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).auth.token;
			if (token) {
				headers.set('authorization', `Bearer ${token}`);
			}
			return headers;
		}
	}),
	tagTypes: ['key', 'keys', 'orders'],
	endpoints: (build) => ({
		fetchFuturesKeys: build.query({
			query: () => ({
				url: '/token/'
			}),
		}),
		fetchFutureKeyById: build.query<any, number>({
			query: (id) => ({
				url: `/token/${id}/`
			}),
		}),
		addKey: build.mutation<Promise<any>, IAddKeyFormData>({
			query: (data) => ({
				url: '/token/',
				method: 'POST',
				body: {
					name: data.name,
					binance_key: data.publicKey,
					binance_secret: data.secretKey
				}
			}),
			invalidatesTags: ['keys']
		}),
		payCommission: build.mutation<any, IPayCommissionRequest>({
			query: (data) => ({
				url: `/commission/${data.id}/`,
				method: 'PUT',
				body: {
					currency: data.currency
				}
			})
		}),
		activateKey: build.mutation({
			query: (data) => ({
				url: `/request_token_activation/${data.id}/`,
				method: 'PUT',
				body: {
					is_requested: data.isRequested
				}
			})
		}),
		updateKey: build.mutation<Promise<any>, IUpdateFormData & {id: number, name: string}>({
			query: (data) => ({
				url: `/token/${data.id}`,
				method: 'PUT',
				body: {
					name: data.name,
					binance_key: data.publicKey,
					binance_secret: data.secretKey
				}
			})
		}),
		changeAutoPayCommission: build.mutation<any, IChangeAutoCommissionRequest>({
			query: (data) => ({
				url: `/auto_commission_token/${data.id}/`,
				method: 'PUT',
				body: {
					auto_commissions: data.autoCommission
				}
			}),
		}),
		createOrder: build.mutation<any, IOrder>({
			query: (order) => ({
				url: '/order/',
				method: 'POST',
				body: order
			}),
			invalidatesTags: ['orders']
		}),
		getOrders: build.query<IFutureOrder[], null>({
			query: () => ({
				url: '/order/'
			}),
			providesTags: result => ['orders']
		}),
		closeOrder: build.mutation<any, number>({
			query: (id) => ({
				url: `/order/${id}/`,
				method: 'DELETE'
			}),
			invalidatesTags: ['orders']
		}),
		getPositions: build.query<IFuturePosition[], number>({
			query: (id) => ({
				url: `/positions/${id}`
			})
		}),
		closePosition: build.mutation<any, {
			id: number,
			quantity: number,
		}>({
			query: (data) => ({
				url: `/position/${data.id}/?quantity=${data.quantity}`,
				method: 'DELETE'
			}) 
		}),
		closeAllOrders: build.mutation<any, number>({
			query: (id) => ({
				url: `/orders/${id}/`,
				method: 'DELETE'
			})
		}),
		closeAllPositions: build.mutation<any, number>({
			query: (id) => ({
				url: `/positions/${id}/`,
				method: 'DELETE'
			})
		}),
		getRequestedKeys: build.query({
			query: () => ({
				url: '/activate_token/',
			})
		}),
		activateKeyToTrading: build.mutation({
			query: (data: {id: number, isActive: boolean}) => ({
				url: `/activate_token/${data.id}/`,
				method: 'PUT',
				body: {
					is_active: data.isActive,
					is_requested: false,
				}
			})
		}),
		switchParamsKey: build.mutation({
			query: (id: number) => ({
				url: `/switch_params/${id}/`,
				method: 'PUT'
			})
		})
	}),
});
