import { IMenuItem } from 'types/menu.types';
import { ExchangeIcon } from '../components/icons/exchangeIcon';
import {MainIcon} from '../components/icons/mainIcon';
import {PartnersIcon} from '../components/icons/partnersIcon';
import { RefilIcon } from '../components/icons/refilIcon';
import { WalletIcon } from '../components/icons/walletIcon';
import { FuturesIcon, RoboforexMenuItemIcon, SpotIcon } from 'forly-kit/dist/icons';


export const menuList: IMenuItem[] = [
	{
		path: '',
		text: 'menu.index',
		Icon: MainIcon,
	},
// 	{
// 		path: 'exchange',
// 		text: 'menu.buyFCC',
// 		Icon: ExchangeIcon,
// 	},
	{
		path: 'refil',
		text: 'menu.refil',
		Icon: RefilIcon,
	},
	{
		path: 'finance',
		text: 'menu.finance',
		Icon: WalletIcon,
	},
//	{
//		path: 'spot',
//		text: 'menu.spot',
//		Icon: SpotIcon,
//	},
//	{
//		path: 'futures',
//		text: 'menu.futures',
//		Icon: FuturesIcon,
//	},
	{
		path: 'forex',
		text: 'menu.roboforex',
		Icon: RoboforexMenuItemIcon,
	},
	{
		path: 'partners',
		text: 'menu.partners',
		Icon: PartnersIcon,
	},
// 	{
// 		path: 'trading',
// 		text: 'trading',
// 		Icon: FuturesIcon,
// 	},
// 	{
// 		path: 'trading-spot',
// 		text: 'trading spot',
// 		Icon: SpotIcon,
// 	},
	// {
	// 	path: '',
	// 	text: 'Spot'
	// },
	// {
	// 	path: '',
	// 	text: 'Futures'
	// },
	// {
	// 	path: '',
	// 	text: 'RoboForex'
	// },
	// {
	// 	path: '',
	// 	text: 'Справка'
	// },
	// {
	// 	path: 'politics',
	// 	text: 'Политика',
	// 	Icon: PrivacyIcon,
	// }
];
