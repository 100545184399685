import React from 'react';
import styles from './createHoldModal.module.scss';
import { Modal } from '../Modal';
import { ICreateHoldModal } from './createHoldModal.types';
import { Headline, Text } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';
import { CreateHoldForm } from '../../Forms/CreateHoldForm/CreateHoldForm';
import {
	ICreateHoldFormData
} from '../../Forms/CreateHoldForm/createHoldForm.types';
import { useTranslation } from 'react-i18next';

export const CreateHoldModal: React.FC<ICreateHoldModal> = ({
	onClose,
	isOpen,
	successAction
}) => {
	const {t} = useTranslation();
	if (!isOpen) {
		return null;
	}
	
	return <Modal onClose={onClose}>
		<div className={styles.createHoldWrapper}>
			<Headline className={styles.title} type='h2'>{t('frozen')} Forly Crypto Coin (FCC)</Headline>
			<ul className={styles.conditionList}>
				<li>
					<Text size='m' color={colors.primary800}  className={styles.condition}>
						{t('createHoldDescription')}
					</Text>
				</li>
				<li>
					<Text size='m' color={colors.primary800}  className={styles.condition}>
						{t('frozenPeriodNoUse')}
					</Text>
				</li>
				<li>
					<Text size='m' color={colors.primary800}  className={styles.condition}>
						{t('maxHolds', {value: 10})}
					</Text>
				</li>
			</ul>
			<CreateHoldForm successAction={successAction} />
		</div>
	</Modal>;
};
