// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".divider--FZS6w{width:100%;height:1px;background:#CED5F5;margin:24px 0}\n", "",{"version":3,"sources":["webpack://./../src/pages/Forex/ForexKey/forexKey.module.scss","webpack://./../node_modules/forly-kit/dist/_colors.scss"],"names":[],"mappings":"AAIA,gBACE,UAAW,CACX,UAAW,CACX,kBCiBkB,CDhBlB,aAAc","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.divider {\n  width: 100%;\n  height: 1px;\n  background: $neutral200;\n  margin: 24px 0;\n}\n","$background-color: #F8F9FF;\r\n$white: #FFFFFF;\r\n$danger: #B20F25;\r\n$success: #2E8742;\r\n\r\n// PRIMARY\r\n$primary900: #262F56;\r\n$primary800: #0C1A66;\r\n$primary700: #1D2F99;\r\n$primary600: #354ACB;\r\n$primary500: #556AEB;\r\n$primary400: #6E82FE;\r\n$primary300: #8FA0FF;\r\n$primary200: #B9C4FF;\r\n$primary100: #EBEFFF;\r\n\r\n// NEUTRAL\r\n$neutral900: #2E3242;\r\n$neutral800: #42475C;\r\n$neutral700: #575C75;\r\n$neutral600: #6D738F;\r\n$neutral500: #838AA8;\r\n$neutral400: #9BA2C2;\r\n$neutral300: #B4BBDB;\r\n$neutral200: #CED5F5;\r\n$neutral100: #E3E7F9;\r\n\r\n// SECONDARY\r\n$secondary900: #001733;\r\n$secondary800: #002F66;\r\n$secondary700: #004899;\r\n$secondary600: #0061CC;\r\n$secondary500: #007AFF;\r\n$secondary400: #3B98FF;\r\n$secondary300: #75B6FF;\r\n$secondary200: #B0D5FF;\r\n$secondary100: #EBF4FF;\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": "divider--FZS6w"
};
export default ___CSS_LOADER_EXPORT___;
