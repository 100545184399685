import { RootState } from 'store/store';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IProfile, IProfileResponse } from 'types/profile.types';

export const userAPI = createApi({
	reducerPath: 'userAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: `https://${CONFIG.api}/profile/`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).auth.token;
			if (token) {
				headers.set('authorization', `Bearer ${token}`);
			}
			return headers;
		},
	}),
	tagTypes: ['profile'],
	endpoints: (build) => ({
		fetchProfile: build.query<IProfile, string>({
			query: () => ({
				url: 'get/'
			}),
			providesTags: result => ['profile'],
			transformResponse:
				(response: IProfileResponse) => response.results[0],
		}),
		changeProfile: build.mutation({
			query: (body) => ({
				url: '/put/',
				method: 'PUT',
				body
			}),
			invalidatesTags: ['profile']
		}),
		sendCode: build.mutation({
			query: (body) => ({
				url: '/phone/send/',
				method: 'POST',
				body
			})
		}),
		confirmContact: build.mutation({
			query: (body) => ({
				url: '/phone/',
				method: 'POST',
				body
			}),
			invalidatesTags: ['profile']
		}),
		changeProfileLang: build.mutation({
			query: (lang: string) => ({
				url: '/lang/',
				method: 'POST',
				body: {
					lang
				}
			})
		})
	})
});
