import React, { useState } from 'react';
import styles from './login.module.scss';
import { SubmitHandler, useForm, useFormState } from 'react-hook-form';
import { IFormValues } from './login.types';
import { emailPattern } from '../../helpers/patterns';
import { authService } from 'services/authService';
import { useDispatch } from 'react-redux';
import { setToken } from 'store/slices/auth';
import { useNavigate } from 'react-router-dom';
import { checkToken } from '../../helpers/token';
import { Button, Headline, Input, Text } from 'forly-kit/dist';
import { Logo } from 'forly-kit/dist/icons';
import { colors } from 'forly-kit/dist/colors';
import { useTranslation } from 'react-i18next';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { LanguageSwitcher } from 'src/components/LanguageSwitcher/LanguageSwitcher';

export const Login: React.FC = () => {
	const { i18n } = useTranslation();
	const { executeRecaptcha } = useGoogleReCaptcha();

	const {
		register,
		formState: { errors },
		handleSubmit,
		control,
	} = useForm<IFormValues>({
		mode: 'onChange',
	});
	const { t } = useTranslation();

	const { dirtyFields, isValidating, touchedFields } = useFormState({
		control,
	});
	const [loginErr, setLoginErr] = useState<string>('');
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const onSubmit: SubmitHandler<IFormValues> = async (data) => {
		if (!executeRecaptcha) {
			return;
		}

		const token = await executeRecaptcha('yourAction');
		authService
			.auth(data.email, data.password, token)
			.then((res) => {
				if (typeof res === 'string') {
					dispatch(setToken(res));
					if (checkToken(res)) {
						navigate(`/${i18n.language}/`);
					}
				}
			})
			.catch((err) => {
				console.log(err);
				setLoginErr('invalid login');
			});
	};

	const getFieldStatus = (name: keyof IFormValues) => {
		if (!dirtyFields[name] && !touchedFields[name]) {
			return null;
		}

		if (errors[name]) {
			return 'error';
		} else {
			return 'success';
		}
	};

	const changePasswordHandler = () => {
		setLoginErr('');
	};

	const emailStatus = React.useMemo<'error' | 'success' | null>(() => {
		return getFieldStatus('email');
	}, [isValidating]);

	const passwordStatus = React.useMemo<'error' | 'success' | null>(() => {
		if (loginErr) {
			return 'error';
		}
		return null;
	}, [isValidating, loginErr]);

	return (
		<div className={styles.wrapper}>
			<div className={styles.leftSide}>
				<Logo />
				<div className={styles.header}>
					<Headline type="h0" color={colors.white}>
						{t('investing')}
					</Headline>
					<Headline type="h3" color={colors.primary200}>
						— {t('investingSubtitle')}
					</Headline>
				</div>
			</div>
			<div className={styles.rightSide}>
				<LanguageSwitcher className={styles.languageSwitcher} />
				<div className={styles.contentBlock}>
					<Headline
						type="h0"
						color={colors.primary800}
						className={styles.title}
					>
						{t('loginTitle')}
					</Headline>
					<div className={styles.subtitle}>
						<Text size="m" color={colors.primary900}>
							{t('loginSubtitle')}
						</Text>
						<Text size="button" color={colors.primary700}>
							<a href={`${CONFIG.passportUrl}/${i18n.language}/auth/sign-up/`}>{t('register')}</a>
						</Text>
					</div>

					<form className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
						<Input
							label="Email"
							register={() =>
								register('email', {
									required: {
										value: true,
										message: t('errors.requiredField'),
									},
									pattern: {
										value: emailPattern,
										message: t('errors.invalidFieldPatternEmail'),
									},
								})
							}
							status={emailStatus}
						/>
						<Input
							label={t('password')}
							type="password"
							register={() =>
								register('password', {
									required: {
										value: true,
										message: t('errors.requiredField'),
									},
									onChange: changePasswordHandler,
								})
							}
							status={passwordStatus}
							errorMsg={loginErr}
						/>
						<Button
							className={styles.loginBtn}
							text={t('login')}
							variant="primary"
							type="submit"
							fill
						/>
					</form>
					<Text
						className={styles.forgetLink}
						size="button"
						color={colors.primary700}
					>
						<a href={`${CONFIG.passportUrl}/${i18n.language}/auth/reset-pass/`}>{t('forgetPass')}</a>
					</Text>
				</div>
			</div>
		</div>
	);
};
