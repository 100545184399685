import React, { useEffect, useState } from 'react';
import { Link, LinkProps, useMatch, useResolvedPath } from 'react-router-dom';
import { IIcon } from '../../icons/icons.type';
import classNames from 'classnames';
import styles from './navLink.module.scss';
import { colors } from 'forly-kit/dist/colors';

export const NavLink: React.FC<LinkProps & {Icon?: React.FC<IIcon>, isMobile?: boolean}> =
	({children, to, Icon, className, isMobile, ...props}) => {
		const [isHover, setIsHover] = useState<boolean>(false);
		const resolved = useResolvedPath(to);
		const match = useMatch({ path: resolved.pathname, end: true });
		
		const classes = React.useMemo(() => {
			return classNames( className, styles.link, {
				[styles.active]: match,
				[styles.mobile]: isMobile
			});
		}, [match]);
		
		const color = React.useMemo(() => {
			if (isHover || match) {
				return '#FFFFFF';
			} else {
				return '#0C1A66';
			}
		}, [isHover, match]);

		const mobileColor = React.useMemo(() => {
			if (isHover || match) {
				return colors.primary700;
			} else {
				return colors.white;
			}
		}, [isHover, match]);
		
		const hoverHandler = (status: boolean) => {
			setIsHover(status);
		};
		
		return <Link
			className={classes}
			to={to}
			onMouseOver={() => hoverHandler(true)}
			onMouseLeave={() => hoverHandler(false)}
			replace
			{...props}
		>
			{Icon && <Icon
				color={isMobile ? mobileColor : color}
			/>}
			{children}
		</Link>;
	};
