import React, { useEffect, useState } from 'react';
import styles from './confirmCreateHoldModal.module.scss';
import { ConfirmTypeForm, IConfirmCreateHoldModal } from './confirmCreateHoldModal.types';
import { Modal } from '../Modal';
import { Button, Checkbox, Headline, Text } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';
import { userAPI } from 'services/userService';
import { EConfirmType } from 'types/common.types';
import { useForm } from 'react-hook-form';
import { bankAPI } from 'services/bankService';
import { useTranslation } from 'react-i18next';

const ConfirmHoldModal: React.FC<IConfirmCreateHoldModal> = ({
	onClose,
	isOpen,
	data,
	confirm
}) => {
	const {t} = useTranslation();
	const {
		register,
		setValue,
		getValues,
	} = useForm<ConfirmTypeForm>({
		mode: 'onChange',
	});

	const {data: profile, isLoading: profilePending} = userAPI.useFetchProfileQuery('');
	const [getCode, {isLoading: codePending, isSuccess: successGetCode}] = bankAPI.useGetCodeMutation();
	const [isAgree, setIsAgree] = useState<boolean>(false);
	const [confirmType, setConfirmType] = useState<EConfirmType>(EConfirmType.All);

	useEffect(() => {
		if (!profile) {
			return;
		}

		if (profile.phone_checked && profile.telegram_checked) {
			setConfirmType(EConfirmType.All);
			setValue('type', 'telegram');
			return;
		}
		if (profile.phone_checked) {
			setConfirmType(EConfirmType.OnlyPhone);
			return;
		}
		if (profile.telegram_checked) {
			setConfirmType(EConfirmType.OnlyTg);
		}
	}, [profile]);

	useEffect(() => {
		if (!profile) {
			return;
		}
		if (successGetCode) {
			if (profile.phone_checked && profile.telegram_checked) {
				confirm(getValues('type'));
				onClose();
				return;
			}
			if (profile.phone_checked) {
				confirm('phone');
				onClose();
				return;
			}
			if (profile.telegram_checked) {
				confirm('telegram');
				onClose();
				return;
			}
		}
	}, [successGetCode]);
	
	const changeAgreeHandler = (checked: boolean) => {
		setIsAgree(checked);
	};

	const getCodeHandler = () => {
		getCode({
			is_withdraw: false,
			type: getValues('type') || confirmType
		});
	};
	
	if (!isOpen || !data) {
		return null;
	}
	return <Modal onClose={onClose}>
		<div className={styles.wrapper}>
			<header className={styles.header}>
				<Headline type='h3'>{t('confirmHoldTitle')}</Headline>
				<Text size='m'>{t('actionNoCancel')}</Text>
			</header>
			<div className={styles.data}>
				<div className={styles.dataItem}>
					<Headline type='h4'>{t('count')} FCC</Headline>
					<Text size='m'>{data.value}</Text>
				</div>
				<div className={styles.dataItem}>
					<Headline type='h4'>{t('frozenPeriod')}</Headline>
					<Text size='m'>{data.period}</Text>
				</div>
			</div>
			<div className={styles.footer}>
				{confirmType === EConfirmType.All && <form className={styles.form}>
					<Headline type='h4' color={colors.primary800} className={styles.titleForm}>
						{t('confirmBy')}
					</Headline>
					<div className={styles.radio}>
						<input
							type='radio'
							id='telegram'
							value='telegram'
							{...register('type',
								{
									required: true,
								}
							)}
						/>
						<label htmlFor='telegram'>Telegram</label>
					</div>
					<div className={styles.radio}>
						<input
							type='radio'
							id='phone'
							value='phone'
							{...register('type',
								{
									required: true,
								}
							)}
						/>
						<label htmlFor='phone'>{t('callPhone')}</label>
					</div>
				</form>}

				<Checkbox name='aggrementHold' onChange={changeAgreeHandler} checked={isAgree}>
					<Text size='m'>{t('agreeWithPolicy')}</Text>
				</Checkbox>
				<div className={styles.actions}>
					<Button
						text={t('frozFCC')}
						variant='primary'
						fill
						disabled={!isAgree || codePending}
						onClick={getCodeHandler}
					/>
					<Button
						text={t('cancelAction')}
						variant='secondary'
						fill
						disabled={codePending}
					/>
				</div>
			</div>
		</div>
	</Modal>;
};

export const ConfirmCreateHoldModal = React.memo(ConfirmHoldModal);
