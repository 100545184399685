import React from 'react';
import styles from './balance.module.scss';
import cn from 'classnames';
import { IBalance } from './balance.types';
import { EBalanceSymbol } from 'types/common.types';
import { colors } from 'forly-kit/dist/colors';
import { Headline } from 'forly-kit';
import { formatNumber } from '../../helpers/formatNumber';

const Component: React.FC<IBalance> = ({
	className,
	symbol,
	label,
	value,
	Icon,
}) => {

	const getSymbolLabel = (): string => {
		if (symbol === EBalanceSymbol.FT) {
			return 'FCC';
		}

		if (symbol === EBalanceSymbol.USDT) {
			return 'BUSD';
		}

		return symbol;
	};

	return <div className={cn(className, styles.balance)}>
		<Icon
			color={colors.primary700}
		/>
		<Headline
			type='h4'
			className={cn({
				[styles.gradientText]: symbol === EBalanceSymbol.FT
			})}
			color={symbol === EBalanceSymbol.FT ? '' : colors.primary700}
		>
			{label}
		</Headline>
		<Headline
			type='h2'
			className={cn(styles.value, {
				[styles.gradientText]: symbol === EBalanceSymbol.FT
			})}
			color={symbol === EBalanceSymbol.FT ? '' : colors.primary700}
		>
			{formatNumber(value, 2)} {getSymbolLabel()}
		</Headline>
	</div>;
};

export const Balance = React.memo(Component);
