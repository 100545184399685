import { configureStore } from '@reduxjs/toolkit';
import profileReducer from './slices/profile';
import bankSlice from './slices/bank';
import managementSlice from './slices/management';
import { userAPI } from 'services/userService';
import authSlice from 'store/slices/auth';
import { bankAPI } from 'services/bankService';
import { managementAPI } from 'services/managementService';
import { activationKeysAPI } from 'services/activationKeysService';
import { spotAPI } from 'services/spotService';
import { futuresAPI } from 'services/futuresService';
import { baseAuthAPI } from 'services/baseAuthService';
import { partnersAPI } from 'services/partnersService';
import { forexKeysAPI } from 'services/forexKeysService';
import { indexAPI } from 'services/indexService';

export const store = configureStore({
	reducer: {
		auth: authSlice,
		profile: profileReducer,
		management: managementSlice,
		[userAPI.reducerPath]: userAPI.reducer,
		[bankAPI.reducerPath]: bankAPI.reducer,
		[managementAPI.reducerPath]: managementAPI.reducer,
		[activationKeysAPI.reducerPath]: activationKeysAPI.reducer,
		[spotAPI.reducerPath]: spotAPI.reducer,
		[futuresAPI.reducerPath]: futuresAPI.reducer,
		[baseAuthAPI.reducerPath]: baseAuthAPI.reducer,
		[partnersAPI.reducerPath]: partnersAPI.reducer,
		[forexKeysAPI.reducerPath]: forexKeysAPI.reducer,
		[indexAPI.reducerPath]: indexAPI.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(userAPI.middleware, bankAPI.middleware, futuresAPI.middleware)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
