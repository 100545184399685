import React, { useState } from 'react';
import styles from './createPartnerProgramForm.module.scss';
import { ICreatePartnerProgramForm, ICreatePartnerProgramFormData } from './createPartnerProgramForm.types';
import { Button, Headline, Input, Text } from 'forly-kit';
import { useForm } from 'react-hook-form';
import { colors } from 'forly-kit/dist/colors';
import { useTranslation } from 'react-i18next';

const Form: React.FC<ICreatePartnerProgramForm> = ({
	create
}) => {
	const {t} = useTranslation();
	const [errorState, setErrorState] = useState<any>({});

	const {
		register,
		formState: { errors },
		handleSubmit,
		getValues,
	} = useForm<ICreatePartnerProgramFormData>({
		mode: 'onChange',
	});

	const changeHandler = () => {
		const state: any = {};
		const value = getValues('subdomain');
		if (!(/^[a-z]+$/.test(value)) && value) {
			state.symbols = true;
		}
		if (!(/^[a-z]{6,}$/.test(value))) {
			state.length = true;
		}
		if (value.includes('api') || value.includes('forly') || value.includes('admin')) {
			state.private = true;
		}
		setErrorState(state);
	};

	return <>
		<form className={styles.form} onSubmit={handleSubmit(create)}>
			<div className={styles.inputBlock}>
				<Input
					label={t('inputName')}
					register={() =>
						register('subdomain', {
							required: 'required',
							pattern: /^[a-z]{6,}$/,
							onChange: changeHandler
						})
					}
					status={errors.subdomain ? 'error' : null}
				/>
			</div>
			<Text className={styles.domain} size='s'>{CONFIG.domain}</Text>
			<Button
				type='submit'
				variant='primary'
				text={t('create')}
				disabled={!getValues('subdomain') || !!errors.subdomain}
				onClick={create}
			/>
		</form>
		<div className={styles.rulesBlock}>
			<Headline type='h4'>
				{t('important')}:
			</Headline>
			<ul>
				<li><Text size='m' color={errorState.length ? colors.danger : colors.primary900}>
					{t('errors.minSymbolsAlphabet')};
				</Text></li>
				<li>
					<Text size='m' color={errorState.private ? colors.danger : colors.primary900}>
						{t('errors.noSubstrings')};
					</Text>
				</li>
				<li>
					<Text size='m' color={errorState.symbols ? colors.danger : colors.primary900}>
						{t('errors.noNumber')};
					</Text>
				</li>
			</ul>
		</div>
	</>;
};

export const CreatePartnerProgramForm = React.memo(Form);
