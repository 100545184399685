import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ICommissionParams, ISpotBot, ISpotKeyProps } from 'types/apiKeys.types';
import { spotAPI } from 'services/spotService';
import { Loader } from '../../../components/Loader/Loader';
import { Key } from '../../../components/Key/Key';
import { EKeyStatuses, IKeyStatus } from '../../../components/Key/key.types';
import { managementAPI } from 'services/managementService';
import {
	AutoCommissionConfirmModal
} from '../../../components/Modals/Commissions/AutoCommissionConfirmModal/AutoCommissionConfirmModal';
import { bankAPI } from 'services/bankService';
import { EBalanceSymbol } from 'types/common.types';
import { UpdateKeyModal } from '../../../components/Modals/KeysModals/UpdateKeyModal/UpdateKeyModal';
import { IUpdateFormData } from '../../../components/Forms/UpdateKeyForm/updateKeyForm.types';
import { activationKeysAPI } from 'services/activationKeysService';
import { SuccessModal } from '../../../components/Modals/SuccessModal/SuccessModal';
import { AutoExtensionActivationModal } from '../../../components/Modals/ActivationModals/AutoExtensionActivationModal';
import {
	ActivationExtensionModal
} from '../../../components/Modals/ActivationModals/ActivationExtensionModal/ActivationExtensionModal';
import { BuyActivationModal } from '../../../components/Modals/ActivationModals/BuyActivationModal';
import { BuyConfirmModal } from '../../../components/Modals/ActivationModals/BuyConfirmModal';
import { IBalance } from 'types/balance.types';
import { IBuyParams } from '../../../components/Modals/ActivationModals/activationModals.types';
import {
	IAutoPayModalParams
} from '../../../components/Modals/Commissions/AutoCommissionConfirmModal/autoCommissionConfirm.types';
import { ISuccessModalParams } from '../../../components/Modals/SuccessModal/successModal.types';
import { PayCommissionModal } from '../../../components/Modals/Commissions/PayCommissionModal/PayCommissionModal';
import { useTranslation } from 'react-i18next';
import { debug } from 'webpack';



const KeyComponent: React.FC<ISpotKeyProps> = ({
	id,
	name,
	isActive = false,
	freeActivations
}) => {
	const {t, i18n} = useTranslation();
	const botStatusText: Record<EKeyStatuses, string> = useMemo(() => {
		return {
			[EKeyStatuses.WORKING]: t('keys.bot.working'),
			[EKeyStatuses.STOPPED]: t('keys.bot.stopped'),
			[EKeyStatuses.WAITING]: t('keys.bot.waiting')
		};
	}, [i18n.language]);

	const botActionText: Record<EKeyStatuses, string> = useMemo(() => {
		return {
			[EKeyStatuses.WORKING]: t('keys.bot.actionStop'),
			[EKeyStatuses.STOPPED]: t('keys.bot.actionStart'),
			[EKeyStatuses.WAITING]: t('cancelAction')
		};
	}, [i18n.language]);

	const botActionDescription: Record<EKeyStatuses, string> = useMemo(() => {
		return {
			[EKeyStatuses.WORKING]: t('keys.bot.stopDesc'),
			[EKeyStatuses.STOPPED]: t('keys.bot.startDesc'),
			[EKeyStatuses.WAITING]: t('tradingWaitingDescription')
		};
	}, [i18n.language]);

	const [autoPay, setAutoPay] = useState<boolean>(false);
	const [bot, setBot] = useState<ISpotBot | null>(null);
	const [updateKeyModal, setUpdateKeyModal] = useState<boolean>(false);
	const [autoExtension, setAutoExtension] = useState<boolean>(false);
	const [isShowAutoExtensionModal, setIsShowAutoExtensionModal] = useState<boolean>(false);
	const [showExtensionModal, setShowExtensionModal] = useState<boolean>(false);
	const [buyActivationModal, setBuyActivationModal] = useState<boolean>(false);
	const [buyParams, setBuyParams] = useState<IBuyParams | null>(null);
	const [autoPayModalParams, setAutoPayModalParams] = useState<IAutoPayModalParams | null>(null);
	const [successModalParams, setSuccessModalParams] = useState<ISuccessModalParams | null>(null);
	const [payCommissionParams, setPayCommissionParams] = useState<ICommissionParams | null>(null);

	const {data: key, isFetching: keyLoading, refetch: refetchKey} = spotAPI.useFetchSpotKeyByIdQuery(id);
	const { data: lastFT, isLoading: lastFTLoading } =
		managementAPI.useFetchLastFTQuery(null);
	const [ startBot, {
		isLoading: startingBot,
		isSuccess: startBotSuccess,
		data: startBotResponse,
	}] = spotAPI.useStartBotMutation();
	const [stopBot, {
		isLoading: stopingBot,
		isSuccess: stopBotSuccess,
		data: stopBotResponse
	}] = spotAPI.useStopBotMutation();
	const [
		changeAutoCommission,
		{
			data: autoCommissionResponse,
			isSuccess: successAutoCommission,
		}
	] = spotAPI.useChangeAutoPayCommissionMutation();

	const [requestToReplicate, {isSuccess: requestReplicateSuccess}] = spotAPI.useRequestToReplicateMutation();

	const {data: balances} = bankAPI.useFetchBalanceQuery(null);
	const [updateKey, { isSuccess: successUpdate}] = spotAPI.useUpdateKeyMutation();
	const [
		updateautoExtension,
		{isSuccess: successAutoExtension, isLoading: autoExtensionLoading, data: autoExtensionResponse}
	] = activationKeysAPI.useAutoExtensionMutation();
	const {data: activations} = activationKeysAPI.useFetchActivationListQuery(null);
	const [extensionKey, {isSuccess: successBuyExtension}] = activationKeysAPI.useExtensionActivationMutation();
	const [
		extensionKeyByExist, 
		{isSuccess: successExtensionExist}
	] = activationKeysAPI.useExtensionExistingActivationMutation();
	const [
		payCommission,
		{isLoading: payCommissionProcess, isSuccess: payCommissionSuccess}
	] = spotAPI.usePayCommissionMutation();

	const expired = useMemo(() => {
		return key?.activation?.expiration_date || '';
	}, [key]);

	const spotBusdPrice = useMemo(() => {
		if (activations?.CRP) {
			return +activations.CRP.price;
		}
		return 0;
	}, [activations]);

	const spotFccPrice = useMemo(() => {
		if (spotBusdPrice && lastFT) {
			return spotBusdPrice / lastFT;
		}
		return 0;
	}, [spotBusdPrice, lastFT]);

	useEffect(() => {
		if (requestReplicateSuccess) {
			refetchKey();
		}
	}, [requestReplicateSuccess]);

	useEffect(() => {
		setBot(key?.token_bot[0] || null);
		setAutoPay(!!key?.auto_commissions);
		setAutoExtension(!!key?.activation?.auto_extension);
	}, [key]);

	useEffect(() => {
		if (autoCommissionResponse && autoCommissionResponse.auto_commissions !== autoPay) {
			setAutoPay(autoCommissionResponse.auto_commissions);
		}
	}, [autoCommissionResponse]);

	useEffect(() => {
		if (startBotSuccess) {
			if (startBotResponse) {
				setBot(startBotResponse);
			}
		}
	}, [startBotSuccess]);

	useEffect(() => {
		if (stopBotSuccess) {
			if (stopBotResponse) {
				setBot(stopBotResponse);
			}
		}
	}, [stopBotSuccess]);

	useEffect(() => {
		if (successUpdate) {
			refetchKey();
			closeUpdateKeyModal();
		}
	}, [successUpdate]);

	useEffect(() => {
		if (successAutoCommission && autoCommissionResponse?.auto_commissions) {
			setSuccessModalParams({
				title: t('keys.commissions.autoCommissionSuccessModalTitle')
			});
		}
		if (successAutoCommission && autoCommissionResponse && !autoCommissionResponse.auto_commissions) {
			setSuccessModalParams({
				title: t('keys.commissions.autoCommissionCancelTitle')
			});
		}
	}, [successAutoCommission]);

	useEffect(() => {
		if (successAutoExtension && autoExtensionResponse && autoExtensionResponse.auto_extension) {
			setSuccessModalParams({
				title: t('keys.activations.autoExtensionSuccessModalTitle')
			});
			setAutoExtension(true);
		}
		if (successAutoExtension && autoExtensionResponse && !autoExtensionResponse.auto_extension) {
			setSuccessModalParams({
				title: t('keys.activations.autoExtensionCancelTitle')
			});
			setAutoExtension(false);
		}
	}, [successAutoExtension, t]);

	useEffect(() => {
		if (payCommissionSuccess) {
			setSuccessModalParams({
				title: t('keys.commissions.paySuccess')
			});
			setPayCommissionParams(null);
		}
	}, [payCommissionSuccess]);

	useEffect(() => {
		if (successBuyExtension || successExtensionExist) {
			refetchKey();
		}
	}, [successBuyExtension, successExtensionExist]);

	const changeAutoExtensionHandler = (value: boolean) => {
		if (!value) {
			setAutoPayModalParams({
				title: t('keys.activations.autoExtensionCancelModalTitle'),
				// eslint-disable-next-line max-len
				subtitle: t('keys.activations.autoExtensionCancelModalSubtitle'),
				action: () => changeAutoExtension(value),
				actionText: t('keys.activations.cancelAutoExtension'),
				cancel: t('keys.activations.notCancelAutoExtension'),
			});
		} else {
			setAutoPayModalParams({
				title: t('keys.activations.autoExtensionConfirmModalTitle'),
				// eslint-disable-next-line max-len
				subtitle: t('keys.activations.autoExtensionConfirmModalSubtitle'),
				action: () => changeAutoExtension(value),
				actionText: t('keys.activations.confirmAutoExtension')
			});
		}
	};

	const changeAutoExtension = async (value: boolean) => {
		return await updateautoExtension({
			id: key?.activation.id,
			autoExtension: value
		});
	};

	const FCCBalance = useMemo(() => {
		return balances?.find((b) => b.token_symbol === EBalanceSymbol.FT);
	}, [balances]);

	const BUSDBalance = useMemo(() => {
		return balances?.find((b) => b.token_symbol === EBalanceSymbol.USDT);
	}, [balances]);

	const usdtCommission = useMemo(() => {
		if (key && key.commission?.length) {
			return key?.commission[0]?.value || 0;
		}
		return 0;
	}, [key]);

	const fccCommission = useMemo(() => {
		if (!lastFT || lastFT === 0) {
			return 0;
		}
		return usdtCommission / lastFT;
	}, [usdtCommission, lastFT]);

	const actionBotHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		if (!key) {
			return;
		}
		requestToReplicate({
			id: key.id,
			is_replicated: !key?.is_requested
		});
	};

	const botStatus: EKeyStatuses = useMemo(() => {
		if (key && key.is_requested) {
			return EKeyStatuses.WAITING;
		}

		if (key && (key.is_replicated)) {
			return EKeyStatuses.WORKING;
		}

		return EKeyStatuses.STOPPED;
	}, [key]);

	const keyStatus: IKeyStatus = useMemo(() => {
		return ({
			status: botStatus,
			statusText: botStatusText[botStatus],
			actionDescription: botActionDescription[botStatus],
			actionText: botActionText[botStatus],
			action: actionBotHandler
		});
	}, [botStatus, key, botActionText, botActionDescription, botStatusText]);

	const generateBuyParams = (balance: IBalance, price: number) => {
		closeBuyActivationModal();
		setBuyParams({
			price,
			balance
		});
	};

	const resetBuyParams = () => {
		setBuyParams(null);
	};

	const buyActivationRequest = (currency: EBalanceSymbol) => {
		if (!key) {
			return;
		}
		setBuyParams(null);
		extensionKey({
			id: key.activation.id,
			currency
		});
	};

	const payCommissionHandler = () => {
		setPayCommissionParams({
			keyId: id,
			commissionID: key?.commission[0]?.id || 0,
			balanceFCC: FCCBalance?.value_free || 0,
			balanceBUSD: BUSDBalance?.value_free || 0,
			commissionFCC: fccCommission,
			commissionBUSD: usdtCommission
		});
	};

	const buyExtensionActivationHandler = () => {
		closeExtensionModal();
		openBuyActivationModal();
	};

	const updateKeyHandler = async (data: IUpdateFormData) => {
		return await updateKey({
			...data,
			id,
			name
		});
	};

	const openAutoCommissionModal = () => {
		if (autoPay) {
			setAutoPayModalParams({
				title: t('keys.commissions.autoCommissionCancelModalTitle'),
				subtitle: t('keys.commissions.autoCommissionCancelModalSubtitle'),
				action: () => changeAutoPay(false),
				actionText: t('keys.commissions.cancelAutoCommission'),
				cancel: t('keys.commissions.notCancelAutoCommission')
			});
		} else {
			setAutoPayModalParams({
				title: t('keys.commissions.autoCommissionConfirmModalTitle'),
				subtitle: t('keys.commissions.autoCommissionConfirmModalSubtitle'),
				action: () => changeAutoPay(true),
				actionText: t('keys.commissions.confirmAutoCommission')
			});
		}
	};

	const extensionExistKey = () => {
		if (key?.activation) {
			return extensionKeyByExist(key.activation.id);
		}
		return null;
	};

	const openUpdateKeyModal = () => {
		setUpdateKeyModal(true);
	};

	const closeUpdateKeyModal = () => {
		setUpdateKeyModal(false);
	};

	const closeAutoExtensionModal = () => {
		setIsShowAutoExtensionModal(false);
	};

	const openExtensionModal = () => {
		setShowExtensionModal(true);
	};

	const closeExtensionModal = () => {
		setShowExtensionModal(false);
	};

	const openBuyActivationModal = () => {
		setBuyActivationModal(true);
	};

	const closeBuyActivationModal = () => {
		setBuyActivationModal(false);
	};

	const changeAutoPay = useCallback(async (value: boolean) => {
		return await changeAutoCommission({
			id,
			autoCommission: value
		});
	}, [id]) ;

	return 	<>
		<div className='card'>
			{
				(
					startingBot
					|| stopingBot
					|| lastFTLoading
					|| keyLoading
				) && <Loader internal/>
			}
			<Key
				id={id}
				needUpdate={key?.need_update || false}
				keyStatus={keyStatus}
				autoPayCommission={autoPay}
				name={name}
				expired={expired}
				changeAutoPayCommission={openAutoCommissionModal}
				publicKey={key?.binance_key || ''}
				lastCheckCommission={key?.fee_last_check || 0}
				payDate={key?.commission[0]?.payment_deadline}
				usdtCommission={usdtCommission}
				fccCommission={fccCommission}
				isActive={isActive}
				is_blocked={!!bot?.is_blocked}
				updateKey={openUpdateKeyModal}
				autoExtension={autoExtension}
				changeAutoExtension={changeAutoExtensionHandler}
				extendActivation={openExtensionModal}
				payCommission={payCommissionHandler}
			/>
		</div>

		{autoPayModalParams && <AutoCommissionConfirmModal
			{...autoPayModalParams}
			isOpen={!!autoPayModalParams}
			onClose={() => setAutoPayModalParams(null)}		/>
		}


		<UpdateKeyModal update={updateKeyHandler} isOpen={updateKeyModal} onClose={closeUpdateKeyModal} />

		{/*<AutoExtensionActivationModal*/}
		{/*	autoExtensionHandler={() => changeAutoExtension(true)}*/}
		{/*	isOpen={isShowAutoExtensionModal}*/}
		{/*	onClose={closeAutoExtensionModal}*/}
		{/*	isProcess={autoExtensionLoading}*/}
		{/*/>*/}

		<SuccessModal
			{...successModalParams}
			isOpen={!!successModalParams}
			onClose={() => {setSuccessModalParams(null);}}
		/>

		<ActivationExtensionModal
			freeActivations={freeActivations}
			useActivationHandler={extensionExistKey}
			buyActivationHandler={buyExtensionActivationHandler}
			isOpen={showExtensionModal}
			onClose={closeExtensionModal}
		/>

		<BuyActivationModal
			isOpen={buyActivationModal}
			onClose={closeBuyActivationModal}
			fccPrice={spotFccPrice}
			busdPrice={spotBusdPrice}
			fccBalance={FCCBalance}
			busdBalance={BUSDBalance}
			buyClickHandler={generateBuyParams}
		/>

		{buyParams && <BuyConfirmModal
			{...buyParams}
			isOpen={!!buyParams}
			onClose={resetBuyParams}
			payRequest={buyActivationRequest}
		/>
		}

		{payCommissionParams &&
			<PayCommissionModal
				{...payCommissionParams}
				isOpen={!!payCommissionParams}
				onClose={() => setPayCommissionParams(null)}
				payCommission={payCommission}
				isPending={payCommissionProcess}
				isSuccessPay={payCommissionSuccess}
			/>}
	</>;

};

export const SpotKey = React.memo(KeyComponent);

