import React, { useEffect, useMemo, useState } from 'react';
import styles from './keysPageWrapper.module.scss';
import { Sidebar } from '../Sidebar/Sidebar';
import { Button, ValueBox, Text, Headline } from 'forly-kit';
import { IKeysPageWrapper } from './KeysPageWrapper.types';
import { BuyActivationModal } from '../Modals/ActivationModals/BuyActivationModal';
import { BuyConfirmModal } from '../Modals/ActivationModals/BuyConfirmModal';
import { IBuyParams } from '../Modals/ActivationModals/activationModals.types';
import { bankAPI } from 'services/bankService';
import { EBalanceSymbol } from 'types/common.types';
import { IBalance } from 'types/balance.types';
import { SuccessModal } from '../Modals/SuccessModal/SuccessModal';
import { activationKeysAPI } from 'services/activationKeysService';
import { Loader } from '../Loader/Loader';
import { InstructionsLinks } from '../InstructionsLinks/InstructionsLinks';
import { AddKeyBlock } from '../AddKeyBlock/AddKeyBlock';
import { colors } from 'forly-kit/dist/colors';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { PackageActivationBlock } from '../PackageActivationBlock/PackageActivationBlock';
import { managementAPI } from 'services/managementService';

const Wrapper: React.FC<IKeysPageWrapper> = ({
	children,
	isForex,
	usedActivations,
	freeActivations,
	busdPrice,
	fccPrice,
	type,
	successPay,
	addKey,
	instruction
}) => {
	const {t} = useTranslation();
	const [isBuyModalOpen, setIsBuyModalOpen] = useState<boolean>(false);
	const [buyPackageModalOpen, setBuyPackageModalOpen] = useState<boolean>(false);
	const [buyParams, setBuyParams] = useState<IBuyParams | null>(null);
	const [successPayActivation, setSuccessPayActivation] = useState<boolean>(false);
	const [isAddKey, setIsAddKey] = useState<boolean>(false);

	const {data: activations, isLoading: activationsLoading} = activationKeysAPI.useFetchActivationListQuery(null);
	const { data: lastFT, isLoading: lastFTLoading } =
		managementAPI.useFetchLastFTQuery(null);
	const {data: balances, refetch: refetchBalance} = bankAPI.useFetchBalanceQuery(null);
	const [buyActivation, {isLoading: buyProcess, isSuccess: successBuy}] = activationKeysAPI.useBuyActivationMutation();

	const FCCBalance = useMemo(() => {
		return balances?.find((b) => b.token_symbol === EBalanceSymbol.FT);
	}, [balances]);

	const BUSDBalance = useMemo(() => {
		return balances?.find((b) => b.token_symbol === EBalanceSymbol.USDT);
	}, [balances]);

	useEffect(() => {
		if (successBuy) {
			setSuccessPayActivation(true);
			refetchBalance();
			if (successPay) {
				successPay();
			}
		}
	}, [successBuy]);

	const totalActivations = useMemo(() => {
		if (usedActivations === 0) {
			setIsAddKey(true);
		}

		if (freeActivations !== null && usedActivations !== null) {
			return freeActivations + usedActivations;
		}

		return 0;
	}, [freeActivations, usedActivations]);

	const resetBuyParams = () => {
		setBuyParams(null);
	};

	const busdPackageActivationPrice = useMemo(() => {
		if (activations?.ALL) {
			return +activations.ALL.price;
		}
	}, [activations]);

	const fccPackageActivationPrice = useMemo(() => {
		if (lastFT && busdPackageActivationPrice) {
			return busdPackageActivationPrice / lastFT;
		}
	}, [busdPackageActivationPrice, lastFT]);



	const generateBuyParams = (balance: IBalance, price: number) => {
		if (isBuyModalOpen) {
			closeBuyModal();
		}

		if (buyPackageModalOpen) {
			closeBuyPackage();
		}
		
		setBuyParams({
			price,
			balance
		});
	};

	const buyActivationRequest = (currency: EBalanceSymbol) => {
		setBuyParams(null);
		buyActivation({
			activation_id: (buyPackageModalOpen && activations?.ALL.id) ? activations?.ALL.id : type,
			currency
		});
	};

	const startAddKey = () => {
		setIsAddKey(true);
	};

	const cancelAddKey = () => {
		setIsAddKey(false);
	};

	const openBuyNodal = () => {
		setIsBuyModalOpen(true);
	};

	const closeBuyModal = () => {
		setIsBuyModalOpen(false);
	};

	const closeSuccesPayModal = () => {
		setSuccessPayActivation(false);
	};

	const actionPackage = () => {
		setBuyPackageModalOpen(true);
	};

	const closeBuyPackage = () => {
		setBuyPackageModalOpen(false);
	};

	return <>
		{buyProcess && <Loader />}
		<div className='contentWrapper'>
			{isForex
				? <div className='content'>
					{children}
				</div>
				: <div className='content'>
					{totalActivations
						? <>
							<Headline className={styles.title} type='h1'>
								{t('keys.title')}
								<Button className={cn({
									[styles.addKeyBtn]: isAddKey,
								})} text={t('add')} variant='tertiary' onClick={() => setIsAddKey(true)} disabled={isAddKey}/>
							</Headline>
							<div className={cn(styles.addKey, {
								[styles.opened]: isAddKey
							})}>
								<AddKeyBlock
									cancelAddKey={usedActivations ? cancelAddKey : null}
									addKey={addKey}
									instructionModal={instruction}
								/>
							</div>
							{children}
						</>
						: <>
							<div className='card cardContent'>
								<div className={styles.noActivationsWrapper}>
									<Headline type='h2' color={colors.primary800}>
										{t('keys.activations.noActivations')}
									</Headline>
									<Text size='m'>
										{t('keys.activations.noTrading')}
									</Text>
									<Text size='m'>
										{t('keys.activations.noTradingStep1')}
									</Text>
									<Text size='m'>
										{t('keys.activations.noTradingStep2')}
									</Text>
									<Button
										className={styles.btn}
										text={t('keys.activations.buyActivation')}
										variant='primary'
										fill
										onClick={openBuyNodal}
									/>
								</div>
							</div>
						</>
					}
				</div>
			}
			<Sidebar>
				<div className={styles.keysSidebar}>
					{fccPackageActivationPrice && busdPackageActivationPrice && <PackageActivationBlock 
						fccPrice={fccPackageActivationPrice}
						busdPrice={busdPackageActivationPrice}
						action={actionPackage}
					/>}
					<div className={styles.buyBlock}>
						<ValueBox title={t('keys.activations.freeActivations')} value={freeActivations || 0} />
						<Button className={styles.buyButton} text={t('buy')} variant='tertiary' onClick={openBuyNodal} />
						<ValueBox
							className={styles.usedCount}
							title={t('keys.activations.usedActivations')}
							value={usedActivations || 0}
						/>
					</div>
					<div className={styles.priceBlock}>
						<ValueBox title={t('cost')}>
							<Text size='m'>
								1 {t('keys.activations.activation')} = {fccPrice} FСС
							</Text>
						</ValueBox>
						<ValueBox>
							<Text size='m'>
								1 {t('keys.activations.activation')} = {busdPrice} BUSD
							</Text>
						</ValueBox>
					</div>
					<div>
						<Headline type='h4'>
							{t('keys.activations.apiKey')}
						</Headline>
						<Text size='m'>
							{t('keys.activations.apiKeyDesc')}
						</Text>
						<Text size='m'>
							{t('keys.activations.deleteKeyDesc')}
						</Text>
					</div>
					<InstructionsLinks
						video={instruction?.video || ''}
						file={instruction?.file || ''}
						Instruction={instruction?.Instruction}
					/>
				</div>


			</Sidebar>
		</div>

		<BuyActivationModal
			isOpen={isBuyModalOpen || buyPackageModalOpen}
			onClose={isBuyModalOpen ? closeBuyModal : closeBuyPackage}
			fccPrice={isBuyModalOpen ? fccPrice : (fccPackageActivationPrice || 0)}
			busdPrice={isBuyModalOpen ? busdPrice : (busdPackageActivationPrice || 0)}
			fccBalance={FCCBalance}
			busdBalance={BUSDBalance}
			buyClickHandler={generateBuyParams}
		/>
		{buyParams && <BuyConfirmModal
			{...buyParams}
			isOpen={!!buyParams}
			onClose={resetBuyParams}
			payRequest={buyActivationRequest}
		/>
		}

		<SuccessModal
			title={t('keys.activations.successBuyActivationTitle')}
			description={t('keys.activations.successBuyActivationSubtitle')}
			isOpen={successPayActivation}
			onClose={closeSuccesPayModal}
		/>
	</>;

};

export const KeysPageWrapper = React.memo(Wrapper);
