// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form--_SPky{display:grid;grid-template-columns:316px auto;column-gap:8px;row-gap:8px;align-items:end;justify-content:start}.domain--ktotO{margin-bottom:11px}.inputBlock--DMXLg>div>div{margin-bottom:0}.rulesBlock--WeeR8{margin-top:16px}.rulesBlock--WeeR8 ul{margin-left:24px}\n", "",{"version":3,"sources":["webpack://./../src/components/Forms/CreatePartnerProgramForm/createPartnerProgramForm.module.scss"],"names":[],"mappings":"AAIA,aACE,YAAa,CACb,gCAAiC,CACjC,cAAe,CACf,WAAY,CACZ,eAAgB,CAChB,qBAAsB,CACvB,eAGC,kBAAmB,CACpB,2BAGC,eAAgB,CACjB,mBAqBC,eAAgB,CADlB,sBAII,gBAAiB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.form {\n  display: grid;\n  grid-template-columns: 316px auto;\n  column-gap: 8px;\n  row-gap: 8px;\n  align-items: end;\n  justify-content: start;\n}\n\n.domain {\n  margin-bottom: 11px;\n}\n\n.inputBlock > div > div {\n  margin-bottom: 0;\n}\n\n//.form > div {\n//  width: 100%;\n//  div {\n//    width: 100%;\n//   row-gap: 0;\n//    margin-bottom: 0;\n//\n//    label {\n//      display: none;\n//    }\n//\n//    input {\n//      padding-left: 10px;\n//      height: 44px;\n//    }\n//  }\n//}\n\n.rulesBlock {\n  margin-top: 16px;\n\n  ul {\n    margin-left: 24px;\n  }\n}\n\n.rules {\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "form--_SPky",
	"domain": "domain--ktotO",
	"inputBlock": "inputBlock--DMXLg",
	"rulesBlock": "rulesBlock--WeeR8"
};
export default ___CSS_LOADER_EXPORT___;
