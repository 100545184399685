import React from 'react';
import styles from './balanceCardSidebar.module.scss';
import classNames from 'classnames';
import { IBalanceCard } from './balanceCardSidebar.types';
import {Headline} from 'forly-kit';
import { formatNumber } from '../../helpers/formatNumber';
import { tokensLabel } from '../../helpers/common';
import { useTranslation } from 'react-i18next';

export const BalanceCardSidebar: React.FC<IBalanceCard> = ({
	symbol,
	value,
	className
}) => {
	const {t} = useTranslation();
	return <div className={classNames(className, styles.balanceCard)}>
		<Headline type='h4'>
			{t('balanceToken', {token: tokensLabel[symbol]})}
		</Headline>
		<Headline type='h2'>
			{formatNumber(value, 4)}
		</Headline>
	</div>;
};
