import React, { useState, useEffect} from 'react';
import styles from './finance.module.scss';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Headline } from 'forly-kit';
import { bankAPI } from 'services/bankService';
import { EBalanceSymbol } from 'types/common.types';
import { Loader } from '../../components/Loader/Loader';
import { UserHoldCard } from '../../components/UserHoldCard/UserHoldCard';
import { colors } from 'forly-kit/dist/colors';
import { BalanceCardContent } from '../../components/BalanceCardContent/BalanceCardContent';
import { CreateHoldModal } from '../../components/Modals/CreateHoldModal/CreateHoldModal';
import { ICreateHoldFormData } from '../../components/Forms/CreateHoldForm/createHoldForm.types';
import { ConfirmCreateHoldModal } from '../../components/Modals/ConfirmCreateHoldModal/ConfirmCreateHoldModal';
import { TransactionCard } from '../../components/TransactionCard/TransactionCard';
import { userAPI } from 'services/userService';
import { format } from 'date-fns';
import { ITransactionFinance } from 'types/bank.types';
import { formatNumber } from '../../helpers/formatNumber';
import { useTranslation } from 'react-i18next';
import { ConfirmCodeModal } from '../../components/Modals/ConfirmCode/ConfirmCode';

type TransactionDirection = 'ALL' | 'INPUT' | 'OUTPUT';

export const Finance: React.FC = () => {
	const {t} = useTranslation();
	const { data: balances, isLoading: balanceLoading } =
		bankAPI.useFetchBalanceQuery(null);
	const {data: holdList, isLoading: holdsLoading} = bankAPI.useFetchHoldsQuery(null);
	const {data: transactions, isLoading: transactionsPending} = bankAPI.useGetTransactionAllQuery(null);
	const {data: profile, isLoading: profilePending} = userAPI.useFetchProfileQuery('');
	const [
		setHold, 
		{isLoading: setHoldPending, isSuccess: successSetHold, error: errorSetHold}
	] = bankAPI.useSetHoldMutation();
	
	const [openCreateHoldModal, setOpenCreateHoldModal] = useState<boolean>(false);
	const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
	const [holdData, setHoldData] = useState<ICreateHoldFormData | null>(null);
	const [transactionDirection, setTransactionDirection] = useState<TransactionDirection>('ALL');
	const [codeType, setCodeType] = useState<string>('');

	useEffect(() => {
		if (successSetHold) {
			closeConfirmModal();
		}
	}, [successSetHold]);

	
	const fccBalance = React.useMemo(() => {
		if (!balances?.length) {
			return null;
		}
		
		return balances.find((b) => b.token_symbol === EBalanceSymbol.FT);
	}, balances);
	
	const toggleCreateHoldModal = (isOpen: boolean) => {
		setOpenCreateHoldModal(isOpen);
	};
	
	const createHoldDataHandler = (data: ICreateHoldFormData) => {
		setHoldData(data);
		toggleCreateHoldModal(false);
		setOpenConfirmModal(true);
	};

	const getTransactionValue = (userFrom: string, value: number): number => {
		if (userFrom === profile?.uuid) {
			return value * -1;
		}
		return value;
	};

	const getOperationType = (transaction: ITransactionFinance): string => {
		if (transaction.basis === 'ACTIVATION') {
			return 'transactions.activations';
		}

		if (transaction.basis === 'CONVERSION') {
			if (transaction.comment === 'second part conversion') {
				return 'transactions.conversionInput';
			}

			if (transaction.comment === 'first part conversion') {
				return 'transactions.conversionOutput';
			}
		}

		if (transaction.basis === 'WITHDRAW') {
			return 'transactions.withdraw';
		}

		if (transaction.basis === 'COMMISSION') {
			if (transaction.comment === 'hold create commission') {
				return 'transactions.commissionHold';
			}
			return 'transactions.commission';
		}

		if (transaction.basis === 'HOLD') {
			return 'transactions.hold';
		}

		if (transaction.basis === 'FEE') {
			const value = getTransactionValue(transaction.user_from_uuid, transaction.token_count);
			if (value > 0) {
				return 'transactions.partner';
			} else {
				return 'transactions.management';
			}
		}

		console.log(transaction);
		return '';
	};

	const setHoldHandler = (code: string) => {
		setHold({
			SMS_CODE: code,
			period: holdData?.period || 3,
			count: (holdData?.value ? +holdData.value : 0),
		});
	};

	const backToCreate = () => {
		setCodeType('');
		setOpenCreateHoldModal(true);
	};

	const confirmHoldHandler = (type: string) => {
		setCodeType(type);
		setOpenConfirmModal(false);
	};

	const closeConfirmModal = () => {
		setCodeType('');
	};
	
	if (!balances
		|| !balances.length
		|| holdsLoading
		|| balanceLoading
		|| transactionsPending
		|| profilePending) {
		return <Loader />;
	}

	return <>
		<div className='contentWrapper'>
			<div className='content'>
				{fccBalance && <BalanceCardContent
					symbol={fccBalance.token_symbol}
					value={formatNumber(fccBalance.value_free, 2)}
					title="Forly Crypto Coin"
					valueInUsdt={formatNumber(fccBalance.value_free_USDT, 2)}
					action={(holdList && holdList.length < 10) ? () => toggleCreateHoldModal(true) : null}
				>
					<div className={styles.holdList}>
						{holdList?.length ? 	
							<Headline className={styles.holdListTitle} type='h3' color={colors.primary800}>
								{t('frozenTokens')}
							</Headline>
							: null
						}
					
						{holdList?.map((h) => {
							return <UserHoldCard
								key={h.id}
								value={h.value}
								created={h.created}
								period={h.period}
								blockchain_id={h.transaction_income.external ? h.transaction_income.external.blockchain_id : ''}
								symbol={h.transaction_income.token.symbol}
								commission={h.commission}
							/>;
						})}
					</div>
				</BalanceCardContent>
				}
				
				{balances.map((b) => {
					if (b.token_symbol !== EBalanceSymbol.FT) {
						return <BalanceCardContent
							symbol={b.token_symbol}
							value={b.value_free}
							title={b.token_symbol === EBalanceSymbol.USDT ? 'BUSD' : b.token_symbol}
						/>;
					}
				})}
			</div>
			
			<Sidebar>
				<div className={styles.financeSidebar}>
					<Headline type='h2' color={colors.primary800}>
						{t('transactions.title')}
					</Headline>
					<div className={styles.tabs}>
						<div onClick={() => setTransactionDirection('ALL')}>
							<Headline type='h3' color={transactionDirection === 'ALL' ? colors.primary700 : colors.neutral300}>
								{t('transactions.all')}
							</Headline>
						</div>
						<div onClick={() => setTransactionDirection('INPUT')}>
							<Headline type='h3' color={transactionDirection === 'INPUT' ? colors.primary700 : colors.neutral300}>
								{t('transactions.input')}
							</Headline>
						</div>
						<div onClick={() => setTransactionDirection('OUTPUT')}>
							<Headline type='h3' color={transactionDirection === 'OUTPUT' ? colors.primary700 : colors.neutral300}>
								{t('transactions.output')}
							</Headline>
						</div>
					</div>
					<div className={styles.transactions}>
						{transactions?.map((transaction) => {
							if (transaction.status !== 'COMPLETE') {
								return;
							}
							const value = getTransactionValue(transaction.user_from_uuid, transaction.token_count);
							if (transactionDirection === 'INPUT' && value <= 0) {
								return;
							}

							if (transactionDirection === 'OUTPUT' && value >0) {
								return;
							}

							return <TransactionCard
								key={transaction.id}
								value={value}
								token={transaction.token_symbol}
								user={transaction.user_from_uuid.slice(0,8)}
								time={format(new Date(transaction.transaction_time*1000), 'dd.MM.yyyy HH:mm:ss')}
								type={t(getOperationType(transaction))}
								id={transaction.id}
							/>;
						})}
					</div>
				</div>
			</Sidebar>
		</div>
		<CreateHoldModal
			isOpen={openCreateHoldModal}
			onClose={() => toggleCreateHoldModal(false)}
			successAction={createHoldDataHandler}
		/>
		<ConfirmCreateHoldModal
			confirm={confirmHoldHandler}
			data={holdData}
			isOpen={openConfirmModal}
			onClose={() => setOpenConfirmModal(false)}
		/>
		<ConfirmCodeModal
			title={''}
			type={codeType}
			confirm={setHoldHandler}
			cancel={backToCreate}
			isOpen={!!codeType}
			onClose={closeConfirmModal}
			isPending={setHoldPending}
			// @ts-ignore
			error={errorSetHold?.data?.detail || ''}
		/>
	</>;
	
};
