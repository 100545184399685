// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".instructionBlock--hjOQa .blockTitle--ycN9M{margin-bottom:8px}.instructionLink--FuCc5{display:grid;grid-template-columns:16px 1fr;align-items:center;justify-content:start;column-gap:8px;cursor:pointer}.instructionLink--FuCc5:not(:last-child){margin-bottom:8px}\n", "",{"version":3,"sources":["webpack://./../src/components/InstructionsLinks/instructionsLinks.module.scss"],"names":[],"mappings":"AAIA,4CAEI,iBAAkB,CACnB,wBAID,YAAa,CACb,8BAA+B,CAC/B,kBAAmB,CACnB,qBAAsB,CACtB,cAAe,CACf,cAAe,CANjB,yCASI,iBAAkB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.instructionBlock {\n  .blockTitle {\n    margin-bottom: 8px;\n  }\n}\n\n.instructionLink {\n  display: grid;\n  grid-template-columns: 16px 1fr;\n  align-items: center;\n  justify-content: start;\n  column-gap: 8px;\n  cursor: pointer;\n\n  &:not(:last-child) {\n    margin-bottom: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"instructionBlock": "instructionBlock--hjOQa",
	"blockTitle": "blockTitle--ycN9M",
	"instructionLink": "instructionLink--FuCc5"
};
export default ___CSS_LOADER_EXPORT___;
