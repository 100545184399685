import React, { useEffect, useState } from 'react';
import styles from './SpotOrder.module.scss';
import { ISpotOrderProps } from './SpotOrder.types';
import { Button, Text } from 'forly-kit';
import { format } from 'date-fns';
import { colors } from 'forly-kit/dist/colors';
import { spotAPI } from 'services/spotService';

const status: Record<string, string> = {
	NEW: 'Открыт',
	FILLED: 'Исполнен',
};
const type: Record<string, string> = {
	LIMIT: 'Лимитный',
	COC: 'COC',
	LIMIT_MAKER: 'Take profit',
	STOP_LOSS_LIMIT: 'Stop loss'
};
const side: Record<string, string> = {
	BUY: 'Покупка',
	SELL: 'Продажа',
};

const Component: React.FC<ISpotOrderProps> = ({
	order,
	cancelOrder
}) => {
	const {refetch: refetchOrders} = spotAPI.useGetSpotOrdersQuery(null);

	const [isLoading, setIsLoading] = useState<boolean>(true);
	// const [deleteOrder,
	// 	{isSuccess: deleteOrderSuccess}
	// ] = spotAPI.useDeleteSpotOrderMutation();

	// useEffect(() => {
	// 	if (deleteOrderSuccess) {
	// 		refetchOrders();
	// 	}
	// }, [deleteOrderSuccess]);

	const deleteOrderHandler = () => {
		cancelOrder(order.connected_to || 0, order.type);
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 1500);
	};
    
	return <div className={styles.mobileOrder}>
		<Text size='s' color={colors.neutral600}>Валютная&nbsp;пара</Text>   
		<Text size='s'>{order.symbol_name}</Text>
		<Text size='s' color={colors.neutral600}>Статус</Text>
		<Text size='s'>{status[order.status]}</Text>
		<Text size='s' color={colors.neutral600}>Тип ордeра</Text>
		<Text size='s'>{type[order.type]}</Text>
		<Text size='s' color={colors.neutral600}>Направлeниe</Text>
		<Text size='s'>{side[order.side]}</Text>
		<Text size='s' color={colors.neutral600}>Объем</Text>
		<Text size='s'>{order.orig_qty}</Text>
		<Text size='s' color={colors.neutral600}>Цена</Text>
		<Text size='s'>{order.price}</Text>
		<Text size='s' color={colors.neutral600}>Дата</Text>
		<Text size='s'>{format(new Date(order.transact_time), 'dd.MM.yyyy HH:mm')}</Text>
		<Button 
			className={styles.closeBtn} 
			text='Отмена ордера' 
			variant='tertiary'
			onClick={deleteOrderHandler}
		/>
	</div>;
};

export const MobileSpotOrder = React.memo(Component);
