import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { RootState } from 'store/store';
import { IUser } from 'types/profile.types';

export const baseAuthAPI = createApi({
	reducerPath: 'baseAuthAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: `https://${CONFIG.api}/base_auth/`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).auth.token;
			if (token) {
				headers.set('authorization', `Bearer ${token}`);
			}
			return headers;
		},
	}),
	endpoints: (build) => ({
		fetchUser: build.query<IUser, null>({
			query: () => ({
				url: '/user/'
			})
		})
	})
});
