import { IManagementState } from 'stateTypes/managementState';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: IManagementState = {
	lastFCC: null
};

export const managementSlice = createSlice({
	name: 'management',
	initialState,
	reducers: {
		setLastFCC: (state, action: PayloadAction<number>) => {
			state.lastFCC = action.payload;
		}
	}
});

export const {setLastFCC} = managementSlice.actions;
export default managementSlice.reducer;
