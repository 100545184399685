// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".confirmExchangeWrapper--hfGbG{display:grid;justify-content:center;justify-items:center;grid-template-columns:1fr}.infoBlock--Xnmms{display:grid;grid-template-columns:auto;column-gap:24px;margin-top:24px;margin-bottom:24px}\n", "",{"version":3,"sources":["webpack://./../src/components/Modals/ConfirmExchange/modalConfirmExchange.module.scss"],"names":[],"mappings":"AAIA,+BACE,YAAa,CACb,sBAAuB,CACvB,oBAAqB,CACrB,yBAA0B,CAC3B,kBAGC,YAAa,CACb,0BAA2B,CAC3B,eAAgB,CAChB,eAAgB,CAChB,kBAAmB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.confirmExchangeWrapper {\n  display: grid;\n  justify-content: center;\n  justify-items: center;\n  grid-template-columns: 1fr;\n}\n\n.infoBlock {\n  display: grid;\n  grid-template-columns: auto;\n  column-gap: 24px;\n  margin-top: 24px;\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmExchangeWrapper": "confirmExchangeWrapper--hfGbG",
	"infoBlock": "infoBlock--Xnmms"
};
export default ___CSS_LOADER_EXPORT___;
