import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { KeysPageWrapper } from '../../components/KeysPageWrapper/KeysPageWrapper';
import { activationKeysAPI } from 'services/activationKeysService';
import { Loader } from '../../components/Loader/Loader';
import { spotAPI } from 'services/spotService';
import { SpotKey } from './SpotKey/SpotKey';
import { managementAPI } from 'services/managementService';
import { PayCommissionModal } from '../../components/Modals/Commissions/PayCommissionModal/PayCommissionModal';
import { ICommissionParams } from 'types/apiKeys.types';
import { SpotInstructionModal } from '../../components/Modals/SpotInstructionModal/SpotInstructionModal';

const SpotKeyPage:React.FC = () => {

	const [payCommissionParams, setPayCommissionParams] = useState<ICommissionParams | null>(null);

	const {data: activations, isLoading: activationsLoading} = activationKeysAPI.useFetchActivationListQuery(null);
	const { data: lastFT, isLoading: lastFTLoading } =
		managementAPI.useFetchLastFTQuery(null);
	const {
		data: activationCount,
		isLoading: activationCountLoading,
		refetch: refetchCountActivations
	} = activationKeysAPI.useFetchActivationCountQuery(null);
	const {data: spotKeys = [], isLoading: spotKeysLoading, refetch: refetchKeys} = spotAPI.useFetchSpotKeysQuery(null);
	const [addKey, {isSuccess: successAddKey}] = spotAPI.useAddKeyMutation();
	const [
		payCommission,
		{isLoading: payCommissionProcess, isSuccess: payCommissionSuccess}
	] = spotAPI.usePayCommissionMutation();

	const spotActivations = useMemo(() => {
		return activationCount?.CRP || null;
	}, [activationCount]);

	const spotBusdPrice = useMemo(() => {
		if (activations?.CRP) {
			return +activations.CRP.price;
		}
		return 0;
	}, [activations]);

	const spotFccPrice = useMemo(() => {
		if (spotBusdPrice && lastFT) {
			return spotBusdPrice / lastFT;
		}
		return 0;
	}, [spotBusdPrice, lastFT]);

	useEffect(() => {
		if (successAddKey) {
			refetchKeys();
		}
	}, [successAddKey]);

	const resetPayCommissionParams = useCallback(() => {
		setPayCommissionParams(null);
	}, []);

	const openPayCommissionModal = (params: ICommissionParams) => {
		setPayCommissionParams(params);
	};

	if (
		activationsLoading
		|| spotKeysLoading
		|| activationCountLoading
		|| lastFTLoading
	) {
		return <Loader />;
	}

	return <KeysPageWrapper
		type={activations?.CRP.id || 0}
		freeActivations={spotActivations?.active || 0}
		usedActivations={spotActivations?.used || 0}
		busdPrice={spotBusdPrice}
		fccPrice={spotFccPrice}
		successPay={refetchCountActivations}
		addKey={addKey}
		instruction={{
			Instruction: SpotInstructionModal
		}}
	>
		<>
			{spotKeys.map((s) => {
				return <>
					<SpotKey
						key={s.id}
						id={s.id}
						name={s.name}
						isActive={spotKeys.length === 1}
						payCommissionHandler={openPayCommissionModal}
						freeActivations={spotActivations?.active || 0}
					/>
				</> ;
			})}

			{payCommissionParams &&
				<PayCommissionModal
					{...payCommissionParams}
					isOpen={!!payCommissionParams}
					onClose={resetPayCommissionParams}
					payCommission={payCommission}
					isPending={payCommissionProcess}
					isSuccessPay={payCommissionSuccess}
				/>}

		</>
	</KeysPageWrapper>;
};

export const Spot = React.memo(SpotKeyPage);
