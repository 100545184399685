// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".formWrapper--XkLoG{max-width:500px;margin:0 auto}.formWrapper--XkLoG .footer--NMkJM{grid-template-columns:1fr;row-gap:16px}.successWrapper--D0NnH{display:grid;grid-template-columns:1fr;row-gap:24px;justify-content:center;justify-items:center}.successTitle--RgUMi{display:grid;grid-template-columns:24px 1fr;column-gap:16px;align-items:center}.formTitle--WEbSH{margin-bottom:16px}.listItem--E4rPs{display:grid;grid-template-columns:auto 1fr;column-gap:4px;align-items:baseline;margin-bottom:24px}.list--qk35S{margin-bottom:24px}\n", "",{"version":3,"sources":["webpack://./../src/components/Forms/AddForexKeyForm/addForexKey.module.scss"],"names":[],"mappings":"AAIA,oBACE,eAAgB,CAChB,aAAc,CAFhB,mCAKI,yBAA0B,CAC1B,YAAa,CACd,uBAID,YAAa,CACb,yBAA0B,CAC1B,YAAa,CACb,sBAAuB,CACvB,oBAAqB,CACtB,qBAGC,YAAa,CACb,8BAA+B,CAC/B,eAAgB,CAChB,kBAAmB,CACpB,kBAGC,kBAAmB,CACpB,iBAGC,YAAa,CACb,8BAA+B,CAC/B,cAAe,CACf,oBAAqB,CACrB,kBAAmB,CACpB,aAGC,kBAAmB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.formWrapper {\n  max-width: 500px;\n  margin: 0 auto;\n\n  .footer {\n    grid-template-columns: 1fr;\n    row-gap: 16px;\n  }\n}\n\n.successWrapper {\n  display: grid;\n  grid-template-columns: 1fr;\n  row-gap: 24px;\n  justify-content: center;\n  justify-items: center;\n}\n\n.successTitle {\n  display: grid;\n  grid-template-columns: 24px 1fr;\n  column-gap: 16px;\n  align-items: center;\n}\n\n.formTitle {\n  margin-bottom: 16px;\n}\n\n.listItem {\n  display: grid;\n  grid-template-columns: auto 1fr;\n  column-gap: 4px;\n  align-items: baseline;\n  margin-bottom: 24px;\n}\n\n.list {\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formWrapper": "formWrapper--XkLoG",
	"footer": "footer--NMkJM",
	"successWrapper": "successWrapper--D0NnH",
	"successTitle": "successTitle--RgUMi",
	"formTitle": "formTitle--WEbSH",
	"listItem": "listItem--E4rPs",
	"list": "list--qk35S"
};
export default ___CSS_LOADER_EXPORT___;
