import React from 'react';
import { IModalProps } from '../modal.types';
import { SpotInstruction } from '../../Instructions/SpotInstruction/SpotInstruction';
import { Modal } from '../Modal';
import { Button } from 'forly-kit';
import { useTranslation } from 'react-i18next';

export const SpotInstructionModal: React.FC<IModalProps> = ({
	isOpen,
	onClose
}) => {
	const {t} = useTranslation();

	if (!isOpen) {
		return null;
	}
	return <Modal onClose={onClose}>
		<SpotInstruction />
		<Button variant='primary' text={t('close')} onClick={onClose} fill />
	</Modal>;
};
