import React, { useEffect, useState } from 'react';
import styles from './TradingSpot.module.scss';
import cn from 'classnames';
import { DropdownCard } from 'src/components/DropdownCard/DropdownCard';
import { CreateSpotOrderForm } from 'src/components/Forms/CreateSpotOrderForm/CreateSpotOrderForm';
import { SpotOrderList } from './SpotOrderList/SpotOrderList';
import { spotAPI } from 'services/spotService';
import { Button, Headline } from 'forly-kit';
import { Loader } from 'src/components/Loader/Loader';
import useWebSocket from 'react-use-websocket';
import { ETradingEvent } from 'types/trading.types';
import { formatNumber } from '../../helpers/formatNumber';

export const TradingSpot: React.FC = () => {

	const {data: replicate, isFetching: replicatePending, refetch: refetchTokens} = spotAPI.useReplicateQuery(null);
	const {refetch: refetchOrders} = spotAPI.useGetSpotOrdersQuery(null);
	const [approveReplicate, 
		{isLoading: approvePending, isSuccess: approveSuccess}
	] = spotAPI.useReplicateTokenMutation();

	const [symbolRender, setSymbolRender] = useState<symbol>(Symbol());

	const { sendJsonMessage, lastJsonMessage, getWebSocket } = useWebSocket(
		`${CONFIG.socketUrl}/ws/spot/`
	);

	useEffect(() => {
		return () => {
			const ws = getWebSocket();
			ws?.close();
		};
	}, [] );

	useEffect(() => {
		refetchTokens();
		refetchOrders();
	}, [lastJsonMessage] );

	useEffect(() => {
		if (approveSuccess) {
			refetchTokens();
		}
	}, [approveSuccess]);

	const approveHandler = () => {
		if (!replicate || !replicate.length) {
			return;
		}

		replicate.forEach((token: any) => {
			approveReplicate({
				id: token.id,
				is_replicated: !token.is_replicated
			});
		});
	};

	const createOrder = (data: any) => {
		sendJsonMessage({
			message_name: ETradingEvent.CREATE_ORDER,
			...data,
			connected_to: formatNumber((new Date()).getTime()/1000, 0)
		});
	};

	const createOCOOrder = (data: any) => {
		sendJsonMessage({
			message_name: ETradingEvent.CREATE_OCO_ORDER,
			...data,
			connected_to: formatNumber((new Date()).getTime()/1000, 0)
		});
	};

	const cancelOrders = () => {
		sendJsonMessage({
			message_name: ETradingEvent.CANCEL_ORDERS,
		});
	};

	const sellAll = () => {
		sendJsonMessage({
			message_name: ETradingEvent.SELL_ALL,
		});
	};

	const cancellOrder = (connected_to: number, type: string) => {
		sendJsonMessage({
			message_name: ETradingEvent.CANCEL_ORDER,
			connected_to,
			type
		});
	};

	const rerenderDropdown = () => {
		setSymbolRender(Symbol());
	};
    
	return <div className='contentWrapper'>
		<div className='content'>
			<div className={styles.wrapper}>
				<DropdownCard
					title='Выставление ордера'
					rerenderKey={symbolRender}
				>
					<CreateSpotOrderForm
						rerenderParent={rerenderDropdown}
						createOrder={createOrder}
						createOcoOrder={createOCOOrder}
					/>
				</DropdownCard>

				<SpotOrderList
					cancelOrders={cancelOrders}
					sellAll={sellAll}
					cancelOrder={cancellOrder}
				/>

				<div>
					{replicatePending && <Loader internal/>}
					<Headline type='h2'>
						Управление
					</Headline>
					<div className={cn('card cardContent', styles.managementKeys)}>
						<Button 
							variant='primary' 
							text='Принять заявки в пул' 
							onClick={approveHandler}
							disabled={!replicate || !replicate.length || approvePending}
						/>
						<Headline type='h4' color='#7D829A' className={styles.counter}>
							Новые заявки <Headline type='h4'>{replicate ? replicate.length : 0}</Headline>
						</Headline>
					</div>
				</div>
			</div>
		</div>
	</div>;
};
