import React from 'react';
import styles from './balanceList.module.scss';
import classNames from 'classnames';
import { IBalanceList } from './balanceList.types';
import { BalanceCardSidebar } from '../BalanceCardSidebar/BalanceCardSidebar';

export const BalanceList: React.FC<IBalanceList> = ({
	className,
	balances = []
}) => {
	return <ul className={classNames(className, styles.balanceList)}>
		{balances.map((b, _idx) => {
			return <>
				<BalanceCardSidebar symbol={b.token_symbol} value={b.value_free} />
				{_idx < balances.length-1 && <span className={styles.divider}></span>}
			</>;
		})}
	</ul>;
};
