// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form--LT5qo{width:auto;margin:0 auto}.counterBlock--yVhDR{display:grid;grid-template-columns:auto 1fr;column-gap:22px;margin-bottom:24px}.balance--N8U5U,.counter--TKbXh{display:grid;grid-template-columns:1fr;row-gap:8px}@media (max-width: 767.9px){.counterBlock--yVhDR{grid-template-columns:1fr;row-gap:24px}}\n", "",{"version":3,"sources":["webpack://./../src/components/Forms/ExchangeForm/exchangeForm.module.scss"],"names":[],"mappings":"AAIA,aACE,UAAW,CACX,aAAc,CACf,qBAGC,YAAa,CACb,8BAA+B,CAC/B,eAAgB,CAChB,kBAAmB,CACpB,gCAIC,YAAa,CACb,yBAA0B,CAC1B,WAAY,CACb,4BAGC,qBACE,yBAA0B,CAC1B,YAAa,CACd","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.form {\n  width: auto;\n  margin: 0 auto;\n}\n\n.counterBlock {\n  display: grid;\n  grid-template-columns: auto 1fr;\n  column-gap: 22px;\n  margin-bottom: 24px;\n}\n\n.balance,\n.counter {\n  display: grid;\n  grid-template-columns: 1fr;\n  row-gap: 8px;\n}\n\n@media (max-width: 767.9px) {\n  .counterBlock {\n    grid-template-columns: 1fr;\n    row-gap: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "form--LT5qo",
	"counterBlock": "counterBlock--yVhDR",
	"balance": "balance--N8U5U",
	"counter": "counter--TKbXh"
};
export default ___CSS_LOADER_EXPORT___;
