import { Headline } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';
import React from 'react';
import { Modal } from '../Modal';
import { IModalProps } from '../modal.types';
import { useTranslation } from 'react-i18next';

const ModalComponent: React.FC<IModalProps> = ({
	isOpen,
	onClose
}) => {
	const {t} = useTranslation();
	if (!isOpen) {
		return null;
	}
    
	return <Modal onClose={onClose}>
		<Headline type='h1' color={colors.primary800}>
			{t('maintenance')}
		</Headline>
	</Modal>;
};

export const ServiceModal = React.memo(ModalComponent);
