import React from 'react';
import { IIcon } from './icons.type';

export const RefilIcon: React.FC<IIcon> = ({color}) => {
	return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" stroke={color || '#0C1A66'}
		xmlns="http://www.w3.org/2000/svg">
		<path d="M6.04688 7.73517L9 10.6875L11.9531 7.73517" strokeWidth="1.6875" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M9 2.8125V10.6854" strokeWidth="1.6875" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M15.1875 10.6875V14.625C15.1875 14.7742 15.1282 14.9173 15.0227 15.0227C14.9173 15.1282 14.7742 15.1875 14.625 15.1875H3.375C3.22582 15.1875 3.08274 15.1282 2.97725 15.0227C2.87176 14.9173 2.8125 14.7742 2.8125 14.625V10.6875" strokeWidth="1.6875" strokeLinecap="round" strokeLinejoin="round"/>
	</svg>;
};
