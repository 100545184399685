// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".successExchangeWrapper--HmxRF{display:grid;justify-content:center;justify-items:center;grid-template-columns:1fr}.title--_Ubo_{margin-bottom:8px}.subtitle--UZBpw{margin-bottom:24px}\n", "",{"version":3,"sources":["webpack://./../src/components/Modals/SuccessExchange/modalSuccessExchange.module.scss"],"names":[],"mappings":"AAIA,+BACE,YAAa,CACb,sBAAuB,CACvB,oBAAqB,CACrB,yBAA0B,CAC3B,cAGC,iBAAkB,CACnB,iBAGC,kBAAmB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.successExchangeWrapper {\n  display: grid;\n  justify-content: center;\n  justify-items: center;\n  grid-template-columns: 1fr;\n}\n\n.title {\n  margin-bottom: 8px;\n}\n\n.subtitle {\n  margin-bottom: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successExchangeWrapper": "successExchangeWrapper--HmxRF",
	"title": "title--_Ubo_",
	"subtitle": "subtitle--UZBpw"
};
export default ___CSS_LOADER_EXPORT___;
