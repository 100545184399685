import React, { useEffect, useState } from 'react';
import styles from './confirm.contactForm.module.scss';
import { IConfirmContactForm, IConfirmContactFormData, IRequestConfirm } from './ConfirmContactForm.types';
import { Button, Headline, Input } from 'forly-kit';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { userAPI } from 'services/userService';
import { colors } from 'forly-kit/dist/colors';
import { CheckIcon } from 'forly-kit/dist/icons';

const Form: React.FC<IConfirmContactForm> = ({
	type,
	value,
	cancelConfirm,
}) => {
	const {t} = useTranslation();
	const [confirmed, setConfirmed] = useState<boolean>(false);

	const [
		confirm,
		{isLoading: isPending, isError: errorConfirm, isSuccess: successConfirm}
	] = userAPI.useConfirmContactMutation();

	const codeLabel = type === 'phone' ? 'phoneCode' : 'telegramCode';

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm<IConfirmContactFormData>({
		mode: 'onChange',
	});

	useEffect(() => {
		if (successConfirm) {
			setConfirmed(true);
		}
	}, [successConfirm]);

	const confirmHandler = (data: IConfirmContactFormData) => {
		const requestBody: IRequestConfirm = {...data};
		if (type === 'phone') {
			requestBody.phone = value;
		} else {
			requestBody.telegram_id = value;
		}
		confirm(requestBody);
	};

	return <>
		{
			!confirmed && <form className={styles.wrapper}>
				<Headline className={styles.title} type='h2'>
					{type === 'phone'
						? t('confirmPhone')
						: t('confirmTelegram')
					}
				</Headline>
				<Input
					label={t(codeLabel)}
					register={() =>
						register('code', {
							required: `${t('errors.requiredField')}`,
						})
					}
					errorMsg={errors.code?.message || ''}
					status={errors.code ? 'error' : null}
				/>
				<Input
					label={t('emailCode')}
					register={() =>
						register('email_code', {
							required: `${t('errors.requiredField')}`,
						})
					}
					errorMsg={errors.email_code?.message || ''}
					status={errors.email_code ? 'error' : null}
				/>
				<div className={styles.footer}>
					<Button
						type='button'
						variant='secondary'
						text={t('cancel')}
						onClick={cancelConfirm}
						fill
						disabled={isPending}
					/>
					<Button
						type='button'
						onClick={handleSubmit(confirmHandler)}
						variant='primary'
						text={t('confirm')}
						fill
						disabled={isPending}
					/>
				</div>
			</form>
		}
		{
			confirmed && <div className={styles.confirmedWrapper}>
				<Headline type='h2' color={colors.success} className={styles.confirmedTitle}>
					<CheckIcon color={colors.success} />
					{type === 'telegram' ? 'Telegram' : t('phone')} {t('successConfirm')}
				</Headline>
				<Button variant='secondary' text={t('exchangePage.success_exchange_button')} onClick={cancelConfirm} />
			</div>
		}
	</>;
};

export const ConfirmContactForm = React.memo(Form);
