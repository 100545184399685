import React, { useMemo, useRef, useState } from 'react';
import styles from './refil.module.scss';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { BalanceList } from '../../components/BalanceList/BalanceList';
import { Headline, Text, ValueBox } from 'forly-kit';
import { BusdIcon, CopyIcon, ForlyTokenIcon } from 'forly-kit/dist/icons';
import { bankAPI } from 'services/bankService';
import { managementAPI } from 'services/managementService';
import { Loader } from '../../components/Loader/Loader';
import { TagTab } from '../../components/TagTab/TagTab';
import { colors } from 'forly-kit/dist/colors';
import { Warning } from '../../components/Warning/Warning';
import {QRCodeSVG} from 'qrcode.react';
import { useTranslation } from 'react-i18next';

export const Refil: React.FC = () => {
	const {t} = useTranslation();
	const [activeTab, setActiveTab] = useState<string>('fcc');

	const walletRef = useRef<HTMLSpanElement>(null);

	const { data: balances, isLoading: balanceLoading } =
		bankAPI.useFetchBalanceQuery(null);
	const { data: lastFT, isLoading: lastFTLoading } =
		managementAPI.useFetchLastFTQuery(null);
	const {data: wallets, isLoading: walletPending} = bankAPI.useWalletsQuery(null);

	const wallet = useMemo(() => {
		const symbol = activeTab === 'busd' ? 'usdt' : 'ft';
		if (wallets) {
			const findWallet = wallets.find((w) => w.token.symbol.toUpperCase() === symbol.toUpperCase());
			return findWallet ? findWallet.address : 'свободного кошелька нет';
		}
	}, [wallets, activeTab]);

	const changeTab = (key: string) => {
		setActiveTab(key);
	};

	const copyWallet = () => {
		navigator.clipboard.writeText(walletRef.current?.innerText || '');
	};

	if (balanceLoading || lastFTLoading) {
		return <Loader />;
	}

	return <>
		<div className='contentWrapper'>
			<div className='content'>
				<div className='card cardContent'>
					<div className={styles.refilWrapper}>
						<div>
							<Headline type='h3' className={styles.title}>
								{t('refilPage.refilToken')}
							</Headline>
							<div className={styles.tabList}>
								<TagTab
									className={styles.tab}
									keyTab='fcc'
									isActive={activeTab === 'fcc'}
									Icon={ForlyTokenIcon}
									title='fcc'
									click={changeTab}
								/>
								<TagTab
									className={styles.tab}
									keyTab='busd'
									isActive={activeTab === 'busd'}
									Icon={BusdIcon}
									title='busd'
									click={changeTab}
								/>
							</div>
							<Warning
								text=	{t('refilPage.warning1')}
							/>
						</div>
						<div>
							<Headline type='h4' className={styles.walletText}>
								{t('refilPage.copyWallet')}
							</Headline>
							<div className={styles.wallet} onClick={copyWallet}>
								<CopyIcon />
								<Text className={styles.walletNumber} size='s' color={colors.primary700}>
									<span ref={walletRef}>
										{walletPending ? `${t('refilPage.walletPending')}` : wallet}
									</span>
								</Text>
							</div>
							<Headline type='h4' className={styles.walletText}>
								{t('refilPage.scanQr')}
							</Headline>
							<QRCodeSVG
								value={wallet || ''}
								size={120}
								bgColor={colors.white}
								includeMargin={false}
								level={'H'}
							 	fgColor='#000000'
							/>
						</div>

						<div>
							<Warning
								text={t('refilPage.warning2')}
							/>
						</div>
					</div>

				</div>



			</div>
			<Sidebar>
				<BalanceList
					className={styles.sidebarBalanceList}
					balances={balances || []}
				/>
				<ValueBox title={t('exchangeRatesTitle')}>
					<div className={styles.sidebarRate}>
						<ForlyTokenIcon />
						<Text size='m'>1 FСС = {lastFT} BUSD</Text>
					</div>
				</ValueBox>
			</Sidebar>
		</div>
	</>;
};
