import React, { useMemo } from 'react';
import styles from './userHoldCard.module.scss';
import { IUserHoldCard } from './userHoldCard.types';
import { Headline, ValueBox, Text } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';
import { addMonths, format } from 'date-fns';
import { formatNumber } from '../../helpers/formatNumber';
import { useTranslation } from 'react-i18next';

export const UserHoldCard: React.FC<IUserHoldCard> = ({
	value,
	created,
	period,
	blockchain_id,
	commission
}) => {
	const {t, i18n} = useTranslation();
	
	const textColor = useMemo(() => {
		return colors.primary800;
	}, []);
	
	const unHoldDate = useMemo(() => {
		const date = addMonths(new Date(created * 1000), period);
		if (period === 0) {
			return t('unlimitedPeriod');
		}
		return format(date, 'dd.MM.yyyy');
	}, [created, period, i18n]);
	
	const profitPercent = useMemo(() => {
		return formatNumber(commission * 0.0001 * value, 5);
	}, [value, commission]);
	
	return <ValueBox >
		<div className={styles.hold}>
			<div className={styles.holdData}>
				<div className={styles.holdValue}>
					<Headline type='h4' color={textColor}>{t('unfrozenDate')}</Headline>
					<Text size='m' color={textColor}>{unHoldDate}</Text>
				</div>
				<div className={styles.holdValue}>
					<Headline type='h4' color={textColor}>{t('quantity')}</Headline>
					<Text size='m' color={textColor}>{value} FCC</Text>
				</div>
				<div className={styles.holdValue}>
					<Headline type='h4' color={textColor}>{t('profitPercent')}</Headline>
					<Text size='m' color={textColor}>{profitPercent}%</Text>
				</div>
			</div>

			<a className={styles.walletLink} href={`${CONFIG.explorerUrl}/${blockchain_id}`} target='_blank' rel="noreferrer">
				<Text size='button' color={colors.primary700}>{t('transaction')}</Text>
			</a>
			{/*<span className={styles.walletLink} aria-label={`${CONFIG.explorerUrl}/${blockchain_id}`}>Кошелек</span>*/}
		</div>
	</ValueBox>;
};
