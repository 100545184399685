import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import styles from './exchange.module.scss';
import { EBalanceSymbol } from 'types/common.types';
import { useTranslation } from 'react-i18next';
import { Headline, Text, ValueBox } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';
import { bankAPI } from 'services/bankService';
import { managementAPI } from 'services/managementService';
import { Loader } from '../../components/Loader/Loader';
import { BalanceList } from '../../components/BalanceList/BalanceList';
import { ForlyTokenIcon } from 'forly-kit/dist/icons';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { ExchangeForm } from '../../components/Forms';
import { tokensLabel } from '../../helpers/common';
import { ServiceModal } from 'src/components/Modals/ServiceModal/ServiceModal';

export const Exchange: React.FC = () => {
	const { t, i18n } = useTranslation();
	const [serviceModalOpen, setServiceModalOpen] = useState<boolean>(false);
	
	const { data: balances, isLoading: balanceLoading } =
    bankAPI.useFetchBalanceQuery(null);
	const { data: lastFT, isLoading: lastFTLoading } =
    managementAPI.useFetchLastFTQuery(null);

	const openServiceModal = () => {
		setServiceModalOpen(true);
	};

	const closeServiceModal = () => {
		setServiceModalOpen(false);
	};

	const tabs = useMemo(() => {
		return [
			{
				id: 1,
				from: EBalanceSymbol.USDT,
				to: EBalanceSymbol.FT,
				label: t('operation.buyFCC'),
			},
			{
				id: 2,
				from: EBalanceSymbol.FT,
				to: EBalanceSymbol.USDT,
				label: t('operation.sellFCC'),
			},
		];
	}, [i18n.language]);
	
	const [activeTab, setActiveTab] = useState(tabs[0]);

	const activeBalance = useMemo(() => {
		if (balances && balances.length) {
			const balance = balances.find((b) => {
				return b.token_symbol === activeTab.from;
			});
			if (balance) {
				return balance;
			}
		}
		return null;
	}, [activeTab, balances]);

	const changeTabHandler = (tab: any) => () => {
		if (tab.from === EBalanceSymbol.FT) {
			openServiceModal();
			return;
		}
		
		setActiveTab(tab);
	};

	if (lastFTLoading || balanceLoading || !activeBalance) {
		return <Loader />;
	}

	return (
		<>
			<div className='contentWrapper'>
				<div className='content'>
					<div className='card'>
						<div className={styles.tabs}>
							{tabs.map((tab) => {
								const isActive = tab.id === activeTab.id;
								const itemClasses = classNames(styles.tabItem, {
									[styles.active]: isActive,
								});
								return (
									<div
										className={itemClasses}
										key={tab.id}
										onClick={changeTabHandler(tab)}
									>
										<Headline
											type="h4"
											color={isActive ? colors.primary800 : colors.primary700}
										>
											{tab.label}
										</Headline>
										<Text
											size="m"
											color={isActive ? colors.primary800 : colors.primary700}
										>
										({tokensLabel[tab.from]} - {tokensLabel[tab.to]})
										</Text>
									</div>
								);
							})}
						</div>
						<div className={styles.tabContent}>
							<ExchangeForm
								fromSymbol={activeBalance.token_symbol}
								maxValue={activeBalance.value_free}
								toSymbol={activeTab.to}
							/>
						</div>
					</div>
				</div>
				<Sidebar>
					<BalanceList
						className={styles.sidebarBalanceList}
						balances={balances || []}
					/>
					<ValueBox title={t('exchangeRatesTitle')}>
						<div className={styles.sidebarRate}>
							<ForlyTokenIcon />
							<Text size='m'>1 FСС = {lastFT} BUSD</Text>
						</div>
					</ValueBox>
				</Sidebar>
			</div>

			<ServiceModal 
				isOpen={serviceModalOpen}
				onClose={closeServiceModal}
			/>
		</>
		
	);
};

export default Exchange;


