// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--Z_1vn{max-width:550px;display:grid;grid-template-columns:1fr;row-gap:24px}.wrapper--Z_1vn p{text-align:center}.form--tOyZf{display:grid;grid-template-columns:1fr;row-gap:32px;justify-content:center}\n", "",{"version":3,"sources":["webpack://./../src/components/Modals/ClosePositionModal/ClosePositionModal.module.scss"],"names":[],"mappings":"AAIA,gBACC,eAAgB,CAChB,YAAa,CACb,yBAA0B,CAC1B,YAAa,CAJd,kBAOE,iBAAkB,CAClB,aAID,YAAa,CACb,yBAA0B,CAC1B,YAAa,CACb,sBAAuB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.wrapper {\n\tmax-width: 550px;\n\tdisplay: grid;\n\tgrid-template-columns: 1fr;\n\trow-gap: 24px;\n\n\tp {\n\t\ttext-align: center;\n\t}\n}\n\n.form {\n\tdisplay: grid;\n\tgrid-template-columns: 1fr;\n\trow-gap: 32px;\n\tjustify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--Z_1vn",
	"form": "form--tOyZf"
};
export default ___CSS_LOADER_EXPORT___;
