import { enUS, ru } from 'date-fns/locale';

import i18n from '../i18n';

interface Locales {
	[key: string]: Locale;
}

const locales: Locales = {
	en: enUS,
	ru: ru,
};

export const getDateFnsLocale = (): Locale => {
	return locales[i18n.language];
};


