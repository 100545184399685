import React, { useEffect, useMemo, useState } from 'react';
import { activationKeysAPI } from 'services/activationKeysService';
import { managementAPI } from 'services/managementService';
import { Loader } from '../../components/Loader/Loader';
import { KeysPageWrapper } from '../../components/KeysPageWrapper/KeysPageWrapper';
import { ForexInstruction } from '../../components/Instructions/ForexInstruction/ForexInstruction';
import { bankAPI } from 'services/bankService';
import { EBalanceSymbol } from 'types/common.types';
import { AddForexKeyBlock } from '../../components/AddForexKeyBlock/AddForexKeyBlock';
import { forexKeysAPI } from 'services/forexKeysService';
import { ForexKey } from './ForexKey/ForexKey';
import { AddKeyButton } from '../../components/KeysPageWrapper/AddKeyButton/AddKeyButton';
import { ForexInstructionModal } from '../../components/Modals/ForexInstructionModal/ForexInstructionModal';

export const ForexPage:React.FC = () => {
	const [showAdd, setShowAdd] = useState<boolean>(false);

	const {data: activations, isLoading: activationsLoading} = activationKeysAPI.useFetchActivationListQuery(null);
	const { data: lastFT, isLoading: lastFTLoading } =
		managementAPI.useFetchLastFTQuery(null);
	const {
		data: activationCount,
		isLoading: activationCountLoading,
	} = activationKeysAPI.useFetchActivationCountQuery(null);
	const {data: balances, isLoading: balancePending} = bankAPI.useFetchBalanceQuery(null);
	const {data: keys} = forexKeysAPI.useForexKeysQuery(null);

	const FCCBalance = useMemo(() => {
		return balances?.find((b) => b.token_symbol === EBalanceSymbol.FT);
	}, [balances]);

	const BUSDBalance = useMemo(() => {
		return balances?.find((b) => b.token_symbol === EBalanceSymbol.USDT);
	}, [balances]);

	const forexActivations = useMemo(() => {
		return activationCount?.FRX || null;
	}, [activationCount]);

	useEffect(() => {
		if (activationCount && !forexActivations?.used) {
			setShowAdd(true);
		}
	}, [forexActivations]);

	const forexBusdPrice = useMemo(() => {
		if (activations?.FRX) {
			return +activations.FRX.price;
		}
		return 0;
	}, [activations]);

	const forexFccPrice = useMemo(() => {
		if (forexBusdPrice && lastFT) {
			return forexBusdPrice / lastFT;
		}
		return 0;
	}, [forexBusdPrice, lastFT]);

	const hideAddBlock = () => {
		setShowAdd(false);
	};

	if (
		activationsLoading
		|| activationCountLoading
		|| lastFTLoading
		|| balancePending
	) {
		return <Loader />;
	}

	return <KeysPageWrapper
		isForex
		type={activations?.FRX.id || 0}
		usedActivations={forexActivations?.used || 0}
		freeActivations={forexActivations?.active || 0}
		busdPrice={forexBusdPrice}
		fccPrice={forexFccPrice}
		instruction={{
			Instruction: ForexInstructionModal
		}}
	>
		<>
			{showAdd &&
				<AddForexKeyBlock
					totalKeys={forexActivations?.total || 0}
					freeActivations={forexActivations?.active || 0}
					fccPrice={forexFccPrice}
					busdPrice={forexBusdPrice}
					fccBalance={FCCBalance || null}
					busdBalance={BUSDBalance || null}
					Instruction={ForexInstruction}
					goToKeys={hideAddBlock}
				/>
			}

			{!showAdd && keys?.results.length && <>
				{
					keys.results.map((k) => {
						return <ForexKey key={k.id} id={k.id} />;
					})
				}
				<AddKeyButton addKeyHandler={() => setShowAdd(true)} />
			</>}
		</>
	</KeysPageWrapper>;

};
