import React, { useEffect, useState } from 'react';
import styles from './CreateSpotOrderForm.module.scss';
import cn from 'classnames';
import { useForm } from 'react-hook-form';
import { ICreateSpotOrderData, ISpotOrder, ISpotOrderForm } from 'types/trading.types';
import { Button, Checkbox, Headline, Input, Text } from 'forly-kit';
import { ITab } from 'src/components/Tabs/tabs.types';
import { Tabs } from 'src/components/Tabs/Tabs';
import { TagTab } from 'src/components/TagTab/TagTab';
import { changeNumberInput } from 'src/helpers/patterns';
import { ICreateSpotOrderForm } from './CreateSpotOrderForm.types';
import { spotAPI } from 'services/spotService';
import { colors } from 'forly-kit/dist/colors';
import { format } from 'date-fns';
import { ICreateOrderData } from '../../../pages/TradingFutures/tradingFutures.types';
import { ConfirmFuturesOrderModal } from '../../Modals/ConfirmFuturesOrderModal/ConfirmFuturesOrderModal';

const pairs = [
	{
		keyTab: 'btceth',
		title: 'btceth'
	},
	{
		keyTab: 'bnbbtc',
		title: 'bnbbtc'
	},
	{
		keyTab: 'ethxpr',
		title: 'ethxpr'
	},
	{
		keyTab: 'bnbxpr',
		title: 'bnbxpr'
	}
];

const orderTabs: ITab[] = [
	{
		id: 1,
		label: 'Обычный ордер',
		value: 'SIMPLE',
	},
	{
		id: 2,
		label: 'OCO-ордер',
		value: 'OCO'
	}
];

export const CreateSpotOrderForm: React.FC<ICreateSpotOrderForm> = ({
	rerenderParent,
	createOrder,
	createOcoOrder
}) => {
	// const {data: orders, refetch: refetchOrders} = spotAPI.useGetSpotOrdersQuery(null);
	const [activePair, setActivePair] = useState<string>('');
	// const {data: filledOrders} = spotAPI.useGetFilledOrdersQuery({pair: activePair});

	const [activeOrder, setActiveOrder] = useState<ITab>(orderTabs[0]);
	const [isOrderWithStop, setIsOrderWithStop] = useState<boolean>(false);
	// const [connectedOrders, setConnectedOrders] = useState<ISpotOrder[]>([]);
	const [orderData, setOrderData] = useState<ICreateOrderData | null>(null);
	const [errorCreateOrder, setErrorCreateOrder] = useState<string>('');
	const [createOrderPending, setCreateOrderPending] = useState<boolean>(false);

	// const [
	// 	createOrder,
	// 	{isLoading: createOrderPending, isSuccess: createOrderSuccess, isError: createOrderError}
	// ] = spotAPI.useCreateSpotOrderMutation();
	

	const {
		register,
		formState: { errors },
		setValue,
		handleSubmit,
		reset
	} = useForm<ISpotOrderForm>({
		mode: 'onChange',
	});

	// useEffect(() => {
	// 	if (createOrderSuccess) {
	// 		refetchOrders();
	// 		reset();
	// 	}
	// }, [createOrderSuccess]);

	// useEffect(() => {
	// 	if (createOrderError) {
	// 		setErrorCreateOrder('Не удалось создать ордер');
	// 	}
	// }, [createOrderError]);

	useEffect(() => {
		if (activeOrder.value === 'OCO' && !isOrderWithStop) {
			setIsOrderWithStop(true);
		}
		if (rerenderParent) {
			rerenderParent();
		}
	}, [activeOrder]);

	// useEffect(() => {
	// 	if (!filledOrders) {
	// 		return;
	// 	}
	//
	// 	if (activeOrder.value === 'SIMPLE') {
	// 		setConnectedOrders([]);
	// 	}
	//
	// 	if (activeOrder.value === 'OCO') {
	// 		// const filtered = filledOrders.filter((o) => o.status === 'NEW'
	// 		// && o.symbol_name === activePair.toUpperCase()
	// 		// && o.side === 'BUY'
	// 		// );
	// 		setConnectedOrders(filledOrders);
	// 	}

	// 	setTimeout(() => {
	// 		if (rerenderParent) {
	// 			rerenderParent();
	// 		}
	// 	});
	// }, [activeOrder, activePair]);

	const changeOrderWithStop = (value: boolean) => {
		setIsOrderWithStop(value);
		setTimeout(() => {
			if (rerenderParent) {
				rerenderParent();
			}
		});
	};

	const changeOrderType = (type: string | number) => {
		// clearErrors();
		const finded = orderTabs.find((t) => t.value === type);
		if (!finded) {
			return;
		}
		setActiveOrder(finded);
	};

	const inputPairChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setActivePair(e.target.value.toUpperCase());
	};

	const changeNumberHandler = (name: keyof ISpotOrderForm) => (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = changeNumberInput(e.currentTarget.value);
		setValue(name, value);
	};

	const clickTabHandler = (key: string) => {
		setActivePair(key);
		setValue('symbol', key);
	};

	const closeConfirmOrder = () => {
		setErrorCreateOrder('');
		setOrderData(null);
	};

	const createOrderHandler = (data: ISpotOrderForm) => {
		const newOrder: ICreateOrderData = {
			symbol_name: data.symbol.toUpperCase(),
			quantity: +data.quantity,
			side: activeOrder.value === 'OCO' ? 'SELL' : 'BUY',
			type: activeOrder.value === 'OCO' ? 'OCO' : 'LIMIT'
		};

		if (activeOrder.value === 'SIMPLE') {
			newOrder.price = +data.price;
		}

		// if (activeOrder.value === 'OCO' && !data.connected_to) {
		// 	return;
		// }

		// if (activeOrder.value === 'OCO') {
		// 	newOrder.connected_to = +data.connected_to;
		// }

		if (data.take_profit) {
			newOrder.takeProfit = +data.take_profit;
		}

		if (data.stop_loss) {
			newOrder.stopLoss = +data.stop_loss;
		}

		if (data.stop_limit_price) {
			newOrder.stop_price = +data.stop_limit_price;
		}

		setOrderData({
			...newOrder,
		});
	};

	const createSpotOrder = () => {
		setErrorCreateOrder('');
		if (!orderData) {
			return;
		}

		const order: ICreateSpotOrderData = {
			symbol_name: orderData.symbol_name,
			quantity: orderData.quantity || 0,
		};

		if (orderData.price) {
			order.price = orderData.price;
		}

		// if (activeOrder.value === 'OCO') {
		// 	order.connected_to = orderData.connected_to;
		// }

		if (orderData.takeProfit) {
			order.take_profit = orderData.takeProfit;
		}

		if (orderData.stopLoss) {
			order.stop_loss = orderData.stopLoss;
		}

		if (orderData.stop_price) {
			order.stop_limit_price = orderData.stop_price;
		}

		setCreateOrderPending(true);

		if (createOrder && activeOrder.value !== 'OCO') {
			createOrder(order);
		}

		if (createOcoOrder && activeOrder.value === 'OCO') {
			createOcoOrder(order);
		}

		setTimeout(() => {
			setCreateOrderPending(false);
			reset();
			setActivePair('');
			closeConfirmOrder();
		}, 1500);
	};

	return <>
		<form className={styles.form} onSubmit={handleSubmit(createOrderHandler)}>
			<div className={styles.fieldSymbol}>
				<Headline type='h4'>
					Тип ордера
				</Headline>
				<Tabs
					tabs={orderTabs}
					activeTab={activeOrder}
					action={changeOrderType}
				/>
				{activeOrder.value === 'SIMPLE' ? <Checkbox
					name='stopOrder'
					onChange={changeOrderWithStop}
					checked={isOrderWithStop}

				>
					Ордер со стопами
				</Checkbox> : null}

			</div>

			<div className={styles.inputPairWrapper}>
				<Input
					label='Валютная пара'
					register={() =>
						register('symbol',{
							onChange: inputPairChangeHandler
						})
					}
				/>
				<div className={styles.pairTabs}>
					{pairs.map((pair) => {
						return <TagTab
							className={styles.tab}
							keyTab={pair.keyTab}
							title={pair.title}
							isActive={pair.keyTab === activePair}
							key={pair.keyTab}
							click={clickTabHandler}
						/>;
					})}
				</div>
			</div>

			<div className={styles.inputBlock}>
				{activeOrder.value === 'SIMPLE' && <Input
					label='Цена'
					register={() =>
						register('price',{
							onChange: changeNumberHandler('price')
						})
					}
				/>}

				<Input
					label='Количество'
					register={() =>
						register('quantity',{
							onChange: changeNumberHandler('quantity'),
							required: {
								value: true,
								message: 'Поле обязательно для заполнения'
							},
							max: {
								value: 100,
								message: 'максимум 100'
							},
							min: {
								value: 0.001,
								message: 'минимум 0.001'
							}
						})
					}
					errorMsg={errors.quantity?.message}
					status={errors.quantity ? 'error' : null}
				/>

				{(isOrderWithStop || activeOrder.value === 'OCO') && <>
					<Input
						label='Тейк профит, %'
						register={() =>
							register('take_profit',{
								onChange: changeNumberHandler('take_profit')
							})
						}
					/>
					<Input
						label='Стоп лосс, %'
						register={() =>
							register('stop_loss',{
								onChange: changeNumberHandler('stop_loss')
							})
						}
					/>
					<div
						className={cn({
							[styles.fullInput]: activeOrder.value !== 'OCO'
						})}
					>
						<Input
							label='Стоп лосс лимит, %'
							register={() =>
								register('stop_limit_price',{
									onChange: changeNumberHandler('stop_limit_price')
								})
							}
						/>
					</div>

				</> }
			</div>

			{/*{connectedOrders.length ? <Headline type='h4'>Последние ордера</Headline> : null}*/}

			{/*{connectedOrders.map((o) => {*/}
			{/*	return <div key={o.id}>*/}
			{/*		<div className={cn('card cardContent', styles.connectedOrdersWrapper)}>*/}
			{/*			<input*/}
			{/*				type='radio'*/}
			{/*				id={o.id + ''}*/}
			{/*				value={o.connected_to || 0}*/}
			{/*				{...register('connected_to')}*/}
			{/*			/>*/}
			{/*			<label className={styles.connectedOrder} htmlFor={o.id+''}>*/}
			{/*				<div className={styles.connectedOrderInfo}>*/}
			{/*					<Text size='s' color={colors.neutral600}>Пара:</Text>*/}
			{/*					<Text size='s'>{o.symbol_name}</Text>*/}
			{/*				</div>*/}

			{/*				<div className={styles.connectedOrderInfo}>*/}
			{/*					<Text size='s' color={colors.neutral600}>Цена:</Text>*/}
			{/*					<Text size='s'>{o.price}</Text>*/}
			{/*				</div>*/}

			{/*				<div className={styles.connectedOrderInfo}>*/}
			{/*					<Text size='s' color={colors.neutral600}>Объем:</Text>*/}
			{/*					<Text size='s'>{o.orig_qty}</Text>*/}
			{/*				</div>*/}

			{/*				<div className={styles.connectedOrderInfo}>*/}
			{/*					<Text size='s' color={colors.neutral600}>Дата:</Text>*/}
			{/*					<Text size='s'>{format(new Date(o.transact_time), 'dd.MM.yyyy HH:mm')}</Text>*/}
			{/*				</div>*/}
			{/*			</label>*/}
			{/*		</div>*/}

			{/*		/!* <input*/}
			{/*		type='radio'*/}
			{/*		name='orderinfo'*/}
			{/*		id={o.id + ''}*/}
			{/*		value={o.id}*/}
			{/*	>*/}
			{/*		<div className={styles.connectedOrder}>*/}
			{/*			<div className={styles.connectedOrderInfo}>*/}
			{/*				<Text size='s' color={colors.neutral600}>Пара:</Text>*/}
			{/*				<Text size='s'>{o.symbol_name}</Text>*/}
			{/*			</div>*/}

			{/*			<div className={styles.connectedOrderInfo}>*/}
			{/*				<Text size='s' color={colors.neutral600}>Цена:</Text>*/}
			{/*				<Text size='s'>{o.price}</Text>*/}
			{/*			</div>*/}

			{/*			<div className={styles.connectedOrderInfo}>*/}
			{/*				<Text size='s' color={colors.neutral600}>Объем:</Text>*/}
			{/*				<Text size='s'>{o.orig_qty}</Text>*/}
			{/*			</div>*/}

			{/*			<div className={styles.connectedOrderInfo}>*/}
			{/*				<Text size='s' color={colors.neutral600}>Дата:</Text>*/}
			{/*				<Text size='s'>{format(new Date(o.transact_time), 'dd.MM.yyyy HH:mm')}</Text>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</input> *!/*/}
			{/*	</div>;*/}
			{/*})}*/}

			<Button type='submit' variant='primary' text='Выставить ордер'/>
		</form>

		{orderData && <ConfirmFuturesOrderModal
			order={[orderData]}
			createOrder={createSpotOrder}
			isPending={createOrderPending}
			errorMessage={errorCreateOrder}
			isOpen={!!orderData}
			onClose={closeConfirmOrder}
		/> }
	</>;
};
