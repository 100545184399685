// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".order--EkD7a{display:grid;grid-template-columns:repeat(3, auto);justify-content:space-between;align-items:center;padding:16px;box-shadow:0px 0px 8px rgba(0,0,0,0.1);border-radius:8px;position:relative}.orderInfo--O26RW{display:grid;grid-template-columns:repeat(2, auto);column-gap:8px;align-items:center}.orderData--iBpaH{display:grid;grid-template-columns:repeat(2, auto);column-gap:12px;align-items:center}.orderData--iBpaH .orderDataItem--ft0Du{display:inline}@media (max-width: 768px){.order--EkD7a{grid-template-columns:1fr;justify-content:start;justify-items:start;row-gap:16px}}\n", "",{"version":3,"sources":["webpack://./../src/pages/TradingFutures/FutureOrder/futureOrder.module.scss"],"names":[],"mappings":"AAIA,cACC,YAAa,CACb,qCAAsC,CACtC,6BAA8B,CAC9B,kBAAmB,CACnB,YAAa,CACb,sCAA0C,CAC1C,iBAAkB,CAClB,iBAAkB,CAClB,kBAGA,YAAa,CACb,qCAAsC,CACtC,cAAe,CACf,kBAAmB,CACnB,kBAGA,YAAa,CACb,qCAAsC,CACtC,eAAgB,CAChB,kBAAmB,CAJpB,wCAOE,cAAe,CACf,0BAID,cACC,yBAA0B,CAC1B,qBAAsB,CACtB,mBAAoB,CACpB,YAAa,CACb","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.order {\n\tdisplay: grid;\n\tgrid-template-columns: repeat(3, auto);\n\tjustify-content: space-between;\n\talign-items: center;\n\tpadding: 16px;\n\tbox-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);\n\tborder-radius: 8px;\n\tposition: relative;\n}\n\n.orderInfo {\n\tdisplay: grid;\n\tgrid-template-columns: repeat(2, auto);\n\tcolumn-gap: 8px;\n\talign-items: center;\n}\n\n.orderData {\n\tdisplay: grid;\n\tgrid-template-columns: repeat(2, auto);\n\tcolumn-gap: 12px;\n\talign-items: center;\n\n\t.orderDataItem {\n\t\tdisplay: inline;\n\t}\n}\n\n@media (max-width: 768px) {\n\t.order {\n\t\tgrid-template-columns: 1fr;\n\t\tjustify-content: start;\n\t\tjustify-items: start;\n\t\trow-gap: 16px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"order": "order--EkD7a",
	"orderInfo": "orderInfo--O26RW",
	"orderData": "orderData--iBpaH",
	"orderDataItem": "orderDataItem--ft0Du"
};
export default ___CSS_LOADER_EXPORT___;
