// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sidebar--CqMTM{overflow:hidden;overflow-y:auto}@media (max-width: 1023.9px){.sidebar--CqMTM{display:grid;grid-template-columns:72px 1fr;height:100%;overflow:hidden}}@media (max-width: 767.9px){.sidebar--CqMTM{display:block}}.sidebarContent--j5fLO{height:100%;overflow:auto}.openButton--MEYMu{width:72px;height:100%;display:none;box-shadow:0 0 8px rgba(180,187,219,0.2)}@media (max-width: 1023.9px){.openButton--MEYMu{display:flex;justify-content:center;align-items:center}}@media (max-width: 767.9px){.openButton--MEYMu{display:none}}\n", "",{"version":3,"sources":["webpack://./../src/components/Sidebar/sidebar.module.scss"],"names":[],"mappings":"AAIA,gBACE,eAAgB,CAChB,eAAgB,CAEhB,6BAJF,gBAKI,YAAa,CACb,8BAA+B,CAC/B,WAAY,CACZ,eAAgB,CAMnB,CAHC,4BAXF,gBAYI,aAAc,CAEjB,CAED,uBACE,WAAY,CACZ,aAAc,CACf,mBAGC,UAAW,CACX,WAAY,CACZ,YAAa,CACb,wCAA4C,CAE5C,6BANF,mBAOI,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CAMtB,CAHC,4BAZF,mBAaI,YAAa,CAEhB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.sidebar {\n  overflow: hidden;\n  overflow-y: auto;\n\n  @media (max-width: 1023.9px) {\n    display: grid;\n    grid-template-columns: 72px 1fr;\n    height: 100%;\n    overflow: hidden;\n  }\n\n  @media (max-width: 767.9px) {\n    display: block;\n  }\n}\n\n.sidebarContent {\n  height: 100%;\n  overflow: auto;\n}\n\n.openButton {\n  width: 72px;\n  height: 100%;\n  display: none;\n  box-shadow: 0 0 8px rgba(180, 187, 219, 0.2);\n\n  @media (max-width: 1023.9px) {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  @media (max-width: 767.9px) {\n    display: none;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": "sidebar--CqMTM",
	"sidebarContent": "sidebarContent--j5fLO",
	"openButton": "openButton--MEYMu"
};
export default ___CSS_LOADER_EXPORT___;
