import React from 'react';
import { IModalProps } from '../modal.types';
import { Modal } from '../Modal';
import { Button } from 'forly-kit';
import { useTranslation } from 'react-i18next';
import { FuturesInstruction } from '../../Instructions/FuturesInstruction/FuturesInstruction';

export const FuturesInstructionModal: React.FC<IModalProps> = ({
	isOpen,
	onClose
}) => {
	const {t} = useTranslation();

	if (!isOpen) {
		return null;
	}
	return <Modal onClose={onClose}>
		<FuturesInstruction />
		<Button variant='primary' text={t('close')} onClick={onClose} fill />
	</Modal>;
};
