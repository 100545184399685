// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".holdList--y51w1{display:grid;grid-template-columns:1fr;margin-top:32px;row-gap:8px}.holdListTitle--qQZd9{margin-bottom:16px}.financeSidebar--bXrXn{display:grid;grid-template-rows:auto auto 1fr;row-gap:16px;height:100%;overflow:hidden}.tabs--D8SNR{display:grid;grid-template-columns:repeat(3, auto);column-gap:16px;align-items:baseline;justify-content:start;justify-items:start}.tabs--D8SNR div{cursor:pointer}.transactions--SlkEB{height:100%;overflow:auto}\n", "",{"version":3,"sources":["webpack://./../src/pages/Finance/finance.module.scss"],"names":[],"mappings":"AAMA,iBACE,YAAa,CACb,yBAA0B,CAC1B,eAAgB,CAChB,WAAY,CACb,sBAGC,kBAAmB,CACpB,uBAGC,YAAa,CACb,gCAAiC,CACjC,YAAa,CACb,WAAY,CACZ,eAAgB,CACjB,aAGC,YAAa,CACb,qCAAsC,CACtC,eAAgB,CAChB,oBAAqB,CACrB,qBAAsB,CACtB,mBAAoB,CANtB,iBASI,cAAe,CAChB,qBAID,WAAY,CACZ,aAAc","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n\n\n.holdList {\n  display: grid;\n  grid-template-columns: 1fr;\n  margin-top: 32px;\n  row-gap: 8px;\n}\n\n.holdListTitle {\n  margin-bottom: 16px;\n}\n\n.financeSidebar {\n  display: grid;\n  grid-template-rows: auto auto 1fr;\n  row-gap: 16px;\n  height: 100%;\n  overflow: hidden;\n}\n\n.tabs {\n  display: grid;\n  grid-template-columns: repeat(3, auto);\n  column-gap: 16px;\n  align-items: baseline;\n  justify-content: start;\n  justify-items: start;\n\n  div {\n    cursor: pointer;\n  }\n}\n\n.transactions {\n  height: 100%;\n  overflow: auto;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"holdList": "holdList--y51w1",
	"holdListTitle": "holdListTitle--qQZd9",
	"financeSidebar": "financeSidebar--bXrXn",
	"tabs": "tabs--D8SNR",
	"transactions": "transactions--SlkEB"
};
export default ___CSS_LOADER_EXPORT___;
