// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".footer--qWN6V{display:grid;grid-template-columns:repeat(2, 1fr);column-gap:16px}\n", "",{"version":3,"sources":["webpack://./../src/components/Forms/UpdateKeyForm/updateKeyForm.module.scss"],"names":[],"mappings":"AAIA,eACE,YAAa,CACb,oCAAqC,CACrC,eAAgB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.footer {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  column-gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "footer--qWN6V"
};
export default ___CSS_LOADER_EXPORT___;
