import React, { useState } from 'react';
import styles from './futurePositionsList.module.scss';
import cn from 'classnames';
import { futuresAPI } from './../../../services/futuresService';
import { IFuturePositionsList } from './FuturePositionsList.types';
import { Button, Headline } from 'forly-kit';
import { Loader } from 'src/components/Loader/Loader';
import { FuturePosition } from '../FuturePosition/FuturePosition';
import { ConfirmModal } from 'src/components/Modals/ConfirmModal/ConfirmModal';

export const FuturePositionsList: React.FC<IFuturePositionsList> = ({
	id,
	closeAll,
	closePosition,
	createOrder,
	closeOrder
}) => {
	const {
		data: positions, 
		isFetching: positionsPending, 
		refetch: refetchPositions
	} = futuresAPI.useGetPositionsQuery(id);

	const [loading, setLoading] = useState<boolean>(false);

	const [isClosePositionsOpened, setIsClosePositionsOpened] = useState<boolean>(false);

	const openConfirmModal = () => {
		setIsClosePositionsOpened(true);
	};

	const closeConfirmModal = () => {
		setIsClosePositionsOpened(false);
	};

	const closeAllPositionsHandler = () => {
		setLoading(true);
		closeAll();
		setTimeout(() => {
			setLoading(false);
			closeConfirmModal();
		}, 1000);
	};

	return <>
		<div className={cn('card cardContent', styles.list)}>
			{positionsPending && <Loader internal/>}
			{positions && positions.length !== 0 
				? <>
					<div className={styles.header}>
						<Headline type='h4'>
                        Открытые позиции
						</Headline>
            
						<Button variant='tertiary' text='Закрыть все' onClick={openConfirmModal} />
					</div>
					{positions.map((p) => <FuturePosition
						position={p}
						key={p.id}
						refetchPositions={refetchPositions}
						closePosition={closePosition}
						createOrder={createOrder}
						closeOrder={closeOrder}
					/>)}
				</>
				: <div className={styles.header}>
					<Headline type='h4'>
                    Позиций нет
					</Headline>
					<Button variant='tertiary' text='Закрыть все' onClick={openConfirmModal} />
				</div>    
			}
		</div>

		<ConfirmModal 
			isOpen={isClosePositionsOpened}
			onClose={closeConfirmModal}
			confirm={closeAllPositionsHandler}
			title='Закрыть все позиции'
			subtitle='Все открытые позиции будут закрыты'
			isLoading={loading}
		/>
	</>;
};
