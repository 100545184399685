import React, { useEffect, useMemo, useState } from 'react';
import styles from './addForexKeyBlock.module.scss';
import { IAddForexKeyBlock } from './addForexKeyBlock.types';
import { Button, Headline, Text } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';
import { useTranslation } from 'react-i18next';
import { BusdIcon, ForlyTokenIcon, PlusIcon } from 'forly-kit/dist/icons';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';
import { formatNumber } from '../../helpers/formatNumber';
import { IBalance } from 'types/balance.types';
import { IBuyParams } from '../Modals/ActivationModals/activationModals.types';
import { BuyConfirmModal } from '../Modals/ActivationModals/BuyConfirmModal';
import { EBalanceSymbol } from 'types/common.types';
import { activationKeysAPI } from 'services/activationKeysService';
import { bankAPI } from 'services/bankService';
import { AddForexKeyForm } from '../Forms/AddForexKeyForm/AddForexKeyForm';
import { userAPI } from 'services/userService';

type TStep = 'initial' | 'success' | 'instruction' | 'add' | 'successAdd';

const Component: React.FC<IAddForexKeyBlock> = ({
	totalKeys,
	cancelAddKey,
	Instruction,
	busdPrice,
	fccPrice,
	busdBalance,
	fccBalance,
	goToKeys,
	freeActivations
}) => {
	const {t, i18n} = useTranslation();
	const [step, setStep] = useState<TStep>('initial');
	const [buyParams, setBuyParams] = useState<IBuyParams | null>(null);

	const {data: profile} = userAPI.useFetchProfileQuery('');
	const [buyActivation, {isLoading: buyProcess, isSuccess: successBuy}] = activationKeysAPI.useBuyActivationMutation();
	const {refetch: refetchBalance} = bankAPI.useFetchBalanceQuery(null);

	useEffect(() => {
		if (successBuy) {
			setStep('success');
			refetchBalance();
		}
	}, [successBuy]);

	useEffect(() => {
		if (freeActivations !== 0) {
			setStep('instruction');
		}
	}, [freeActivations]);

	const enoughtFCCBalance = useMemo(() => {
		if (fccBalance) {
			return fccBalance.value_free >= fccPrice;
		}
		return false;
	}, [fccBalance, fccPrice]);

	const enoughtBUSDBalance = useMemo(() => {
		if (busdBalance) {
			return  busdBalance.value_free >= busdPrice;
		}
		return false;
	}, [busdBalance, busdPrice]);

	const generateBuyParams = (balance: IBalance, price: number) => {
		setBuyParams({
			price,
			balance
		});
	};

	const resetBuyParams = () => {
		setBuyParams(null);
	};

	const buyActivationRequest = (currency: EBalanceSymbol) => {
		setBuyParams(null);
		buyActivation({
			activation_id: 2,
			currency
		});
	};

	const goToInstruction = () => {
		setStep('instruction');
	};

	const closeInstruction = () => {
		setStep('add');
	};

	const successAddKey = () => {
		goToKeys();
	};

	return <div className='card cardContent'>
		{step === 'initial' && <>
			<div className={styles.buyBlock}>
				<Headline type='h2' color={colors.primary800}>
					{totalKeys ? t('forex.buyNewActivation') : t('forex.buyTitle')}
				</Headline>
				<div className={styles.noTrading}>
					<Text size='m'>
						{t('keys.activations.noTrading')}
					</Text>
					{/*<Text size='m'>*/}
					{/*	{t('forex.needTo')}*/}
					{/*</Text>*/}
					<Text size='m'>
						{t('keys.activations.noTradingStep1')}
					</Text>
					<Text size='m'>
						{t('forex.noTradingStep2')}
					</Text>
				</div>
				<div className={styles.priceBlock}>
					<Headline type='h4' color={colors.neutral600} className={styles.priceTitle}>
						{t('keys.activationPrice')}
					</Headline>
					<div className={cn(styles.valueItem, styles.fccPrice)}>
						<Headline type='h1'>
							≈ {fccPrice}
						</Headline>
						<ForlyTokenIcon className={styles.icon}/>
					</div>
					<div className={cn(styles.valueItem, styles.busdPrice)}>
						<Headline type='h1'>
							{busdPrice}
						</Headline>
						<BusdIcon className={styles.icon}/>
					</div>
					<div className={cn(styles.balanceItem, styles.fccBalance)}>
						<Headline type='h4' color={colors.neutral600}>
							{t('balance', {token: 'FCC'})}
						</Headline>
						<div className={styles.valueItem}>
							<Headline type='h1'>
								{formatNumber(fccBalance?.value_free || 0, 2)}
							</Headline>
							<ForlyTokenIcon className={styles.icon}/>
							{!enoughtFCCBalance &&
								<NavLink className={styles.link} to={`/${i18n.language}/refil/`}>
									<Button variant='tertiary' text={t('refillBalance')} icon={{component: PlusIcon}} />
								</NavLink>
							}
						</div>
					</div>

					<div className={cn(styles.balanceItem, styles.busdBalance)}>
						<Headline type='h4' color={colors.neutral600}>
							{t('balance', {token: 'BUSD'})}
						</Headline>
						<div className={styles.valueItem}>
							<Headline type='h1'>
								{formatNumber(busdBalance?.value_free || 0, 2)}
							</Headline>
							<ForlyTokenIcon className={styles.icon}/>
							{!enoughtBUSDBalance &&
								<NavLink className={styles.link} to={`/${i18n.language}/refil/`}>
									<Button variant='tertiary' text={t('refillBalance')} icon={{component: PlusIcon}} />
								</NavLink>
							}
						</div>
					</div>
				</div>
				<div className={styles.buyFooter}>
					<Button
						variant='primary'
						text={`${t('buyFrom')} FCC`}
						fill
						disabled={(!enoughtFCCBalance || buyProcess) && !profile?.white_list}
						onClick={fccBalance ? () => generateBuyParams(fccBalance, fccPrice) : undefined}
					/>
					<Button
						variant='primary'
						text={`${t('buyFrom')} BUSD`}
						fill
						disabled={(!enoughtBUSDBalance || buyProcess) && !profile?.white_list}
						onClick={busdBalance ? () => generateBuyParams(busdBalance, busdPrice) : undefined}
					/>
				</div>
			</div>
			{buyParams && <BuyConfirmModal
				{...buyParams}
				isOpen={!!buyParams}
				onClose={resetBuyParams}
				payRequest={buyActivationRequest}
			/>
			}
		</> }

		{step === 'success' && <div className={styles.successBlock}>
			<Headline type='h2' color={colors.primary800}>
				{t('keys.activations.successBuyActivationTitle')}
			</Headline>
			<Text size='m'>
				{t('forex.successBuySubtitle')}
			</Text>
			<Button className={styles.successBtn} variant='primary' text={t('start')} onClick={goToInstruction} />
		</div>}

		{step === 'instruction' && Instruction && <Instruction close={closeInstruction} />}

		{step === 'add' &&
			<AddForexKeyForm cancel={goToInstruction} success={successAddKey} />
		}

	</div>;
};

export const AddForexKeyBlock = React.memo(Component);
