import baseApi from './base';
import { IAuthResponse } from 'types/auth.types';

export const authApi = {
	auth: (username: string, password: string, captcha: string) => {
		return baseApi.post<IAuthResponse>('/auth/sign-in/', {
			username,
			password,
			captcha
		})
			.then((res) => {
				return res.data;
			});
	},
	logout: () => {
		return baseApi.post('/base_auth/logout/')
			.then(() => true );
	}
};
