export const setCookie = (name: string, value: string, exp?: number) => {
	let cookie = `${name}=${value};path=/;`;
	
	if (exp) {
		cookie += `expires=${new Date(exp).toUTCString()}`;
	} else {
		cookie += 'max-age=3600';
	}
	
	document.cookie = cookie;
};

export const getCookie = (name: string) => {
	const matches = document.cookie.match(new RegExp(
		// eslint-disable-next-line max-len
		'(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
	));
	return matches ? decodeURIComponent(matches[1]) : undefined;
};
