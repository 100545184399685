import React, { useEffect, useMemo, useState } from 'react';
import styles from './profile.module.scss';
import cn from 'classnames';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { Button, Headline, Text } from 'forly-kit';
import { ProfileEditForm } from '../../components/Forms/ProfileEditForm/ProfileEditForm';
import { userAPI } from 'services/userService';
import { Loader } from '../../components/Loader/Loader';

// @ts-ignore
import avatar from 'src/assets/img/fakeAvatar.jpg';
import phone_icon from 'src/assets/img/common/phone_green.svg';
import telegram_icon from 'src/assets/img/common/telegram_logo.svg';
import forly_logo from 'src/assets/img/common/forly_logo_profile.svg';
import spot_logo from 'src/assets/img/common/spot_logo.svg';
import future_logo from 'src/assets/img/common/futures_logo.svg';

import { useTranslation } from 'react-i18next';
import { CameraIcon, CheckIcon, EditIcon, FileIcon, WarningIcon } from 'forly-kit/dist/icons';
import { colors } from 'forly-kit/dist/colors';
import { baseAuthAPI } from 'services/baseAuthService';
import { ConfirmContactForm } from '../../components/Forms/ConfirmContactForm/ConfirmContactForm';
import { bankAPI } from 'services/bankService';
import { activationKeysAPI } from 'services/activationKeysService';

const Page: React.FC = () => {
	const {t, i18n} = useTranslation();
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [confirmContact, setConfirmContact] = useState<'' | 'phone' | 'telegram'>('');
	const [isSendCode, setIsSendCode] = useState<'' | 'phone' | 'telegram'>('');
	const [showErrorSendCode, setShowErrorSendCode] = useState<boolean>(false);

	const [changeProfile] = userAPI.useChangeProfileMutation();
	const {data: profile, isLoading: profilePending} = userAPI.useFetchProfileQuery('');
	const {data: user} = baseAuthAPI.useFetchUserQuery(null);
	const [sendCode, {isSuccess: successCodeSend, isError: sendCodeError}] = userAPI.useSendCodeMutation();
	const {data: holds, isLoading: holdsPending} = bankAPI.useFetchHoldsQuery(null);
	const {
		data: activationCount,
		isLoading: activationCountLoading
	} = activationKeysAPI.useFetchActivationCountQuery(null);

	useEffect(() => {
		if (successCodeSend) {
			setIsSendCode(confirmContact);
		}
	}, [successCodeSend]);

	useEffect(() => {
		if (sendCodeError) {
			setConfirmContact('');
			setShowErrorSendCode(true);
		}
	}, [sendCodeError]);

	const spotActivations = useMemo(() => {
		return activationCount?.CRP.total || 0;
	}, [activationCount]);

	const futuresActivations = useMemo(() => {
		return activationCount?.FTR.total || 0;
	}, [activationCount]);

	const birthday: string = useMemo(() => {
		if (!profile || !profile.birthday) {
			return t('noInputed');
		}

		const [year, month, day] = profile.birthday.split('-');
		return [day, month, year].join('/');
	}, [profile, i18n.language]);

	const changeImage = (value: any) => {
		const file = value.currentTarget.files[0];
		const formData = new FormData();
		formData.append('avatar', file);
		changeProfile(formData);
	};

	const counterLabel = (value: number, texts: string[]) => {
		if (value % 5 === 0) {
			return texts[1];
		}
		if ((value < 20 && value > 10) || value % 5 === 0 || value % 10 > 5) {
			return texts[1];
		}

		if (value % 10 === 1) {
			return texts[0];
		}

		return texts[2];
	};

	const switchToEdit = () => {
		setShowErrorSendCode(false);
		setIsEdit(true);
	};

	const closeEdit = () => {
		setIsEdit(false);
	};

	const switchToConfirm = (contact: 'phone' | 'telegram') => {
		setShowErrorSendCode(false);
		setConfirmContact(contact);
		sendCode({
			type: contact
		});
	};

	const cancelConfirm = () => {
		setConfirmContact('');
		setIsSendCode('');
	};

	if (profilePending && !profile) {
		return <Loader />;
	}

	return <div className='contentWrapper'>
		<div className='content'>
			<div className={cn('card cardContent', {[styles.profileWrapper]: !isEdit && !isSendCode })}>
				{
					!isEdit && !isSendCode && <>
						<div>
							<img className={styles.avatar} src={profile?.avatar || avatar} alt="" />
							<label className={styles.label} htmlFor='avatar'>
								<CameraIcon />
								<Text size='button' color={colors.primary700}>
									{t('changePhoto')}
								</Text>
								{/*<Button variant='tertiary' icon={{component: CameraIcon}} text={t('changePhoto')} />*/}
							</label>
							<input
								className={styles.avatarInput}
								name='avatar'
								id='avatar'
								type='file'
								accept="image/*"
								onChange={changeImage}
							/>
						</div>
						<div>
							<Headline type='h2' className={styles.name}>
								{profile?.surname || ''} {profile?.name || ''} {profile?.middle_name || ''}
							</Headline>
							<div className={styles.contactsInfo}>
								<Headline type='h4' color={colors.neutral500}>
									{t('email')}:
								</Headline>
								<Headline type='h4' color={colors.primary800}>
									{user?.email || t('noInputed')}
								</Headline>
								<span></span>

								<Headline
									className={styles.contactWithIcon}
									type='h4'
									color={colors.neutral500}
								>
									{profile && (!profile.phone_checked
										? <WarningIcon className={styles.icon} color={colors.danger} />
										: <CheckIcon className={styles.icon} color={colors.success} />)}
									{t('phone')}:
								</Headline>
								<Headline type='h4' color={colors.primary800}>
                                    {profile?.phone ? `+${profile?.phone}` : t('noInputed')}
								</Headline>
								{!profile?.phone_checked
									? <Button variant='tertiary' text={t('confirm')} onClick={() => switchToConfirm('phone')} />
									: <span></span>
								}
								<Headline
									className={styles.contactWithIcon}
									type='h4'
									color={colors.neutral500}
								>
									{profile &&
										(!profile.telegram_checked
											? <WarningIcon className={styles.icon} color={colors.danger} />
											: <CheckIcon className={styles.icon} color={colors.success} />)}
									Telegram:
								</Headline>
								<Headline type='h4' color={colors.primary800}>
									{profile?.telegram_id || t('noInputed')}
								</Headline>
								{!profile?.telegram_checked
									? <Button variant='tertiary' text={t('confirm')} onClick={() => switchToConfirm('telegram')} />
									: <span></span>
								}
								<Headline
									type='h4'
									color={colors.neutral500}
								>
									{t('birthday')}:
								</Headline>
								<Headline type='h4' color={colors.primary800}>
									{birthday || t('noInputed')}
								</Headline>
								<span></span>
							</div>
							<Button variant='secondary' text={t('edit')} icon={{component: EditIcon}} onClick={switchToEdit} />
							{showErrorSendCode &&
								<Text className={styles.errorSendCode} size='s' color={colors.danger}>
									{i18n.language === 'ru'
										? 'При отправке проверочного кода произошла ошибка. Обратитесь к вашему менеджеру'
										: 'An error occurred while sending the verification code. Contact your manager'
									}
								</Text>
							}
						</div>
					</>
				}
				{
					isEdit && !confirmContact && <ProfileEditForm editFinish={closeEdit}/>
				}
				{
					isSendCode && !isEdit && <ConfirmContactForm
						type={isSendCode}
						value={confirmContact === 'phone' ? profile?.phone || '' : profile?.telegram_id || ''}
						cancelConfirm={cancelConfirm}
					/>
				}
			</div>

		</div>
		<Sidebar>
			<div className={styles.sidebarWrapper}>
				{/*<div className={styles.managerBlock}>*/}
				{/*	<Headline type='h4'>*/}
				{/*		{t('personalManager')}*/}
				{/*	</Headline>*/}
				{/*	<div className={cn(styles.managerCard, styles.sidebarCard)}>*/}
				{/*		<img className={styles.photo} src={avatar} width={48} height={48} alt='' />*/}
				{/*		<div className={styles.managerInfo}>*/}
				{/*			<Headline type='h4'>*/}
				{/*				Анастасия Залесская*/}
				{/*			</Headline>*/}
				{/*			<div className={styles.managerInfoContact}>*/}
				{/*				<img src={phone_icon} alt='' />*/}
				{/*				<Text size='m'>*/}
				{/*					+7 930 456-56-89*/}
				{/*				</Text>*/}
				{/*			</div>*/}
				{/*			<div className={styles.managerInfoContact}>*/}
				{/*				<img src={telegram_icon} alt='' />*/}
				{/*				<Text size='m'>*/}
				{/*					@Bonnie84*/}
				{/*				</Text>*/}
				{/*			</div>*/}

				{/*		</div>*/}
				{/*	</div>*/}
				{/*</div>*/}
				<div className={styles.assetsBlock}>
					{(holdsPending || activationCountLoading) && <Loader internal />}
					<Headline type='h4'>
						{t('personalAssets')}
					</Headline>
					<div className={cn(styles.sidebarCard, styles.assetsCard)}>
						<img src={forly_logo} alt="" />
						<Headline type='h4'>
							{holds?.length || 0} {counterLabel(holds?.length || 0, [t('hold'), t('holdof'), t('holda')])}
						</Headline>
					</div>

					<div className={cn(styles.sidebarCard, styles.assetsCard)}>
						<img src={spot_logo} alt="" />
						<Headline type='h4'>
							{spotActivations || 0} {counterLabel(
								spotActivations,
								[t('activation'), t('activationiy'), t('activationii')])}
						</Headline>
					</div>

					<div className={cn(styles.sidebarCard, styles.assetsCard)}>
						<img src={future_logo} alt="" />
						<Headline type='h4'>
							{futuresActivations} {counterLabel(
								futuresActivations,
								[t('activation'), t('activationiy'), t('activationii')])}
						</Headline>
					</div>
				</div>
				{/*<div className={styles.instructionBlock}>*/}
				{/*	<Headline type='h4'>*/}
				{/*		{t('verificationTelegram')}*/}
				{/*	</Headline>*/}
				{/*	<Text className={styles.instructionLink} size='button' color={colors.primary700}>*/}
				{/*		<FileIcon color={colors.primary700} />*/}
				{/*		{t('confirmTelegramInstruction')}*/}
				{/*	</Text>*/}
				{/*</div>*/}
				<div className={styles.profileDesc}>
					<Headline type='h4'>
						{t('profileIs')}
					</Headline>
					<Text size='m'>
						{t('profileDesc')}
					</Text>
				</div>
			</div>

		</Sidebar>
	</div>;
};

export const ProfilePage = React.memo(Page);
