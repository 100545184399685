import React from 'react';
import styles from './modalSuccessExchange.module.scss';
import { IModalSuccessExchange } from './modalSuccessExchange.types';
import { Modal } from '../Modal';
import { Button, Headline, Text } from 'forly-kit';
import { useTranslation } from 'react-i18next';
import { tokensLabel } from 'src/helpers/common';

export const ModalSuccessExchange: React.FC<IModalSuccessExchange> = ({
	onClose,
	value,
	symbol,
	isOpen
}) => {
	const {t} = useTranslation();
	
	if (!isOpen) {
		return null;
	}
	
	return <Modal onClose={onClose}>
		<div className={styles.successExchangeWrapper}>
			<Headline type='h2' className={styles.title}>
				{t('exchangePage.success_exchange_title')}
			</Headline>
			<Text size='m' className={styles.subtitle}>
				{t('exchangePage.success_exchange_subtitle', {value, symbol})}
			</Text>
			<Button
				text={t('exchangePage.success_exchange_button')}
				variant='secondary'
				fill
				onClick={onClose}
			/>
		</div>
		
	</Modal>;
};
