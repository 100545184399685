// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".successModalWrapper--MnYmc{display:grid;grid-template-columns:24px 1fr;column-gap:4px;row-gap:8px;margin-bottom:32px;grid-template-areas:\"icon title\" \". description\"}.icon--UkSbj{grid-area:icon}.title--peSbR{grid-area:title}.desc--nocsD{grid-area:description}\n", "",{"version":3,"sources":["webpack://./../src/components/Modals/SuccessModal/successModal.module.scss"],"names":[],"mappings":"AAIA,4BACE,YAAa,CACb,8BAA+B,CAC/B,cAAe,CACf,WAAY,CACZ,kBAAmB,CACnB,gDAEe,CAChB,aAGC,cAAe,CAChB,cAGC,eAAgB,CACjB,aAGC,qBAAsB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.successModalWrapper {\n  display: grid;\n  grid-template-columns: 24px 1fr;\n  column-gap: 4px;\n  row-gap: 8px;\n  margin-bottom: 32px;\n  grid-template-areas:\n  \"icon title\"\n  \". description\";\n}\n\n.icon {\n  grid-area: icon;\n}\n\n.title {\n  grid-area: title;\n}\n\n.desc {\n  grid-area: description;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"successModalWrapper": "successModalWrapper--MnYmc",
	"icon": "icon--UkSbj",
	"title": "title--peSbR",
	"desc": "desc--nocsD"
};
export default ___CSS_LOADER_EXPORT___;
