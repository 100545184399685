import React from 'react';
import styles from './addKeyForm.module.scss';
import { IAddKeyForm, IAddKeyFormData } from './addKeyForm.types';
import { useForm } from 'react-hook-form';
import { Button, Input } from 'forly-kit';
import { useTranslation } from 'react-i18next';

const Form: React.FC<IAddKeyForm> = ({
	addKey,
	cancel
}) => {
	const {
		register,
		formState: { errors },
		handleSubmit
	} = useForm<IAddKeyFormData>({
		mode: 'onChange',
	});

	const {t} = useTranslation();

	return <form className={styles.formWrapper} onSubmit={handleSubmit(addKey)}>
		<Input
			label={t('keys.fields.nameKey')}
			desc={t('keys.fields.nameKeyDesc')}
			register={() =>
				register('name', {
					required: {
						value: true,
						message: t('errors.requiredField')
					},
					maxLength: {
						value: 30,
						message: t('errors.maxLengthString', {length: 30})
					}
				})
			}
			errorMsg={errors.name?.message || ''}
			status={errors.name ? 'error' : null}
		/>
		<Input
			label={t('keys.fields.public')}
			desc={t('keys.fields.publicDesc')}
			register={() =>
				register('publicKey', {
					required: 'required',
				})
			}
			errorMsg={errors.publicKey ? `${t('errors.requiredField')}` : ''}
			status={errors.publicKey ? 'error' : null}
		/>
		<Input
			label={t('keys.fields.secret')}
			desc={t('keys.fields.secretDesc')}
			register={() =>
				register('secretKey', {
					required: 'required',
				})
			}
			errorMsg={errors.secretKey ? `${t('errors.requiredField')}` : ''}
			status={errors.secretKey ? 'error' : null}
		/>
		<div className={styles.footer}>
			<Button type='submit' text={t('keys.addKey')} variant='primary' />
			<Button type='button' text={t('cancelAction')} variant='secondary' onClick={cancel} />
		</div>
	</form>;
};

export const AddKeyForm = React.memo(Form);
