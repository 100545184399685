import React from 'react';
import styles from './documents.module.scss';
import { HeaderCommon } from '../../components/HeaderCommon/HeaderCommon';
import { Headline, Text } from 'forly-kit';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import logo from 'src/assets/img/logo_tm.svg';
import riskStatementRu from 'src/assets/docs/Forly_Risk_Statement_ru.pdf';
import riskStatementEn from 'src/assets/docs/Forly_Risk_Statement_en.pdf';
import termsEn from 'src/assets/docs/Forly_Terms_of_use_of_the_platform_en.pdf';
import termsRu from 'src/assets/docs/Forly_Terms_of_use_of_the_platform_ru.pdf';
import privacyRu from 'src/assets/docs/Privacy_Policy_Forly_Capital_ru.pdf';
import privacyEn from 'src/assets/docs/Privacy_Policy_Forly_Capital_en.pdf';
import wpEn from 'src/assets/docs/wp_en.pdf';
import { CircleArrowIcon } from 'forly-kit/dist/icons';

export const Documents: React.FC = () => {
	const {t, i18n} = useTranslation();

	return <div className={styles.wrapper}>
		<HeaderCommon />
		<div className={styles.content}>
			<div className={styles.contacts}>
				<div>
					<Headline type='h2' className={styles.contactTitle}>
						FORLY US LP
					</Headline>
					<Text size='m'>
						<a className="value"
							 href="https://www.sec.gov/Archives/edgar/data/0001907174/000190717422000001/xslFormDX01/primary_doc.xml"
							 target="_blank" rel="noreferrer">
							{t('documentsPage.regulated')}
						</a>
					</Text>
				</div>

				<div>
					<Headline type='h2' className={styles.contactTitle}>
						{t('documentsPage.address')}
					</Headline>
					<Text size='m'>
						16192 Coastal Hwy, Lewes, DE 19958
					</Text>
				</div>

				<div>
					<Headline type='h2' className={styles.contactTitle}>
						{t('phone')}
					</Headline>
					<Text size='m'>
						<a href="tel:+78005513369" className={styles.noUnderline}>
							+7 (800) 551-33-69
						</a>
					</Text>
				</div>

				<div>
					<Headline type='h2' className={styles.contactTitle}>
						E-mail
					</Headline>
					<Text size='m'>
						<a href="mailto:info@forly.capital" className={styles.noUnderline}>
							info@forly.capital
						</a>
					</Text>
				</div>

			</div>

			<div className={styles.documents}>
				<a
					className={cn('card cardContent', styles.link)}
					href={i18n.language === 'ru' ? privacyRu : privacyEn}
					target='_blank' rel="noreferrer"
				>
					<Headline type='h2'>
						{t('documentsPage.privacyPolicy')}
					</Headline>
					<CircleArrowIcon />
				</a>

				<a
					className={cn('card cardContent', styles.link)}
					href={i18n.language === 'ru' ? termsRu : termsEn}
					target='_blank' rel="noreferrer"
				>
					<Headline type='h2'>
						{t('documentsPage.terms')}
					</Headline>
					<CircleArrowIcon />
				</a>

				<a
					className={cn('card cardContent', styles.link)}
					href={i18n.language === 'ru' ? riskStatementRu : riskStatementEn}
					target='_blank' rel="noreferrer"
				>
					<Headline type='h2'>
						{t('documentsPage.risk')}
					</Headline>
					<CircleArrowIcon />
				</a>

				<a
					className={cn('card cardContent', styles.link)}
					href={logo}
					target='_blank' rel="noreferrer"
				>
					<Headline type='h2'>
						{t('documentsPage.logo')}
					</Headline>
					<CircleArrowIcon />
				</a>

				<a
					className={cn('card cardContent', styles.link)}
					href={wpEn}
					target='_blank' rel="noreferrer"
				>
					<Headline type='h2'>
						{t('documentsPage.wp')}
					</Headline>
					<CircleArrowIcon />
				</a>

			</div>
		</div>
	</div>;
};
