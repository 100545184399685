// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--m55WY{display:grid;grid-template-columns:1fr;row-gap:48px}.managementKeys--O1srs{display:grid;grid-template-columns:1fr;justify-content:center;row-gap:8px;position:relative;margin-top:24px}.managementKeys--O1srs .counter--rorw_{display:grid;grid-template-columns:auto auto;column-gap:16px;justify-content:center}\n", "",{"version":3,"sources":["webpack://./../src/pages/TradingSpot/TradingSpot.module.scss"],"names":[],"mappings":"AAIA,gBACC,YAAa,CACb,yBAA0B,CAC1B,YAAa,CACb,uBAGA,YAAa,CACb,yBAA0B,CAC1B,sBAAuB,CACvB,WAAY,CACZ,iBAAkB,CAClB,eAAgB,CANjB,uCASE,YAAa,CACb,+BAAgC,CAChC,eAAgB,CAChB,sBAAuB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.wrapper {\n\tdisplay: grid;\n\tgrid-template-columns: 1fr;\n\trow-gap: 48px;\n}\n\n.managementKeys {\n\tdisplay: grid;\n\tgrid-template-columns: 1fr;\n\tjustify-content: center;\n\trow-gap: 8px;\n\tposition: relative;\n\tmargin-top: 24px;\n\n\t.counter {\n\t\tdisplay: grid;\n\t\tgrid-template-columns: auto auto;\n\t\tcolumn-gap: 16px;\n\t\tjustify-content: center;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--m55WY",
	"managementKeys": "managementKeys--O1srs",
	"counter": "counter--rorw_"
};
export default ___CSS_LOADER_EXPORT___;
