import React, { useState } from 'react';
import { IRefLinksForm } from './refLinksForm.types';
import { forexKeysAPI } from 'services/forexKeysService';
import { useForm } from 'react-hook-form';
import { IExchangeFormFields } from '../ExchangeForm/ExchangeForm.types';
import { IRefLinks } from 'types/partners.types';
import { Button, Input } from 'forly-kit';
import { binanceReg, forexReg, traderReg } from '../../../helpers/common';
import { Loader } from '../../Loader/Loader';
import { CheckIcon } from 'forly-kit/dist/icons';
import { IUpdateModalParams } from '../../Modals/ConfirmUpdateRefLinksModal/confirmUpdateRefLinks.types';
import { ConfirmUpdateRefLinksModal } from '../../Modals/ConfirmUpdateRefLinksModal/ConfirmUpdateRefLinksModal';
import { useTranslation } from 'react-i18next';

const Form: React.FC<IRefLinksForm> = ({
	type
}) => {
	const {t} = useTranslation();
	const {data: links, isLoading: linksPending} = forexKeysAPI.useFetchRefLinksQuery(null);

	const [updateModalParams, setUpdateModalParams] = useState<IUpdateModalParams | null>(null);

	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm<IRefLinks>({
		mode: 'onChange',
	});

	const updateLinksHandler = (data: IRefLinks) => {
		console.log(links);
		if (!links) {
			setUpdateModalParams({
				id: null,
				link: data.link,
				binanceLink: data.link_binance,
				roboLink: data.link_robo,
				type
			});
			return;
		}
		// const body: any = {id: links.id};
		setUpdateModalParams({
			id: links.id,
			link: data.link,
			binanceLink: data.link_binance,
			roboLink: data.link_robo,
			type
		});
	};

	const closeModal = () => {
		setUpdateModalParams(null);
	};

	if (linksPending) {
		return <Loader />;
	}

	return <>
		<form>
			{type === 'binance' &&
				<Input
					label=''
					register={() =>
						register('link_binance', {
							pattern: binanceReg,
							value: links?.link_binance || '',
						})
					}
					errorMsg={errors.link_binance?.message || ''}
					status={errors.link_binance ? 'error' : null}
				/>
			}

			{type === 'robo' && <>
				<Input
					label={t('partners.linkToForex')}
					register={() =>
						register('link_robo', {
							required: {
								value: false,
								message: t('errors.requiredField')
							},
							pattern: forexReg,
							value: links?.link_robo || '',
						})
					}
					errorMsg={errors.link_robo?.message || ''}
					status={errors.link_robo ? 'error' : null}
				/>

				<Input
					label={t('partners.linkToTraiderForex')}
					register={() =>
						register('link', {
							required: {
								value: false,
								message: t('errors.requiredField')
							},
							pattern: traderReg,
							value: links?.link || '',
						})
					}
					errorMsg={errors.link?.message || ''}
					status={errors.link ? 'error' : null}
				/>
			</>
			}
			<Button onClick={handleSubmit(updateLinksHandler)} variant='primary' text={t('saveLink')} icon={{component: CheckIcon}} fill />
		</form>

		{updateModalParams &&
			<ConfirmUpdateRefLinksModal params={updateModalParams} isOpen={!!updateModalParams} onClose={closeModal} />}

	</>;
};

export const RefLinksForm = React.memo(Form);
