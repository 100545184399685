import React from 'react';
import styles from './spotInstruction.module.scss';
import { Warning } from '../../Warning/Warning';
import step1img from 'src/assets/img/instruction/spot/1.jpg';
import step2img from 'src/assets/img/instruction/spot/2.jpg';
import step3img from 'src/assets/img/instruction/spot/3.jpg';
import step4_1img from 'src/assets/img/instruction/spot/4-1.jpg';
import step4_2img from 'src/assets/img/instruction/spot/4-2.jpg';
import step5_1img from 'src/assets/img/instruction/spot/5-1.jpg';
import step5_2img from 'src/assets/img/instruction/spot/5-2.jpg';
import step6img from 'src/assets/img/instruction/spot/6.jpg';
import step7img from 'src/assets/img/instruction/spot/7.jpg';
import step8img from 'src/assets/img/instruction/spot/8.jpg';
import step9img from 'src/assets/img/instruction/spot/9.jpg';
import step10img from 'src/assets/img/instruction/spot/10.jpg';
import step11img from 'src/assets/img/instruction/spot/11.jpg';
import step12img from 'src/assets/img/instruction/spot/12.jpg';
import step13img from 'src/assets/img/instruction/spot/13.jpg';
import step14img from 'src/assets/img/instruction/spot/14.jpg';
import step15img from 'src/assets/img/instruction/spot/15.jpg';
import step16_img from 'src/assets/img/instruction/spot/16-1.jpg';
import step16_2img from 'src/assets/img/instruction/spot/16-2.jpg';
import step17img from 'src/assets/img/instruction/spot/17.jpg';
import step18img from 'src/assets/img/instruction/spot/18.jpg';
import step20_1img from 'src/assets/img/instruction/spot/20-1.jpg';
import step20_2img from 'src/assets/img/instruction/spot/20-2.jpg';
import step21img from 'src/assets/img/instruction/spot/21.jpg';
import step22img from 'src/assets/img/instruction/spot/22.jpg';
import { Headline, Text } from 'forly-kit';
import { useTranslation } from 'react-i18next';
import { StringMap, TOptions } from 'i18next';
import { ShieldIcon } from 'forly-kit/dist/icons';

export const SpotInstruction: React.FC = () => {
	const {t} = useTranslation();
	const keyBasis = 'instructions.spot.';
	const getKey = (key: string, options?:  TOptions<StringMap>): string => {
		return t(keyBasis+key, options);
	};

	return <div className={styles.wrapper}>
		<Headline type='h2' className={styles.title}>
			{getKey('title')}
		</Headline>
		<ul className={styles.stepList}>
			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>1.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step1.text1')}
						<Text size='button'>
							<a href="https://www.binance.com/ru" target='_blank' rel="noreferrer">
								https://www.binance.com/ru
							</a>
						</Text>
					</Text>
					<Text size='m' className={styles.mt16}>
						{getKey('step1.text2')}
					</Text>
					<img className={styles.mt32} src={step1img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>2.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step2.text1')}
					</Text>
					<Warning
						className={styles.mt32}
						title={t('attention')}
						text={getKey('step2.warning')}
						theme='DARK'
					/>
					<img className={styles.mt32} src={step2img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>3.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step3.text1')}
					</Text>
					<img className={styles.mt32} src={step3img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>4.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step4.text1')}
					</Text>
					<img className={styles.mt32} src={step4_1img} alt=''/>
					<img className={styles.mt16} src={step4_2img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>5.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step5.text1')}
					</Text>
					<img className={styles.mt32} src={step5_1img} alt=''/>
					<Text size='m' className={styles.mt32}>
						{getKey('step5.text2')}
					</Text>
					<img className={styles.mt16} src={step5_2img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' className={styles.stepTitle}>
					{getKey('step6.title')}
				</Headline>
				<Headline type='h4' color='#7D829A'>6.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step5.text1')}
					</Text>
					<img className={styles.mt32} src={step6img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>7.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step7.text1')}
					</Text>
					<Warning
						className={styles.mt32}
						title={t('attention')}
						text={getKey('step7.warning')}
						theme='DARK'
					/>
					<img className={styles.mt32} src={step7img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>8.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step8.text1')}
					</Text>
					<img className={styles.mt32} src={step8img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>9.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step9.text1')}
					</Text>
					<img className={styles.mt32} src={step9img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>10.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step10.text1')}
					</Text>
					<img className={styles.mt32} src={step10img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>11.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step11.text1')}
					</Text>
					<Text size='m' className={styles.mt32}>
						{getKey('step11.text2')}
					</Text>
					<img className={styles.mt32} src={step11img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>12.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step12.text1')}
					</Text>
					<Text size='m' className={styles.mt32}>
						{getKey('step12.text2')}
					</Text>
					<Text size='m' className={styles.mt32}>
						{getKey('step12.text3')}
					</Text>
					<img className={styles.mt32} src={step12img} alt=''/>
					<Warning
						className={styles.mt32}
						title={t('attention')}
						text={getKey('step12.warning', {returnObjects: true})}
					/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>13.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step13.text1')}
					</Text>
					<Text size='m' className={styles.mt32}>
						{getKey('step13.text2')}
					</Text>
					<img className={styles.mt32} src={step13img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>14.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step14.text1')}
						<Text size='m'>
							{getKey('step14.text2')}
						</Text>
					</Text>
					<img className={styles.mt32} src={step14img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A' className={styles.stepTitle}>
					{getKey('step15.title')}
				</Headline>
				<Headline type='h4'>15.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step15.text1')}
					</Text>
					<Warning
						className={styles.mt16}
						title={t('securityRemain')}
						text={getKey('step15.warning')}
						Icon={ShieldIcon}
					/>
					<img className={styles.mt32} src={step15img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>16.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step16.text1')}
					</Text>
					<Warning
						className={styles.mt16}
						title={t('important')}
						text={getKey('step16.warning')}
						Icon={ShieldIcon}
					/>
					<img className={styles.mt32} src={step16_img} alt=''/>
					<img className={styles.mt32} src={step16_2img} alt=''/>
					<Warning
						className={styles.mt32}
						title={t('important')}
						text={getKey('step16.warning2', {returnObjects: true})}
						theme='DARK'
					/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>17.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step17.text1')}
					</Text>
					<img className={styles.mt32} src={step17img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>18.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step18.text1')}
						<Text size='button'>
							<a href="https://passport.forly.finance/" target='_blank' rel="noreferrer">
								https://passport.forly.finance/
							</a>
						</Text>
					</Text>
					<Text size='m' className={styles.mt16}>
						{getKey('step18.text2')}
					</Text>
					<img className={styles.mt32} src={step18img} alt=''/>
					<Warning
						className={styles.mt32}
						title={t('attention')}
						text={getKey('step18.warning')}
						Icon={ShieldIcon}
					/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>19.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step19.text1')}
					</Text>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>20.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step20.text1')}
					</Text>
					<img className={styles.mt32} src={step20_1img} alt=''/>
					<img className={styles.mt32} src={step20_2img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>21.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step21.text1')}
						<Text size='m' className={styles.multiline}>
							{getKey('step21.text2')}
						</Text>
					</Text>
					<img className={styles.mt32} src={step21img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>22.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step22.text1')}
					</Text>
					<img className={styles.mt32} src={step22img} alt=''/>
				</div>
			</li>

			<li className={styles.step}>
				<Headline type='h4' color='#7D829A'>23.</Headline>
				<div>
					<Text size='m' className={styles.multiline}>
						{getKey('step23.text1')}
					</Text>
					<Text size='m' className={styles.mt16}>
						{getKey('step23.text2')}
					</Text>
				</div>
			</li>
		</ul>
	</div>;
};
