import { Headline, Text } from 'forly-kit';
import { 
	FuturesIcon, 
	SpotIcon, 
	RoboforexMenuItemIcon, 
	ForlyTokenIcon, 
	BusdIcon 
} from 'forly-kit/dist/icons';
import React from 'react';
import styles from './packageActivationBlock.module.scss';
import { IPackageActivationBlock } from './packageActivationBlock.types';
import { colors } from 'forly-kit/dist/colors';
import { useTranslation } from 'react-i18next';

const Component: React.FC<IPackageActivationBlock> = ({
	fccPrice,
	busdPrice,
	action
}) => {
	const {t} = useTranslation();

	return <div className={styles.wrapper}>
		<Headline className={styles.title} type='h3' color={colors.white}>
			{t('packageActivationTitle')}
		</Headline>
		<div className={styles.logoList}>
			<div className={styles.logo}>
				<SpotIcon color='#8bbde4'/>
				<Text size='m' color='#8bbde4'>
                Spot
				</Text>
			</div>

			<div className={styles.logo}>
				<FuturesIcon color='#8bbde4'/>
				<Text size='m' color='#8bbde4'>
                Futures
				</Text>
			</div>

			<div className={styles.logo}>
				<RoboforexMenuItemIcon color='#8bbde4'/>
				<Text size='m' color='#8bbde4'>
                RoboForex
				</Text>
			</div>
		</div>
		
		<div className={styles.priceBlock}>
			<div className={styles.price}>
				<Headline type='h2' color={colors.white}>
                ≈ {fccPrice}
				</Headline>
				<div className={styles.circle}>
					<ForlyTokenIcon className={styles.icon} />
				</div>
			
			</div>
			<div className={styles.price}>
				<Headline type='h2' color={colors.white}>
					{busdPrice}
				</Headline>
				<div className={styles.circle}>
					<BusdIcon className={styles.icon} />
				</div>
			</div>
		</div>
		
		<button className={styles.button} onClick={action}>
			<Text size='m' color={colors.primary700} >
				{t('buy')}
			</Text>
		</button>
	</div>;
};

export const PackageActivationBlock = React.memo(Component);