import React from 'react';
import styles from './periodSwitcher.module.scss';
import cn from 'classnames';
import { IPeriodSwitcher } from './periodSwitcher.types';
import { Headline } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';

const Switcher: React.FC<IPeriodSwitcher> = ({
	data,
	onChange,
	activePeriod
}) => {
	
	return <div className={styles.switcher}>
		{data.map((d) => {
			return <div
				key={d.value}
				className={cn(styles.switcherItem, {
					[styles.active]: d.value === activePeriod,
				})}
				onClick={() => onChange(d.value)}
			>
				<Headline type='h4' color={d.value === activePeriod ? colors.white : colors.primary900}>
					{d.label}
				</Headline>
			</div>;
		})}
	</div>;
};

export const PeriodSwitcher = React.memo(Switcher);
