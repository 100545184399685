import React, { useEffect, useState } from 'react';
import styles from './createPartnerProgram.module.scss';
import { Button, Headline, Text } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';
import cn from 'classnames';
import { ICreatePartnerProgramFormData } from '../Forms/CreatePartnerProgramForm/createPartnerProgramForm.types';
import { CreatePartnerProgramForm } from '../Forms/CreatePartnerProgramForm/CreatePartnerProgramForm';
import { partnersAPI } from 'services/partnersService';
import { ICreatePartnerProgram } from './createPartnerProgram.types';
import { Loader } from '../Loader/Loader';
import { useTranslation } from 'react-i18next';
import { tr } from 'date-fns/locale';

const Component: React.FC<ICreatePartnerProgram> = ({
	successCreate
}) => {
	const {t} = useTranslation();
	const [changeRefLink, {isLoading, isSuccess}] = partnersAPI.useChangeRefLinkMutation();

	const [creating, setCreating] = useState<boolean>(false);

	const startCreatingProgram = () => {
		setCreating(true);
	};

	const createProgram = (data: ICreatePartnerProgramFormData) => {
		changeRefLink(data.subdomain);
	};

	if (isLoading) {
		return <Loader />;
	}

	return <div className={cn('card cardContent', {[styles.wrapper]: !creating || isSuccess})}>
		{!creating && !isSuccess && <>
			<Headline type='h2' className={styles.title}>
				{t('noLeaderStatus')}
			</Headline>
			<Text size='m' color={colors.neutral500} className={styles.subtitle}>
				{t('noPartnerWeb')}
			</Text>
			<Button variant='primary' text={t('createPartnerWeb')} onClick={startCreatingProgram}/>
		</>}

		{creating && !isSuccess && <>
			<Headline type='h2' className={styles.title}>
				{t('inputYourSubdomain')}
			</Headline>
			<Text size='m' color={colors.neutral500} className={styles.subtitle}>
				{t('ThisIsYourLink')}
			</Text>
			<CreatePartnerProgramForm create={createProgram} />
		</>}

		{isSuccess && <>
			<Headline type='h2' className={styles.title}>
				{t('subdomainSuccessSaved')}
			</Headline>
			<Text size='m' color={colors.neutral500} className={styles.subtitle}>
				{t('nowInputYourBinanceLinks')}
			</Text>
			<Button variant='primary' text={t('addPartnersLinks')} onClick={successCreate}/>
		</>
		}
	</div>;
};

export const CreatePartnerProgram = React.memo(Component);
