import { IAuthState } from 'stateTypes/authState';
import { getCookie } from '../../helpers/cookie';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: IAuthState = {
	token: getCookie('access') || ''
};

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setToken: (state, action: PayloadAction<string>) => {
			state.token = action.payload;
		}
	}
});

export const {setToken} = authSlice.actions;
export default authSlice.reducer;
