import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './key.module.scss';
import cn from 'classnames';
import { Button, Checkbox, Headline, Text } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';
import { DownArrowIcon, OptionIcon, PauseIcon, PlayIcon, WarningIcon } from 'forly-kit/dist/icons';
import { EKeyStatuses, IKeyProps } from './key.types';
import { format } from 'date-fns';
import { EBalanceSymbol } from 'types/common.types';
import { differenceInCalendarDays, formatDistanceStrict  } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { getDateFnsLocale } from '../../helpers/date';
import { formatNumber } from '../../helpers/formatNumber';

const KeyComponent: React.FC<IKeyProps> = ({
	id,
	keyStatus,
	autoPayCommission,
	name,
	expired,
	changeAutoPayCommission,
	publicKey,
	lastCheckCommission,
	payDate,
	usdtCommission,
	fccCommission,
	needUpdate = false,
	isActive = false,
	is_blocked = false,
	updateKey,
	autoExtension= false,
	changeAutoExtension,
	extendActivation,
	payCommission,
}) => {
	const {i18n, t} = useTranslation();
	const [active, setActive] = useState<boolean>(isActive);
	const [isShowOptions, setIsShowOptions] = useState<boolean>(false);
	const [contentHeight, setContentHeight] = useState<number>(0);

	const optionBtn = useRef(null);

	useEffect(() => {
		// @ts-ignore
		const onClick = (e:any) => optionBtn?.current?.contains(e.target) || setIsShowOptions(false);
		document.addEventListener('click', onClick);
		return () => document.removeEventListener('click', onClick);
	}, []);

	const colorKeyStatus = useMemo(() => {
		if (keyStatus?.status === EKeyStatuses.WORKING) {
			return colors.success;
		}
		return colors.danger;
	}, [keyStatus]);

	const formatPublicKey = useMemo(() => {
		const { length } = publicKey;
		return `${publicKey.slice(0, 8)}...${publicKey.slice(length-3)}`;
	}, [publicKey]);

	const showOptions = (e: React.MouseEvent<HTMLDivElement>) => {
		e.stopPropagation();
		setIsShowOptions((prev) => !prev);
	};

	const openCard = () => {
		const content = document.getElementById(`${id}-content`);
		if (content && active) {
			content.setAttribute('style', 'height: 0px; display: none;');
			setActive(false);
			return;
		}

		if (content && !active) {
			content.setAttribute('style', `height: ${contentHeight}px; display: block`);
			setActive(true);
			return;
		}
	};

	const expiredDifference = useMemo(() => {
		const date = new Date(expired);
		return ({
			date,
			expiredDaysCount: differenceInCalendarDays(date, new Date())
		});
	}, [expired]);

	const expiredStatus = useMemo(() => {
		const { date, expiredDaysCount } = expiredDifference;
		if (expiredDaysCount > 7) {
			return {
				text: t('keys.activations.activationPeriod', {date: format(date, 'dd.MM.yyyy')}),
				color: colors.neutral500
			};
		}

		if (expiredDaysCount > 0) {
			return {
				text: t('keys.activations.activationPeriodSoon', {date: formatDistanceStrict(date, new Date(), {
					unit: 'day',
					locale: getDateFnsLocale()
				})}),
				color: colors.danger
			};
		}

		return {
			text: t('keys.activations.activationPeriodExpired'),
			color: colors.danger
		};
	}, [expiredDifference, i18n.language]);

	const blockedCommission = useMemo(() => {
		if (is_blocked) {
			return true;
		}
	}, [is_blocked, payDate]);

	useEffect(() => {
		const content = document.getElementById(`${id}-content`);
		if (content?.scrollHeight) {
			setContentHeight(content?.scrollHeight || 0);
		}

		if (content && active) {
			content.setAttribute('style', `height: ${contentHeight}px`);
		} else {
			content?.setAttribute('style', 'height: 0px; display: none');
		}
	}, [id, name, expired, isActive, keyStatus]);

	return <div id={`${id}-card`} className={styles.card}>
		<header className={styles.header} id={`${id}-spot`} onClick={openCard}>
			<DownArrowIcon className={cn(styles.arrowIcon, {
				[styles.revertIcon]: active
			})}/>
			<Headline
				type='h2'
				className={styles.name}
				color={needUpdate ? colors.danger : colors.primary800}
			>
				{name}
				{needUpdate && <div className={styles.warningIcon}>
					<WarningIcon color={colors.danger} className={styles.icon}/>
					<div className={styles.warning}>
						{t('errors.requireUpdateKey')}
					</div>
				</div>
				}
			</Headline>

			<Headline
				type='h4'
				className={cn(styles.status, {[styles.noShow]: !keyStatus})}
				color={colorKeyStatus}
			>
				{keyStatus?.statusText}
				{is_blocked && <div className={styles.warningIcon}>
					<WarningIcon color={colors.danger}/>
					<div className={styles.warning}>
						{t('errors.botIsBlocked')}
					</div>
				</div>
				}
			</Headline>
			<div className={cn(styles.options, {[styles.noShow]: !expired})} onClick={showOptions}>
				<OptionIcon color={colors.primary700} ref={optionBtn} />
				<div className={cn(styles.optionsBlock, {
					[styles.opened]: isShowOptions
				})}>
					<Checkbox name={`autopay-activation-${id}`} onChange={changeAutoExtension} checked={autoExtension}>
						<Text size='s' color={colors.primary900}>{t('keys.activations.autoExtensionFlag')}</Text>
					</Checkbox>
				</div>
			</div>
			<Headline type='h4' className={cn(styles.expired, {[styles.noShow]: !expired})} color={expiredStatus.color} >
				{expiredStatus.text}
			</Headline>
		</header>

		<div id={`${id}-content`} className={styles.content}>
			<div className='divider'></div>
			<div className={styles.actions}>
				{expiredDifference.expiredDaysCount <= 7 &&
					<div className={cn(styles.action, styles.extensionBtn)}>
						<Button
							className={cn(styles.actionBtn)}
							variant='primary'
							text={t('keys.activations.extensionActivation')}
							fill
							onClick={extendActivation}
						/>
						<Checkbox name={`autopay-activation-${id}`} onChange={changeAutoExtension} checked={autoExtension}>
							<Text size='s' color={colors.neutral500}>{t('keys.commissions.confirmAutoCommission')}</Text>
						</Checkbox>
					</div>
				}
				{expiredDifference.expiredDaysCount > 0 && needUpdate &&
					<div className={styles.action}>
						<Button  className={styles.actionBtn} variant='primary' text={t('change')} fill onClick={updateKey} />
					</div>
				}
				{keyStatus && (expiredDifference.expiredDaysCount > 0 || !expired) && <div className={styles.action}>
					<Button
						className={styles.actionBtn}
						variant={keyStatus.status === EKeyStatuses.STOPPED ? 'primary' : 'secondary'}
						text={keyStatus.actionText}
						icon={keyStatus.status === EKeyStatuses.STOPPED ? { component: PlayIcon } : { component: PauseIcon }}
						onClick={keyStatus.action}
						disabled={is_blocked}
						fill
					/>
					<Text className={styles.actionDesc} size='s' color={colors.neutral500}>
						{keyStatus.actionDescription}
					</Text>
				</div>
				}
				<div className={styles.action}>
					<Button
						className={styles.actionBtn}
						variant='secondary'
						text={t('keys.commissions.payCommission')}
						icon={{component: PauseIcon}}
						disabled={!payDate}
						fill
						onClick={payCommission}
					/>
					<Checkbox name={`autopay-${id}`} onChange={changeAutoPayCommission} checked={autoPayCommission}>
						<Text size='s' color={colors.neutral500}>{t('keys.commissions.confirmAutoCommission')}</Text>
					</Checkbox>
				</div>
			</div>

			<div className='divider'></div>

			<div className={styles.keyInfo}>
				<div className={cn(styles.keyInfoItem, styles.publicKey)}>
					<Headline type='h4' color={colors.neutral500}>{t('keys.fields.public')}</Headline>
					<Headline type='h4'>{formatPublicKey}</Headline>
				</div>
				<div className={cn(styles.keyInfoItem, styles.secret)}>
					<Headline type='h4' color={colors.neutral500}>{t('keys.fields.secret')}</Headline>
					<Headline type='h4'>*********</Headline>
				</div>
				<div className={cn(styles.keyInfoItem, styles.lastCheck)}>
					<Headline type='h4' color={colors.neutral500}>{t('keys.fields.dateLastCheck')}</Headline>
					{lastCheckCommission
						? <Headline type='h4'>{format(lastCheckCommission * 1000, 'dd.MM.yyyy')}</Headline>
						: <Headline type='h4'> - </Headline>
					}
				</div>
				<div className={cn(styles.keyInfoItem, styles.payBefore)}>
					<Headline type='h4' color={colors.neutral500}>{t('pay')}</Headline>
					{payDate
						? <Headline type='h4'>{t('before')} {format(payDate * 1000, 'dd.MM.yyyy')}</Headline>
						: <Headline type='h4'> - </Headline>
					}
				</div>
				<div className={cn(styles.keyInfoItem, styles.fccCommission)}>
					<Headline
						type='h4'
						color={colors.neutral500}
					>
						{t('keys.commissions.currentCommissionToken', {token: 'FCC'})}
					</Headline>
					<Headline type='h4'>
						{fccCommission
							? `≈ ${formatNumber(fccCommission, 2)} FCC`
							: ' - '
						}
					</Headline>
				</div>
				<div className={cn(styles.keyInfoItem, styles.usdtCommission)}>
					<Headline
						type='h4'
						color={colors.neutral500}
					>
						{t('keys.commissions.currentCommissionToken', {token: 'BUSD'})}
					</Headline>
					<Headline type='h4'>
						{usdtCommission
							? `≈ ${formatNumber(usdtCommission, 2)} ${EBalanceSymbol.BUSD}`
							: ' - '
						}
					</Headline>
				</div>
			</div>
		</div>
	</div>	;
};

export const Key = React.memo(KeyComponent);
