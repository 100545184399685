// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--ln594{max-width:716px}.header--c1jLN{margin-bottom:32px;display:grid;grid-template-columns:1fr;row-gap:8px}.content--x2N_o{margin-bottom:16px}.error--ZOQMj{margin-bottom:4px}.footer--u9Oig{display:grid;grid-template-columns:repeat(2, 1fr);column-gap:30px;align-items:center}\n", "",{"version":3,"sources":["webpack://./../src/components/Modals/Commissions/AutoCommissionConfirmModal/autoCommissionConfirmModal.module.scss"],"names":[],"mappings":"AAIA,gBACE,eAAgB,CACjB,eAGC,kBAAmB,CACnB,YAAa,CACb,yBAA0B,CAC1B,WAAY,CACb,gBAGC,kBAAmB,CACpB,cAGC,iBAAkB,CACnB,eAGC,YAAa,CACb,oCAAqC,CACrC,eAAgB,CAChB,kBAAmB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.wrapper {\n  max-width: 716px;\n}\n\n.header {\n  margin-bottom: 32px;\n  display: grid;\n  grid-template-columns: 1fr;\n  row-gap: 8px;\n}\n\n.content {\n  margin-bottom: 16px;\n}\n\n.error {\n  margin-bottom: 4px;\n}\n\n.footer {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  column-gap: 30px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--ln594",
	"header": "header--c1jLN",
	"content": "content--x2N_o",
	"error": "error--ZOQMj",
	"footer": "footer--u9Oig"
};
export default ___CSS_LOADER_EXPORT___;
