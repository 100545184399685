// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--mCUOZ{max-width:420px;margin:0 auto;display:grid;grid-template-columns:1fr;row-gap:32px}.title--XRFmY{text-align:center}.footer--NNokn{display:grid;grid-template-columns:repeat(2, 1fr);column-gap:16px;align-items:center}.confirmedWrapper--hdQM_{display:grid;grid-template-columns:1fr;align-items:center;row-gap:24px;justify-content:center;justify-items:center}.confirmedTitle--zY0Nz{display:grid;grid-template-columns:24px auto;column-gap:16px;align-items:center}\n", "",{"version":3,"sources":["webpack://./../src/components/Forms/ConfirmContactForm/confirm.contactForm.module.scss"],"names":[],"mappings":"AAIA,gBACE,eAAgB,CAChB,aAAc,CACd,YAAa,CACb,yBAA0B,CAC1B,YAAa,CACd,cAGC,iBAAkB,CACnB,eAGC,YAAa,CACb,oCAAqC,CACrC,eAAgB,CAChB,kBAAmB,CACpB,yBAGC,YAAa,CACb,yBAA0B,CAC1B,kBAAmB,CACnB,YAAa,CACb,sBAAuB,CACvB,oBAAqB,CACtB,uBAGC,YAAa,CACb,+BAAgC,CAChC,eAAgB,CAChB,kBAAmB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.wrapper {\n  max-width: 420px;\n  margin: 0 auto;\n  display: grid;\n  grid-template-columns: 1fr;\n  row-gap: 32px;\n}\n\n.title {\n  text-align: center;\n}\n\n.footer {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  column-gap: 16px;\n  align-items: center;\n}\n\n.confirmedWrapper {\n  display: grid;\n  grid-template-columns: 1fr;\n  align-items: center;\n  row-gap: 24px;\n  justify-content: center;\n  justify-items: center;\n}\n\n.confirmedTitle {\n  display: grid;\n  grid-template-columns: 24px auto;\n  column-gap: 16px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--mCUOZ",
	"title": "title--XRFmY",
	"footer": "footer--NNokn",
	"confirmedWrapper": "confirmedWrapper--hdQM_",
	"confirmedTitle": "confirmedTitle--zY0Nz"
};
export default ___CSS_LOADER_EXPORT___;
