export const emailPattern =
	// eslint-disable-next-line max-len,no-empty-character-class
	/([-!#-'*+/-9=?A-Z^-~]+(\.[-!#-'*+/-9=?A-Z^-~]+)*|"([]!#-[^-~ \t]|(\\[\t -~]))+")@[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?(\.[0-9A-Za-z]([0-9A-Za-z-]{0,61}[0-9A-Za-z])?)+/;

export const passwordPattern = /^(\d|[a-z]|[A-Z]){6,}$/;

export const numberRegExp = new RegExp(/^\d+\.?\d{0,2}$/, 'g');
const numberDecimalValue = /^\d+\.?(\d+)?$/;

export const changeNumberInput = (inputValue: number | string, zeroStart?: boolean): number | string => {
	const value: number | string = (inputValue + '').replace(',', '.');
	const length = value.length;

	if (!value.match(numberDecimalValue)) {
		return +value.slice(0, value.length-1);
	}
	
	if (length === 2 && value[length -1] === '.') {
		return value;
	}
	
	if (!zeroStart && value[0] === '0' && value[1] !== '.') {
		return value.slice(1);
	}
	
	if (!value || +value === 0) {
		return 0;
	}
	
	return value;
};
