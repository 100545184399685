import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { activationKeysAPI } from 'services/activationKeysService';
import { managementAPI } from 'services/managementService';
import { futuresAPI } from 'services/futuresService';
import { KeysPageWrapper } from '../../components/KeysPageWrapper/KeysPageWrapper';
import { IAddKeyFormData } from '../../components/Forms/AddKeyForm/addKeyForm.types';
import { spotAPI } from 'services/spotService';
import { ICommissionParams } from 'types/apiKeys.types';
import { PayCommissionModal } from '../../components/Modals/Commissions/PayCommissionModal/PayCommissionModal';
import { FutureKey } from './FutureKey/FutureKey';
import { Loader } from '../../components/Loader/Loader';
import { FuturesInstructionModal } from '../../components/Modals/FuturesInstructionModal/FuturesInstructionModal';

export const FuturesPage: React.FC = () => {
	const { data: lastFT, isLoading: lastFTLoading } =
		managementAPI.useFetchLastFTQuery(null);
	const {data: activations, isLoading: activationsLoading} = activationKeysAPI.useFetchActivationListQuery(null);
	const {
		data: activationCount,
		isLoading: activationCountLoading,
		refetch: refetchCountActivations
	} = activationKeysAPI.useFetchActivationCountQuery(null);
	const {
		data: futuresKeys = [], 
		isLoading: futuresKeysLoading, 
		refetch: refetchKeys
	} = futuresAPI.useFetchFuturesKeysQuery(null);
	const [addKey, {isSuccess: successAddKey}] = futuresAPI.useAddKeyMutation();
	const [
		payCommission,
		{isLoading: payCommissionProcess, isSuccess: payCommissionSuccess}
	] = futuresAPI.usePayCommissionMutation();

	const [payCommissionParams, setPayCommissionParams] = useState<ICommissionParams | null>(null);

	useEffect(() => {
		if (successAddKey) {
			refetchKeys();
		}
	}, [successAddKey]);

	const futuresActivations = useMemo(() => {
		return activationCount?.FTR || null;
	}, [activationCount]);

	const futuresBusdPrice = useMemo(() => {
		if (activations?.FTR) {
			return +activations.FTR.price;
		}
		return 0;
	}, [activations]);

	const futuresFccPrice = useMemo(() => {
		if (futuresBusdPrice && lastFT) {
			return futuresBusdPrice / lastFT;
		}
		return 0;
	}, [futuresBusdPrice, lastFT]);

	const openPayCommissionModal = (params: ICommissionParams) => {
		setPayCommissionParams(params);
	};

	const resetPayCommissionParams = useCallback(() => {
		setPayCommissionParams(null);
	}, []);

	if (futuresKeysLoading) {
		return <Loader />;
	}

	return <KeysPageWrapper
		type={activations?.FTR.id || 0}
		freeActivations={futuresActivations?.active || 0}
		usedActivations={futuresActivations?.used || 0}
		busdPrice={futuresBusdPrice}
		fccPrice={futuresFccPrice}
		successPay={refetchCountActivations}
		addKey={addKey}
		instruction={{
			Instruction: FuturesInstructionModal
		}}
	>
		<>
			{futuresKeys.map((f: any) => {
				return <FutureKey
					key={f.id}
					id={f.id}
					name={f.name}
					freeActivations={futuresActivations?.active || 0}
				/>;
			})}

			{payCommissionParams &&
				<PayCommissionModal
					{...payCommissionParams}
					isOpen={!!payCommissionParams}
					onClose={resetPayCommissionParams}
					payCommission={payCommission}
					isPending={payCommissionProcess}
					isSuccessPay={payCommissionSuccess}
				/>}
		</>
	</KeysPageWrapper>;
};
