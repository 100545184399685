// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".orderList--fIKjr{display:grid;grid-template-columns:1fr;row-gap:16px;position:relative}.header--F0I67{display:grid;grid-template-columns:repeat(2, auto);justify-content:space-between;align-items:center}\n", "",{"version":3,"sources":["webpack://./../src/pages/TradingFutures/FutureOrders/futureOrders.module.scss"],"names":[],"mappings":"AAIA,kBACC,YAAa,CACb,yBAA0B,CAC1B,YAAa,CACb,iBAAkB,CAClB,eAGA,YAAa,CACb,qCAAsC,CACtC,6BAA8B,CAC9B,kBAAmB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.orderList {\n\tdisplay: grid;\n\tgrid-template-columns: 1fr;\n\trow-gap: 16px;\n\tposition: relative;\n}\n\n.header {\n\tdisplay: grid;\n\tgrid-template-columns: repeat(2, auto);\n\tjustify-content: space-between;\n\talign-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"orderList": "orderList--fIKjr",
	"header": "header--F0I67"
};
export default ___CSS_LOADER_EXPORT___;
