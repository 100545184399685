import React, { useEffect, useMemo, useState } from 'react';
import styles from './exchangeForm.module.scss';
import { Button, Headline, Input } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';
import { formatNumber } from '../../../helpers/formatNumber';
import { useForm } from 'react-hook-form';
import { IExchangeForm, IExchangeFormFields } from './ExchangeForm.types';
import { useTranslation } from 'react-i18next';
import { EBalanceSymbol } from 'types/common.types';
import { managementAPI } from 'services/managementService';
import { bankAPI } from 'services/bankService';
import { ModalConfirmExchange } from '../../Modals';
import {
	ModalSuccessExchange
} from '../../Modals/SuccessExchange/ModalSuccessExchange';
import { changeNumberInput } from '../../../helpers/patterns';
import { tokensLabel } from '../../../helpers/common';

const Form: React.FC<IExchangeForm> = ({
	fromSymbol,
	maxValue,
	toSymbol
}) => {
	const {t} = useTranslation();
	const [changeValue, setChangeValue] = useState<number | string>(0);
	const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
	const [resultChangedValue, setResultChangedValue] = useState<number>(0);
	
	console.log('reload form');
	
	const { data: lastFT } =
		managementAPI.useFetchLastFTQuery(null);
	const [conversion, {data: exchangeRes , isLoading: conversionProcess }] =
		bankAPI.useConversionMutation();
	
	const {
		register,
		formState: { errors },
		handleSubmit,
		setValue,
		getValues,
		reset,
	} = useForm<IExchangeFormFields>({
		mode: 'onChange',
	});
	
	useEffect(() => {
		reset();
		setChangeValue(0);
	}, [fromSymbol]);
	
	useEffect(() => {
		setValue('value', changeValue);
	}, [changeValue]);
	
	useEffect(() => {
		setResultChangedValue(exchangeRes || 0);
	}, [exchangeRes]);
	
	const changeValueHandler = (e: any) => {
		const value = changeNumberInput(e.currentTarget.value);
		value === changeValue
			? setValue('value', value)
			: setChangeValue(value);
	};
	
	const resultCost = useMemo(() => {
		if (!lastFT) {
			return 0;
		}
		
		if (fromSymbol === EBalanceSymbol.USDT) {
			return +changeValue / lastFT;
		}
		
		return +changeValue * lastFT;
	}, [fromSymbol, lastFT, changeValue]);
	
	const conversionHandler = async (): Promise<void> => {
		const value = getValues('value');
		const count = typeof value === 'string' ? +value : value;
		await conversion({
			count,
			symbol: fromSymbol
		});
		setChangeValue(0);
		setValue('value', 0);
	};
	
	const modalHandler = (isOpen: boolean) => {
		setOpenConfirmModal(isOpen);
	};
	
	const closeSuccessModal = () => {
		setResultChangedValue(0);
	};
	
	
	return<>
		<form
			className={styles.form}
			onSubmit={handleSubmit(() => modalHandler(true))}
		>
			<Input
				label={`${t('count')} ${tokensLabel[fromSymbol]}`}
				register={() =>
					register('value', {
						required: 'required',
						min: {
							value: 0.1,
							message: t('errors.min_value', {value: 0.1})
						},
						max: {
							value: maxValue,
							message: t('errors.no_enough_balance')
						},
						pattern: /^[0-9.]+$/,
						onChange: changeValueHandler,
						value: changeValue,
					})
				}
				errorMsg={errors.value?.message || ''}
				status={errors.value ? 'error' : null}
			/>
			<div className={styles.counterBlock}>
				<div className={styles.balance}>
					<Headline type="h4" color={colors.primary800}>
						{t('balance')}
					</Headline>
					<Headline type="h3" color={colors.primary800}>
						{formatNumber(maxValue)}{' '}
						{tokensLabel[fromSymbol]}
					</Headline>
				</div>
				<div className={styles.counter}>
					<Headline type="h4" color={colors.primary800}>
						{t('approximate_cost')}
					</Headline>
					<Headline type="h3" color={colors.primary800}>
						{formatNumber(resultCost)} {tokensLabel[toSymbol]}
					</Headline>
				</div>
			</div>
			<Button
				text={t('exchangePage.exchange')}
				onClick={handleSubmit(() => modalHandler(true))}
				disabled={conversionProcess || !!errors.value || changeValue == 0}
				fill
			/>
		</form>
		
		<ModalConfirmExchange
			confirmAction={conversionHandler}
			onClose={() => modalHandler(false)}
			isOpen={openConfirmModal}
			balance={maxValue}
			symbol={fromSymbol}
			changeValue={+getValues('value')}
		/>
		<ModalSuccessExchange
			value={resultChangedValue}
			symbol={tokensLabel[toSymbol]}
			isOpen={!!resultChangedValue}
			onClose={closeSuccessModal}
		/>
	</>;
};

export const ExchangeForm = React.memo(Form);
