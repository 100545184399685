import React, { useEffect } from 'react';
import { IConfirmRefLinksModal } from './confirmUpdateRefLinks.types';
import { Modal } from '../Modal';
import styles from './confirmUpdateRefLinks.module.scss';
import { Button, Headline, Input } from 'forly-kit';
import cn from 'classnames';
import { forexKeysAPI } from 'services/forexKeysService';
import { useTranslation } from 'react-i18next';

const ModalComponent: React.FC<IConfirmRefLinksModal> = ({
	isOpen,
	onClose,
	params
}) => {
	const {t} = useTranslation();
	const [updateLinks, {isLoading: updatePending, isSuccess: successUpdate}] = forexKeysAPI.useUpdateKeysMutation();
	const [createLinks, {isLoading: createPending, isSuccess: successCreate}] = forexKeysAPI.useCreateLeadersMutation();

	useEffect(() => {
		if (successUpdate || successCreate) {
			onClose();
		}
	}, [successUpdate, successCreate]);
	const updateLinksHandler = () => {
		const {id, link, binanceLink, roboLink, type} = params;

		if (!id) {
			createLinks({
				params: {
					link_binance: binanceLink,
					link,
					link_robo: roboLink
				}
			});
			return;
		}

		if (type === 'binance') {
			updateLinks({
				id,
				params: {
					link_binance: binanceLink
				}
			});
		} else {
			updateLinks({
				id,
				params: {
					link,
					link_robo: roboLink
				}
			});
		}
	};

	if (!isOpen) {
		return null;
	}

	return <Modal onClose={onClose}>
		<div className={styles.modalWrapper}>
			<Headline type='h2'>
				{t('partners.confirmLinkUpdate')}
			</Headline>
			<div className={cn({
				[styles.contentBinance]: params.type === 'binance',
				[styles.contentRobo]: params.type === 'robo'
			})}>
				{params.type === 'binance' && <div className={styles.linkBlock}>
					<Headline type='h4'>
						{t('partners.myReferralLinkBinance')}
					</Headline>
					<div className={styles.linkValue}>
						{params.binanceLink || ''}
					</div>
				</div>}

				{params.type === 'robo' && <>
					<div className={styles.linkBlock}>
						<Headline type='h4'>
							{t('partners.linkToForex')}
						</Headline>
						<div className={styles.linkValue}>
							{params.roboLink}
						</div>
					</div>
					<div className={styles.linkBlock}>
						<Headline type='h4'>
							{t('partners.linkToTraiderForex')}
						</Headline>
						<div className={styles.linkValue}>
							{params.link}
						</div>
					</div>
				</>
				}
			</div>

			<div className={styles.footer}>
				<Button variant='primary' text={t('confirmChange')} onClick={updateLinksHandler} disabled={updatePending || createPending} />
				<Button text={t('cancelAction')} variant='secondary' onClick={onClose} disabled={updatePending || createPending}/>
			</div>
		</div>
	</Modal>;
};

export const ConfirmUpdateRefLinksModal = React.memo(ModalComponent);
