import React, { useState } from 'react';
import styles from './instructionsLinks.module.scss';
import { Headline, Text } from 'forly-kit';
import { FileIcon, PlayIcon } from 'forly-kit/dist/icons';
import { colors } from 'forly-kit/dist/colors';
import { IFileInstruction, IInstructionsLinks, IModalInstruction } from './instructionsLinks.types';
import { useTranslation } from 'react-i18next';

const Component: React.FC<IFileInstruction | IModalInstruction> = ({
	video,
	file,
	Instruction
}) => {
	const [isOpenInstruction, setIsOpenInstruction] = useState<boolean>(false);
	const {t} = useTranslation();

	const instructionToggle = () => {
		setIsOpenInstruction((prev) => !prev);
	};

	return <>
		<div className={styles.instructionBlock}>
			<Headline className={styles.blockTitle} type='h4'>
				{t('keys.apiKeysPlace')}
			</Headline>
			{video && <div className={styles.instructionLink}>
				<PlayIcon color={colors.primary700} />
				<Text size='button' color={colors.primary700}>
					{t('videoInstruction')}
				</Text>
			</div>
			}
			{(file || Instruction) &&
				<div className={styles.instructionLink} onClick={Instruction && instructionToggle}>
					<FileIcon color={colors.primary700} />
					<Text size='button' color={colors.primary700}>
						{t('textInstruction')}
					</Text>
				</div>
			}
		</div>

		{Instruction && <Instruction isOpen={isOpenInstruction} onClose={instructionToggle}/>}
	</>;
};

export const InstructionsLinks = React.memo(Component);
