import { EBalanceSymbol } from 'types/common.types';
import { BusdIcon, LogoGradient } from 'forly-kit/dist/icons';
import React from 'react';
import { IIconProps } from 'forly-kit/dist/icons/icon.types';

export const symbolIcon: Record<EBalanceSymbol, React.FC<IIconProps>> = {
	[EBalanceSymbol.FT]: LogoGradient,
	[EBalanceSymbol.USDT]: BusdIcon,
	[EBalanceSymbol.BUSD]: BusdIcon,
	[EBalanceSymbol.BTC]: BusdIcon,
	[EBalanceSymbol.ETH]: BusdIcon
};
