import React, { useEffect } from 'react';
import styles from './payCommissionModal.module.scss';
import cn from 'classnames';
import { IPayCommissionModal } from './payCommissionModal.types';
import { Modal } from '../../Modal';
import { Button, Headline, ValueBox } from 'forly-kit';
import { formatNumber } from '../../../../helpers/formatNumber';
import { EBalanceSymbol } from 'types/common.types';
import { colors } from 'forly-kit/dist/colors';
import { bankAPI } from 'services/bankService';
import { spotAPI } from 'services/spotService';
import { useTranslation } from 'react-i18next';

const ModalComponent: React.FC<IPayCommissionModal> = ({
	isOpen,
	onClose,
	commissionFCC,
	commissionBUSD,
	balanceBUSD,
	balanceFCC,
	commissionID,
	payCommission,
	isPending,
	isSuccessPay,
	keyId
}) => {
	const {t} = useTranslation();
	const {refetch: refetchBalance} = bankAPI.useFetchBalanceQuery(null);
	const {refetch: refetchKey} = spotAPI.useFetchSpotKeyByIdQuery(keyId);

	useEffect(() => {
		if (isSuccessPay) {
			refetchBalance();
			refetchKey();
		}
	}, [isSuccessPay]);

	const payCommissionHandler = (currency: EBalanceSymbol) => {
		payCommission({
			id: commissionID,
			currency
		});
	};

	if (!isOpen) {
		return null;
	}

	return <Modal onClose={onClose}>
		<div className={styles.modalWrapper}>
			<Headline className={cn(styles.title)} type='h3'>
				{t('keys.commissions.payCommissionConfirmTitle')}
			</Headline>

			<div className={styles.content}>
				<ValueBox
					title={t('keys.commissions.commissionFromPeriod', {token: 'FCC'})}
					value={formatNumber(commissionFCC, 3)}
				/>
				<ValueBox
					title={t('keys.commissions.commissionFromPeriod', {token: 'BUSD'})}
					value={formatNumber(commissionBUSD, 3)}
				/>
				<div className={styles.balance}>
					<ValueBox title={t('balanceToken', {token: 'FCC'})} value={formatNumber(balanceFCC, 3)} />
				</div>
				<div className={styles.balance}>
					<ValueBox title={t('balanceToken', {token: 'BUSD'})} value={formatNumber(balanceBUSD, 3)} />
				</div>
			</div>
			<div className={styles.footer}>
				<Button
					variant='secondary'
					text={t('payFromToken', {token: 'FCC'})}
					fill
					onClick={() => payCommissionHandler(EBalanceSymbol.FT)}
					disabled={isPending}
				/>
				<Button
					variant='secondary'
					text={t('payFromToken', {token: 'BUSD'})}
					fill
					onClick={() => payCommissionHandler(EBalanceSymbol.USDT)}
					disabled={isPending}
				/>
			</div>
		</div>
	</Modal>;
};

export const PayCommissionModal = React.memo(ModalComponent);
