import React, { useMemo } from 'react';
import styles from './tagTab.module.scss';
import cn from 'classnames';
import { ITagTab } from './TagTab.types';
import { colors } from 'forly-kit/dist/colors';
import { Headline } from 'forly-kit';

const Component: React.FC<ITagTab> = ({
	className,
	isActive,
	keyTab,
	Icon,
	title,
	click
}) => {

	const color = useMemo(() => {
		return isActive ? colors.white : colors.primary700;
	}, [isActive]);

	const clickHandler = () => {
		click(keyTab);
	};

	return <div
		className={cn(className, styles.tagTab, {
			[styles.active]: isActive,
			[styles.noIcon]: !Icon
		})}
		onClick={clickHandler}
	>
		{Icon && <Icon className={styles.icon} color={color}/>}
		
		<Headline type='h4' color={color}>
			{title.toUpperCase()}
		</Headline>
	</div>;
};

export const TagTab = React.memo(Component);
