import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProfileState } from 'stateTypes/profileState';
import { IProfile } from 'types/profile.types';

const initialState: IProfileState = {
	profile: null
};

export const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		setProfile: (state, action: PayloadAction<IProfile>) => {
			state.profile = action.payload;
		}
	}
});

export const {setProfile} = profileSlice.actions;
export default profileSlice.reducer;
