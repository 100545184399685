import React, { useRef } from 'react';
import styles from './productCard.module.scss';
import cn from 'classnames';
import { IProductCard } from './productCard.types';
import { Button, Headline, Text } from 'forly-kit';
import { DownArrowIcon, ShareIcon } from 'forly-kit/dist/icons';
import { colors } from 'forly-kit/dist/colors';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ProductCard: React.FC<IProductCard> = ({
	name,
	profit,
	deposit,
	activation,
	commission,
	description,
	videoLink,
	productLink
}) => {
	const {t, i18n} = useTranslation();

	const contentRef = useRef<HTMLDivElement>(null);

	const navigate = useNavigate();

	const expandContent = () => {
		if (!contentRef.current) {
			return;
		}

		if (contentRef.current.hasAttribute('style')) {
			contentRef.current.setAttribute('style', 'height: 0px; margin-top: 24px');
			setTimeout(() => {
				contentRef?.current?.removeAttribute('style');
			}, 230);

		} else {
			const height = contentRef.current.scrollHeight;
			contentRef.current.setAttribute('style', `height: ${height}px; margin-top: 24px`);
		}
	};

	const switchToProduct = () => {
		navigate(`/${i18n.language}/${productLink}/`);
	};

	return <div className={cn('card cardContent', styles.productCard)}>
		<Headline type='h2' className={styles.header}>
			{name}
		</Headline>
		<div onClick={expandContent} className={styles.mobileHeader}>
			<Headline type='h2' className={styles.header}>
				{name} <DownArrowIcon className={styles.icon} color={colors.primary700} />
			</Headline>
		</div>

		<div ref={contentRef} className={styles.content}>
			<div className={styles.parameters}>
				<div className={styles.parameterItem}>
					<Headline type='h4'>{t('historyProfit')}:</Headline>
					<Headline type='h4' className={styles.gradientText}>{profit}</Headline>
				</div>

				<div className={styles.parameterItem}>
					<Headline type='h4'>{t('minDeposit')}:</Headline>
					<Headline type='h4' className={styles.gradientText}>{deposit} BUSD</Headline>
				</div>

				<div className={styles.parameterItem}>
					<Headline type='h4'>{t('activationPrice')}:</Headline>
					<Headline type='h4' className={styles.gradientText}>{activation} BUSD</Headline>
				</div>

				<div className={styles.parameterItem}>
					<Headline type='h4'>{t('commissionOfCopy')}:</Headline>
					<Headline type='h4' className={styles.gradientText}>{commission}%</Headline>
				</div>
			</div>
			<div className={styles.description}>
				<Headline type='h3'>
					Условия торговли:
				</Headline>
				<ul>
					{description?.map((d, idx) => {
						return <li key={idx}>
							<Text size='m' >
								{d}
							</Text>
						</li>;
					})}
				</ul>
			</div>

			{/*<div className={styles.description}>*/}
			{/*	{description?.map((d, idx) => {*/}
			{/*		return <Text size='m' key={idx}>*/}
			{/*			{d}*/}
			{/*		</Text>;*/}
			{/*	})}*/}
			{/*</div>*/}
			<a className={styles.videoLink} href={videoLink} target='_blank' rel='noreferrer'>
				<Text size='button' color={colors.primary700}>
					{t('showProductVideo')}
				</Text>
				<ShareIcon color={colors.primary700} />
			</a>
		</div>
		<Button
            disabled
			className={styles.goBtn}
			text={t('goTo')}
			variant='secondary'
			icon={{component: DownArrowIcon, position: 'right'}}
			fill
			onClick={switchToProduct}
		/>
	</div>;
};

// export const ProductCard = React.memo(Component);
