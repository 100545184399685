import React from 'react';
import styles from './transactionCard.module.scss';
import cn from 'classnames';
import { ITransactionCard } from './transactionCard.types';
import { Headline, Text } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';
import { EBalanceSymbol } from 'types/common.types';
import { TransactionDirectionIcon } from 'forly-kit/dist/icons';
import { formatNumber } from '../../helpers/formatNumber';
import { useTranslation } from 'react-i18next';

const Component: React.FC<ITransactionCard> = ({
	value,
	user,
	token,
	time,
	type,
	id
}) => {

	const {t} = useTranslation();

	return <div className={cn('card', styles.transactionCard)}>
		<div className={styles.header}>
			<div className={styles.nameBlock}>
				<TransactionDirectionIcon
					color={value > 0 ? colors.success : colors.danger}
					className={cn({
						[styles.revertIcon]: value <= 0
					})}
				/>
				<Headline type='h4'>
					{user}
				</Headline>
			</div>
			<Headline type='h4' color={value > 0 ? colors.success : colors.danger}>
				{value > 0 ? '+' : ''}{formatNumber(value, 2)} {token === EBalanceSymbol.FT ? 'FCC' : 'BUSD'}
			</Headline>
		</div>
		<div className={styles.transactionInfo}>
			<div className={styles.transactionInfoItem}>
				<Text size='s' color={colors.neutral500}>
					{t('date_and_time')}:
				</Text>
				<Text size='s'>
					{time}
				</Text>
			</div>
			<div className={styles.transactionInfoItem}>
				<Text size='s' color={colors.neutral500}>
					{t('operation_type')}:
				</Text>
				<Text size='s'>
					{type}
				</Text>
			</div>
			<div className={styles.transactionInfoItem}>
				<Text size='s' color={colors.neutral500}>
					{t('transaction_id')}:
				</Text>
				<Text size='s'>
					{id}
				</Text>
			</div>
		</div>


	</div>;
};

export const TransactionCard = React.memo(Component);
