import React, { useEffect } from 'react';
import {Outlet} from 'react-router-dom';
import styles from './layout.module.scss';
import { DesktopMenu } from '../Menu/DesktopMenu';
import { Header } from './Header/Header';
import { Footer } from './Footer/Footer';

export const Layout: React.FC = () => {
	return <div className={styles.layout}>
		<Header className={styles.header}/>
		<div className={styles.desktopMenu}>
			<DesktopMenu />
		</div>
		<div className={styles.content}>
			<Outlet />
		</div>

		<div className={styles.footer}>
			<Footer />
		</div>
	</div>;
};
