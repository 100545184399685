import React from 'react';
import styles from './ConfirmModal.module.scss';
import { Modal } from '../Modal';
import { IConfirmModal } from './ConfirmModal.types';
import { Button, Headline, Text } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';

const Component: React.FC<IConfirmModal> = ({
	isOpen,
	onClose,
	title,
	subtitle,
	confirm,
	error,
	isLoading
}) => {
	if (!isOpen) {
		return null;
	}

	return <Modal
		onClose={onClose}
	>
		<>
			<div className={styles.wrapper}>
				<Headline type='h2'>{title}</Headline>
				<Text size='m' color={colors.neutral600}>{subtitle}</Text>
				<Button variant='primary' text='OK' onClick={confirm} disabled={isLoading} />
			</div>
			{error && <Text size='m' color={colors.danger}>
				{error}
			</Text>}
		</>

	</Modal>;
};

export const ConfirmModal = React.memo(Component);