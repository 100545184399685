// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".balanceCard--DDUIh{display:grid;grid-template-columns:1fr;row-gap:8px}\n", "",{"version":3,"sources":["webpack://./../src/components/BalanceCardSidebar/balanceCardSidebar.module.scss"],"names":[],"mappings":"AAIA,oBACE,YAAa,CACb,yBAA0B,CAC1B,WAAY","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.balanceCard {\n  display: grid;\n  grid-template-columns: 1fr;\n  row-gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"balanceCard": "balanceCard--DDUIh"
};
export default ___CSS_LOADER_EXPORT___;
