import React, { useState } from 'react';
import styles from './futureOrders.module.scss';
import cn from 'classnames';
import { FutureOrder } from '../FutureOrder/FutureOrder';
import { Button, Headline } from 'forly-kit/dist';
import { futuresAPI } from './../../../services/futuresService';
import { Loader } from 'src/components/Loader/Loader';
import { IFutureOrdersProps } from './futureOrders.types';
import { ConfirmModal } from 'src/components/Modals/ConfirmModal/ConfirmModal';

export const FutureOrders: React.FC<IFutureOrdersProps> = ({
	closeAll,
	closeOrder
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const {data: orders, isLoading: ordersPending} = futuresAPI.useGetOrdersQuery(null);

	const [openCloseOrders, setOpenCloseOrders] = useState<boolean>(false);

	const closeAllOrders = () => {
		setIsLoading(true);
		closeAll();
		setTimeout(() => {
			setIsLoading(false);
			closeConfirmModal();
		}, 1000);
	};

	const showConfirmModal = () => {
		setOpenCloseOrders(true);
	};

	const closeConfirmModal = () => {
		setOpenCloseOrders(false);
	};

	return <>
		<div className={cn('card cardContent', styles.orderList)}>
			{ordersPending && <Loader internal/>}
			{orders && orders.length !== 0 ? <>
				<div className={styles.header}>
					<Headline type='h4'>
            Открытые ордера
					</Headline>
            
					<Button variant='tertiary' text='Закрыть все' onClick={showConfirmModal} />
				</div>
				{orders && orders.length !== 0 && orders.map((o) => <FutureOrder
					order={o}
					key={o.id}
					closeOrder={closeOrder}
				/>)}
			</>
				: <div className={styles.header}>
					<Headline type='h4'>
					Ордеров нет
					</Headline>
					<Button variant='tertiary' text='Закрыть все' onClick={showConfirmModal} />
				</div>
			}
		</div>
		
		<ConfirmModal 
			isOpen={openCloseOrders}
			onClose={closeConfirmModal}
			confirm={closeAllOrders}
			title='Закрыть ордера'
			subtitle='Все открытые ордера будут закрыты'
			error={''}
			isLoading={isLoading}
		/>
	</>;
};
