// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".createHoldWrapper--QSiGk{max-width:652px}.title--Satcz{margin-bottom:16px}.conditionList--CeMAy{padding-left:16px;margin-bottom:32px}.conditionList--CeMAy .condition--FNogW{display:inline-block}\n", "",{"version":3,"sources":["webpack://./../src/components/Modals/CreateHoldModal/createHoldModal.module.scss"],"names":[],"mappings":"AAIA,0BACE,eAAgB,CACjB,cAGC,kBAAmB,CACpB,sBAGC,iBAAkB,CAClB,kBAAmB,CAFrB,wCAKI,oBAAqB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.createHoldWrapper {\n  max-width: 652px;\n}\n\n.title {\n  margin-bottom: 16px;\n}\n\n.conditionList {\n  padding-left: 16px;\n  margin-bottom: 32px;\n\n  .condition {\n    display: inline-block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createHoldWrapper": "createHoldWrapper--QSiGk",
	"title": "title--Satcz",
	"conditionList": "conditionList--CeMAy",
	"condition": "condition--FNogW"
};
export default ___CSS_LOADER_EXPORT___;
