import React, { useState } from 'react';
import styles from './updateKeyForm.module.scss';
import { IUpdateFormData, IUpdateKeyForm } from './updateKeyForm.types';
import { useForm } from 'react-hook-form';
import { Button, Input } from 'forly-kit';
import { useTranslation } from 'react-i18next';

const Form: React.FC<IUpdateKeyForm> = ({
	update,
	cancel
}) => {
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm<IUpdateFormData>({
		mode: 'onChange',
	});

	const {t} = useTranslation();

	const [isUpdating, setIsUpdating] = useState<boolean>(false);

	const updateHandler = async (data: IUpdateFormData) => {
		setIsUpdating(true);
		await update(data);
		setIsUpdating(false);
	};

	return <form onSubmit={handleSubmit(update)}>
		<Input
			label={t('keys.fields.public')}
			register={() =>
				register('publicKey', {
					required: {
						value: true,
						message: t('errors.requiredField')
					},
				})
			}
			errorMsg={errors.publicKey?.message || ''}
			status={errors.publicKey ? 'error' : null}
		/>
		<Input
			label={t('keys.fields.secret')}
			register={() =>
				register('secretKey', {
					required: {
						value: true,
						message: t('errors.requiredField')
					},
				})
			}
			errorMsg={errors.secretKey?.message || ''}
			status={errors.secretKey ? 'error' : null}
		/>
		<div className={styles.footer}>
			<Button variant='primary' text={t('save')} type='submit' fill disabled={isUpdating} />
			<Button text={t('cancel')} variant='secondary' type='button' onClick={cancel} fill disabled={isUpdating} />
		</div>
	</form>;
};

export const UpdateKeyForm = React.memo(Form);
