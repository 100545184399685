// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--ckPIx{max-width:506px}.input--sBE48{margin:32px 0}.footer--A2OvB{display:grid;grid-template-columns:repeat(2, 1fr);column-gap:16px}\n", "",{"version":3,"sources":["webpack://./../src/components/Modals/ConfirmCode/confirmCode.module.scss"],"names":[],"mappings":"AAIA,gBACE,eAAgB,CACjB,cAGC,aAAc,CACf,eAGC,YAAa,CACb,oCAAqC,CACrC,eAAgB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.wrapper {\n  max-width: 506px;\n}\n\n.input {\n  margin: 32px 0;\n}\n\n.footer {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  column-gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--ckPIx",
	"input": "input--sBE48",
	"footer": "footer--A2OvB"
};
export default ___CSS_LOADER_EXPORT___;
