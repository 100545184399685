import React from 'react';
import cn from 'classnames';
import './loader.scss';
import { ILoader } from './loader.types';

export const Loader: React.FC<ILoader> = ({internal = false}) => {
	return	<div className={cn('loader-background', 'display-flex', {
		'internal': internal
	})}>
		<div className="loader-container">
			<div id="ld3" className="position-relative">
				<div className="absolute-0">
				</div>
				<div className="absolute-0">
				</div>
				<div className="absolute-0">
				</div>
				<div className="absolute-0">
				</div>
			</div>
		</div>
	</div>;
};
