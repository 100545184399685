import { Text } from 'forly-kit';
import React, { useEffect, useState } from 'react';
import styles from './tradingFutures.module.scss';
import { futuresAPI } from 'services/futuresService';
import { CreateFutureOrderForm } from 'src/components/Forms/CreateFutureOrderForm/CreateFutureOrderForm';
import { DropdownCard } from './../../components/DropdownCard/DropdownCard';
import { colors } from 'forly-kit/dist/colors';
import { FutureOrders } from './FutureOrders/FutureOrders';
import { Loader } from 'src/components/Loader/Loader';
import { FuturePositionsList } from './FuturePositionList/FuturePositionsList';
import { ManagementTrading } from './ManagementTrading/ManagementTrading';
import useWebSocket from 'react-use-websocket';
import { formatNumber } from '../../helpers/formatNumber';
import { ETradingEvent } from 'types/trading.types';

type Tab = 'orders' | 'positions' | 'management';

export const TradingFutures: React.FC = () => {
	const [activeTab, setActivTab] = useState<Tab>('orders');
	const [keyId, setKeyId] = useState<number | null>(null);
	const [symbolRender, setSymbolRender] = useState<symbol>(Symbol());

	const {data: keys, isLoading: keysLoading} = futuresAPI.useFetchFuturesKeysQuery(null);
	const {refetch: refetchOrders} = futuresAPI.useGetOrdersQuery(null);
	const {refetch: refetchPositions} = futuresAPI.useGetPositionsQuery(keyId || 0);

	const { sendJsonMessage, lastJsonMessage, getWebSocket } = useWebSocket(
		`${CONFIG.socketUrl}/ws/futures/`
	);

	useEffect(() => {
		return () => {
			const ws = getWebSocket();
			ws?.close();
		};
	}, [] );

	useEffect(() => {
		console.log(lastJsonMessage);
		setTimeout(() => {
			refetchOrders();
			refetchPositions();
		}, 5000);
	}, [lastJsonMessage]);

	useEffect(() => {
		if (keys && keys.length) {
			const key = keys.find((k: any) => k.is_active);
			if (key) {
				setKeyId(key.id);
			}
		}
	}, [keys]); 

	const changeTab = (tab: Tab) => () => {
		setActivTab(tab);
	};

	const rerenderDropdown = () => {
		setSymbolRender(Symbol());
	};

	const createOrder = (data: any) => {
		sendJsonMessage({
			message_name: ETradingEvent.CREATE_ORDER,
			...data,
			connected_to: formatNumber((new Date()).getTime()/1000, 0)
		});
	};

	const closeOrder = (connected_to: number, type: string) => {
		sendJsonMessage({
			message_name: ETradingEvent.CANCEL_ORDER,
			connected_to,
			type
		});
	};

	const closeAll = () => {
		sendJsonMessage({
			message_name: ETradingEvent.CANCEL_ORDERS
		});
	};

	const closeAllPositions = () => {
		sendJsonMessage({
			message_name: ETradingEvent.CANCEL_POSITIONS
		});
	};

	const closePosition = (symbol_name: string) => {
		sendJsonMessage({
			message_name: ETradingEvent.CANCEL_POSITION,
			symbol_name
		});
	};

	if (keysLoading) {
		return <Loader />;
	}

	return <>
		<div className='contentWrapper'>
			<div className='content'>
				<DropdownCard
					title='Выставление ордера'
					rerenderKey={symbolRender}
				>
					<CreateFutureOrderForm rerenderParent={rerenderDropdown} createOrder={createOrder}/>
				</DropdownCard>

				<div className={styles.tabs}>
					<span onClick={changeTab('orders')}>
						<Text size='m'
							color={activeTab === 'orders' ? colors.primary500 : colors.neutral600}
						>
                            Ордера
						</Text>
					</span>
					<span onClick={changeTab('positions')}>
						<Text size='m'
							color={activeTab === 'positions' ? colors.primary500 : colors.neutral600}
						>
                            Позиции
						</Text>
					</span>
					<span onClick={changeTab('management')}>
						<Text size='m'
							color={activeTab === 'management' ? colors.primary500 : colors.neutral600}
						>
                            Управление
						</Text>
					</span>
				</div>
                
				{activeTab === 'orders' && keyId && <FutureOrders id={keyId} closeAll={closeAll} closeOrder={closeOrder}/>}
				{activeTab === 'positions' && keyId &&
					<FuturePositionsList
						id={keyId}
						closeAll={closeAllPositions}
						closePosition={closePosition}
						createOrder={createOrder}
						closeOrder={closeOrder}
					/>}
				{activeTab === 'management' && <ManagementTrading keyId={keyId || 0}/>}
			</div>
		</div>
	</>;
};
