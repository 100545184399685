import React, { useMemo } from 'react';
import styles from './warning.module.scss';
import cn from 'classnames';
import { IWarning } from './warning.types';
import { colors } from 'forly-kit/dist/colors';
import { WarningIcon } from 'forly-kit/dist/icons';
import { Headline, Text } from 'forly-kit';

const Component: React.FC<IWarning> = ({
	color,
	text,
	title,
	className,
	isPs,
	theme = 'LIGHT',
	Icon
}) => {

	const IconComponent = Icon || WarningIcon;

	const colorStyle = useMemo(() => {
		return color || colors.primary900;
	}, [color]);

	return <div
		className={cn(className, styles.warning, {
			[styles.ps]: isPs,
			[styles.darkTheme]: theme==='DARK'
		})}
		style={{borderColor: colorStyle}}
	>
		{!isPs && <IconComponent color={theme === 'LIGHT' ? colorStyle : colors.white} className={styles.icon} />}

		<div>
			{title && <Headline type='h4' className={styles.title} color={theme === 'LIGHT' ? colorStyle : colors.white}>
				{title}
			</Headline>}
			{typeof text === 'string' 
				? <Text size='m' color={theme === 'LIGHT' ? colorStyle : colors.white}>
					{text}
				</Text> 
				: text.map((s, idx) => {
					return <>
						<Text size='m' color={theme === 'LIGHT' ? colorStyle : colors.white}>
							{s}
						</Text>
						{idx !== text.length - 1 &&
							<br />
						}
					</>;
				})
			}
		</div>
	</div>;
};

export const Warning = React.memo(Component);
