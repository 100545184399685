export enum ETradingEvent {
    CREATE_ORDER = 'order',
    CREATE_OCO_ORDER = 'oco_order',
    CANCEL_ORDER = 'cancel_order',
    CANCEL_ORDERS = 'cancel_orders',
    CANCEL_POSITION = 'cancel_position',
    CANCEL_POSITIONS = 'cancel_positions',
    SELL_ALL = 'sell_all'
}

export interface IOrder {
    symbol_name: string;
    side: string;
    type: string;
    price?: number;
    quantity?: number;
    stop_loss?: number;
    take_profit?: number;
    stop_price?: number;
    close_position?: boolean;
    connected_to?: number;
}

export interface IFutureOrder {
    id: number;
    side: string;
    price: number;
    stop_price?: number;
    close_position: boolean;
    orig_qty: number;
    symbol_name?: string;
    token_id: number;
    orig_type: string;
    status?: string;
    connected_to?: number;
}

export interface IFuturePosition {
    accumulated_realized: number;
    //объем
    amount: number;
    // цена входа
    entry_price: number;
    id: number
    // маржа
    isolated_wallet: number;
    stop_loss: null | IFutureOrder;
    symbol_name: string;
    take_profit: null | IFutureOrder;
    token_id: number
    // pnl
    unrealized_pnl: number;
}

export interface ISpotOrderForm {
    symbol: string;
    price: number;
    quantity: number;
    stop_loss: number;
    take_profit: string;
    stop_limit_price: number;
    // connected_to: number;
}

export interface ICreateSpotOrderData {
    symbol_name: string;
    price?: number;
    quantity: number;
    stop_loss?: number;
    take_profit?: number;
    stop_limit_price?: number;
    connected_to?: number;
}

export interface ISpotOrder {
    client_order_id: string;
    connected_to: null | number;
    created_by_bot: boolean;
    id: number;
    order_id: number;
    order_list_id: number;
    orig_qty: number;
    orig_quote_order_qty: number | null;
    price: number;
    side: string;
    status: string;
    stop_price: null
    symbol_name: string;
    token_id: number;
    transact_time: number;
    type: string;
    update_time: null | number;
}
