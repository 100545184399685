import React, { useMemo } from 'react';
import styles from './buyActivationModal.module.scss';
import { Modal } from '../Modal';
import { Button, Headline, Text } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';
import { BusdIcon, ForlyTokenIcon, PlusIcon } from 'forly-kit/dist/icons';
import { formatNumber } from '../../../helpers/formatNumber';
import { IBuyActivationModal } from './activationModals.types';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { userAPI } from 'services/userService';

const ModalComponent: React.FC<IBuyActivationModal>  = ({
	isOpen,
	onClose,
	fccPrice,
	busdPrice,
	fccBalance,
	busdBalance,
	buyClickHandler,
}) => {
	const {i18n, t} = useTranslation();

	const {data: profile} = userAPI.useFetchProfileQuery('');

	const enoughtFCCBalance = useMemo(() => {
		if (fccBalance) {
			return fccBalance.value_free >= fccPrice;
		}
		return false;
	}, [fccBalance, fccPrice]);

	const enoughtBUSDBalance = useMemo(() => {
		if (busdBalance) {
			return  busdBalance.value_free >= busdPrice;
		}
		return false;
	}, [busdBalance, busdPrice]);

	const buyHandler = () => {
		if (busdBalance) {
			buyClickHandler(busdBalance, busdPrice);
		}
	};

	if (!isOpen) {
		return null;
	}

	return <Modal onClose={onClose}>
		<div className={styles.modalWrapper}>
			<div className={styles.header}>
				<Headline type='h3'>
					{t('keys.buyActivationModal.title1')}<br />
					{t('keys.buyActivationModal.title2')}
				</Headline>
				<Text size='m' color={colors.neutral600}>
					{t('keys.buyActivationModal.subtitle')}
				</Text>
			</div>

			<div className={styles.content}>
				<div className={styles.priceBlock}>
					<Headline className={styles.priceTitle} type='h4' color={colors.neutral600}>
						{t('keys.activationPrice')}
					</Headline>
					<Headline type='h1' className={styles.itemValue}>
						≈ {formatNumber(fccPrice, 2)}
						<ForlyTokenIcon className={styles.icon} color={colors.primary900} />
					</Headline>

					<Headline type='h1' className={styles.itemValue}>
						{formatNumber(busdPrice, 2)}
						<BusdIcon className={styles.icon} color={colors.primary900} />
					</Headline>
				</div>

				<div className={styles.balanceBlock}>
					<div className={styles.priceItem}>
						<Headline className={styles.balanceTitle} type='h4' color={colors.neutral600}>
							{t('balance')} FCC
						</Headline>
						<Headline type='h1'  className={styles.itemValue}>
							{fccBalance ? formatNumber(fccBalance.value_free, 0) : 0}
							<ForlyTokenIcon className={styles.icon} color={colors.primary900} />
							{!enoughtFCCBalance &&
								<NavLink className={styles.link} to={`/${i18n.language}/refil/`}>
									<Button variant='tertiary' text={t('refillBalance')} icon={{component: PlusIcon}} />
								</NavLink>
							}
						</Headline>
					</div>

					<div className={styles.priceItem}>
						<Headline className={styles.balanceTitle} type='h4' color={colors.neutral600}>
							{t('balance')} BUSD
						</Headline>
						<Headline type='h1' className={styles.itemValue}>
							{busdBalance ? formatNumber(busdBalance.value_free, 0) : 0}
							<BusdIcon className={styles.icon} color={colors.primary900} />
							{!enoughtBUSDBalance &&
								<NavLink className={styles.link} to={`/${i18n.language}/refil/`}>
									<Button variant='tertiary' text={t('refillBalance')} icon={{component: PlusIcon}} />
								</NavLink>
							}
						</Headline>

					</div>
				</div>
			</div>

			<div className={styles.footer}>
				<Button
					variant='primary'
					text={`${t('buyFrom')} FCC`}
					disabled={!enoughtFCCBalance && !profile?.white_list}
					onClick={fccBalance && (() => buyClickHandler(fccBalance, fccPrice))}
				/>
				<Button
					variant='primary'
					text={`${t('buyFrom')} BUSD`}
					disabled={!enoughtBUSDBalance && !profile?.white_list}
					onClick={buyHandler}
				/>
			</div>

		</div>
	</Modal>;
};

export const BuyActivationModal = React.memo(ModalComponent);
