import { RootState } from 'store/store';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { IForlyIndex } from 'types/common.types';

export const indexAPI = createApi({
	reducerPath: 'indexAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: `https://${CONFIG.indexApi}/index/`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).auth.token;
			if (token) {
				headers.set('authorization', `Bearer ${token}`);
			}
			return headers;
		}
	}),
	endpoints: (build) => ({
		getIndex: build.query<number[], null>({
			query: () => {
				const currentDay = new Date();
				const nextDay = new Date(currentDay);
				nextDay.setDate(nextDay.getDate() + 1);

				currentDay.setHours(0, 0, 0, 0);
				nextDay.setHours(0, 0, 0, 0);

				const start = currentDay.getTime() / 1000;
				const end = nextDay.getTime() / 1000;
				return {
					url: `/?start_date=${start}&end_date=${end}`,
				};
			},
			transformResponse(res: any[]) {
				const currentDay = new Date();
				const nextDay = new Date(currentDay);
				nextDay.setDate(nextDay.getDate() + 1);

				currentDay.setHours(0, 0, 0, 0);
				nextDay.setHours(0, 0, 0, 0);

				const start = currentDay.getTime() / 1000;
				const resData: IForlyIndex[] = [];
				const now = new Date(start * 1000);
				for (let i = 0; i < 24; i++) {
					const label = new Date(now.setHours(i, 0, 0, 0));
					const value = res.find(item => item.value_time > label.getTime() / 1000);
					if (value) {
						resData.push(value);
					}
				}

				const array = resData.map(el => +el.value);
				return array.map(el => +el.toFixed(2));
			}
		})
	})
});
