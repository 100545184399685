// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--NT_Rr{display:grid;grid-template-columns:1fr;row-gap:24px;justify-content:center;max-width:550px}.wrapper--NT_Rr p{text-align:center}\n", "",{"version":3,"sources":["webpack://./../src/components/Modals/ConfirmModal/ConfirmModal.module.scss"],"names":[],"mappings":"AAIA,gBACC,YAAa,CACb,yBAA0B,CAC1B,YAAa,CACb,sBAAuB,CACvB,eAAgB,CALjB,kBAQE,iBAAkB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.wrapper {\n\tdisplay: grid;\n\tgrid-template-columns: 1fr;\n\trow-gap: 24px;\n\tjustify-content: center;\n\tmax-width: 550px;\n\n\tp {\n\t\ttext-align: center;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--NT_Rr"
};
export default ___CSS_LOADER_EXPORT___;
