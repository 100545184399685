export enum EBalanceSymbol {
	FT = 'FT',
	BTC = 'BTC',
	ETH = 'ETH',
	USDT = 'USDT',
	BUSD = 'BUSD'
}

export enum EConfirmType {
	OnlyPhone = 'phone',
	OnlyTg = 'telegram',
	All = 'all'
}

export interface IGetCode {
	is_withdraw: boolean;
	type: string;
}

export interface IPageWrapper<T> {
	count: number;
	next: number | null;
	previous: number | null;
	results: T[];
}

export interface IForlyIndex {
	value: string;
	value_time: number;
}

export interface IHoldItem {
	id: number;
	value: number;
	created: number;
	period: number;
	transaction_income: {
		external: {
			blockchain_id: string
		}
	};
	count?: number;
}

export interface IReport {
	fc_income: number;
	ff_income: number;
	act_income: number;
	salary_outcome: number;
	tech_outcome: number;
	adm_outcome: number;
	market_outcome: number;
	fcc_rate: number;
	month: number;
	year: number;
	id: number;
	created: number;
	agent_outcome: number;
	value: number;
	is_distributed: boolean;}
