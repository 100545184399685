// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrapper--PIhV8{display:grid;grid-template-columns:1fr;justify-content:center}.table--TA0c5{max-width:360px;margin:24px auto;display:grid;grid-template-columns:1fr auto;justify-items:start;column-gap:16px;align-items:center}.table--TA0c5 .label--h1Wtv{font-weight:bold}.title--fiCn7,.subtitle--Bekrq{text-align:center}.ordersList--rmk6J{display:grid;grid-template-columns:repeat(2, 1fr);column-gap:16px;align-items:start}\n", "",{"version":3,"sources":["webpack://./../src/components/Modals/ConfirmFuturesOrderModal/confirmFuturesOrderModal.module.scss"],"names":[],"mappings":"AAIA,gBACC,YAAa,CACb,yBAA0B,CAC1B,sBAAuB,CACvB,cAGA,eAAgB,CAChB,gBAAiB,CACjB,YAAa,CACb,8BAA+B,CAC/B,mBAAoB,CACpB,eAAgB,CAChB,kBAAmB,CAPpB,4BAUE,gBAAiB,CACjB,+BAKD,iBAAkB,CAClB,mBAGA,YAAa,CACb,oCAAqC,CACrC,eAAgB,CAChB,iBAAkB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.wrapper {\n\tdisplay: grid;\n\tgrid-template-columns: 1fr;\n\tjustify-content: center;\n}\n\n.table {\n\tmax-width: 360px;\n\tmargin: 24px auto;\n\tdisplay: grid;\n\tgrid-template-columns: 1fr auto;\n\tjustify-items: start;\n\tcolumn-gap: 16px;\n\talign-items: center;\n\n\t.label {\n\t\tfont-weight: bold;\n\t}\n}\n\n.title,\n.subtitle {\n\ttext-align: center;\n}\n\n.ordersList {\n\tdisplay: grid;\n\tgrid-template-columns: repeat(2, 1fr);\n\tcolumn-gap: 16px;\n\talign-items: start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper--PIhV8",
	"table": "table--TA0c5",
	"label": "label--h1Wtv",
	"title": "title--fiCn7",
	"subtitle": "subtitle--Bekrq",
	"ordersList": "ordersList--rmk6J"
};
export default ___CSS_LOADER_EXPORT___;
