import React from 'react';
import styles from './updateKeyModal.module.scss';
import { IUpdateKeyModal } from './updateKeyModal.types';
import { Modal } from '../../Modal';
import { Headline, Text } from 'forly-kit';
import { IUpdateFormData } from '../../../Forms/UpdateKeyForm/updateKeyForm.types';
import { UpdateKeyForm } from '../../../Forms/UpdateKeyForm/UpdateKeyForm';
import { useTranslation } from 'react-i18next';

const ModalComponent: React.FC<IUpdateKeyModal> = ({
	isOpen,
	onClose,
	update
}) => {
	const {t} = useTranslation();

	if (!isOpen) {
		return null;
	}

	return <Modal onClose={onClose}>
		<div className={styles.updateKeysWrapper}>
			<div className={styles.header}>
				<Headline type='h2'>{t('keys.updateModal.title')}</Headline>
				<Text size='m'>
					{t('keys.updateModal.subtitle')}
				</Text>
			</div>
			<UpdateKeyForm update={update} cancel={onClose} />
		</div>
	</Modal>;
};

export const UpdateKeyModal = React.memo(ModalComponent);
