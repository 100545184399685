import React from 'react';
import styles from './forexKey.module.scss';
import { IForexKey } from './ForexKey.types';
import { forexKeysAPI } from 'services/forexKeysService';
import { Headline, ValueBox } from 'forly-kit';
import { Loader } from '../../../components/Loader/Loader';

const Component: React.FC<IForexKey> = ({
	id
}) => {
	const {data: key, isLoading: keyPending} = forexKeysAPI.useForexKeyByIdQuery(id);

	return <div className='card cardContent'>
		{keyPending &&  <Loader internal/> }
		{key && <>
			<Headline type='h3'>
				{key.name}
			</Headline>
			<div className={styles.divider}></div>
			<ValueBox value={key.key} title='Ваш счет на RoboForex'/>
		</>}

	</div>;
};

export const ForexKey = React.memo(Component);
