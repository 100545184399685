import React, { useEffect, useMemo, useState } from 'react';
import styles from './createHoldForm.module.scss';
import { ICreateHoldForm, ICreateHoldFormData } from './createHoldForm.types';
import { useForm } from 'react-hook-form';
import { Button, Headline, Input, Text } from 'forly-kit';
import { changeNumberInput } from '../../../helpers/patterns';
import { PeriodSwitcher } from '../../ui/PeriodSwitcher/PeriodSwitcher';
import { colors } from 'forly-kit/dist/colors';
import { bankAPI } from 'services/bankService';
import { Loader } from '../../Loader/Loader';
import { EBalanceSymbol } from 'types/common.types';
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../../helpers/formatNumber';
















const HoldForm: React.FC<ICreateHoldForm> = ({
	successAction
}) => {
	const {t} = useTranslation();
	const {data: commissions = []} = bankAPI.useFetchHoldCommissionsQuery(null);
	const {data: balances = []} = bankAPI.useFetchBalanceQuery(null);
	const {
		register,
		formState: { errors, isDirty },
		handleSubmit,
		setValue,
	} = useForm<ICreateHoldFormData>({
		mode: 'onChange',
	});

	const periodData = [
		{
			label: t('month_few', {value: 3}),
			value: 3,
		},
		{
			label: t('month_many', {value: 6}),
			value: 6,
		},
		{
			label: t('month_many', {value: 12}),
			value: 12,
		},
	];
	
	const [inputValue, setInputValue] = useState<number | string>(0);
	const [period, setPeriod] = useState<number>(periodData[0].value);
	
	useEffect(() => {
		setValue('value', inputValue);
	}, [inputValue]);
	
	const fccBalance = useMemo(() => {
		const balance = balances.find((b) => b.token_symbol === EBalanceSymbol.FT);
		return balance?.value_free || 0;
	}, [balances]);
	
	const inputStatus = useMemo(() => {
		if ((fccBalance < inputValue || inputValue) < 10 && isDirty) {
			return 'error';
		}
		
		if (inputValue >= 10) {
			return 'success';
		}
		
		return null;
	}, [fccBalance, inputValue]);
	
	const changeValueHandler = (e: any) => {
		const value = changeNumberInput(e.currentTarget.value);
		value === inputValue
			? setValue('value', value)
			: setInputValue(value);
	};
	
	const submitForm = (data: ICreateHoldFormData) => {
		successAction({
			value: +data.value,
			period,
		});
	};
	
	const changePeriodHandler = (period: number) => {
		setPeriod(period);
	};
	
	const currentCommission = React.useMemo(() => {
		const commission = commissions.find((c) => c.period === period);
		return commission?.value || 0;
	}, [period, commissions]);
	
	if (!commissions) {
		return <Loader />;
	}
	
	return <div className={styles.formWrapper}>
		<form
			className={styles.form}
			onSubmit={handleSubmit(submitForm)}
		>
			<div>
				<Input
					label={t('countFccToFroze')}
					register={() =>
						register('value', {
							required: 'required',
							pattern: /^[0-9.]+$/,
							max: {
								value: fccBalance,
								message: t('maxHoldValue', {value: formatNumber(fccBalance, 4)})
							},
							min: {
								value: 10,
								message: t('minHoldValue', {value: 10})
							},
							onChange: changeValueHandler,
							value: inputValue,
						})
					}
					status={inputStatus}
					errorMsg={errors.value?.message}
				/>
				<Headline
					type='h4'
					color={colors.primary900}
					className={styles.switcherTitle}
				>
					{t('frozenPeriodForm')}
				</Headline>
				<PeriodSwitcher data={periodData} onChange={changePeriodHandler} activePeriod={period} />
				<Button
					className={styles.submitButton}
					variant='primary'
					text={t('frozFCC')}
					disabled={!inputValue || !!errors.value}
					type='submit'
				/>
			</div>
			<div className={styles.counter}>
				<div className={styles.counterItem}>
					<Text size='s'>{t('comission')}</Text>
					<Text size='m'>1 BUSD</Text>
				</div>
				<div className={styles.counterItem}>
					<Text size='s'>{t('profitCoeff')}</Text>
					<Text size='m'>{currentCommission}</Text>
				</div>
				<div className={styles.counterItem}>
					<Text size='s'>{t('commonProfit')}</Text>
					<Text size='m'>{formatNumber(+inputValue * currentCommission * 0.0001, 4)} %</Text>
				</div>
			</div>
		</form>
	</div>;
};

export const CreateHoldForm = React.memo(HoldForm);
