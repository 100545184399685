import { managementApi } from 'api/management';
import { AppDispatch, RootState } from 'store/store';
import { setLastFCC } from 'store/slices/management';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { ILastFCC, IWithdraw, IWithdrawUpdateData } from 'types/management.types';
import { IHoldItem } from 'types/common.types';

export const managementAPI = createApi({
	reducerPath: 'managementAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: `https://${CONFIG.api}/management/`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).auth.token;
			if (token) {
				headers.set('authorization', `Bearer ${token}`);
			}
			return headers;
		}
	}),
	endpoints: (build) => ({
		fetchLastFT: build.query<number, null>({
			query: () => ({
				url: 'last_ft/',
			}),
			transformResponse: (response: ILastFCC) => {
				return +response.amount_out;
			}
		}),
		getHolds: build.query<IHoldItem[],any>({
			query: (params) => ({
				url: `holds/?limit=${params.limit}&skip=${params.skip}`
			})
		}),
		getNewWithdraws: build.query<IWithdraw[], any>({
			query: () => ({
				url: '/withdraws/'
			})
		}),
		getCheckWithdraws: build.query<IWithdraw[], any>({
			query: () => ({
				url: '/withdraws/?withdraw_status=CHECK'
			})
		}),
		getCanceledWithdraws: build.query<IWithdraw[], any>({
			query: () => ({
				url: '/withdraws/?withdraw_status=CANCELED'
			})
		}),
		updateWithdrawStatus: build.mutation<any, IWithdrawUpdateData>({
			query: (data) => ({
				url: `/withdraw/${data.id}/`,
				method: 'PUT',
				status: data.isAgree,
				comment: data.comment
			})
		})
	})
});
