import React, { useEffect, useState } from 'react';
import styles from './ManagementTrading.module.scss';
import cn from 'classnames';
import { futuresAPI } from './../../../services/futuresService';
import { Button, Headline, Text } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';
import { Loader } from 'src/components/Loader/Loader';

export const ManagementTrading: React.FC<{keyId: number}> = ({
	keyId
}) => {
	const {
		data: requestedKeys, 
		isFetching: requestKeysPending, 
		isLoading: requestKeysLoading, 
		refetch
	} = futuresAPI.useGetRequestedKeysQuery(null);
	const [activateKeys, {isLoading: activatePending}] = futuresAPI.useActivateKeyToTradingMutation();
	const [switchParams, {isLoading}] = futuresAPI.useSwitchParamsKeyMutation();

	const [queue, setQueue] = useState<number>(0);

	const activateKeysHandler = () => {
		if (requestedKeys) {
			setQueue(requestedKeys.length);
			requestedKeys.forEach(async (k: any) => {
				await activateKeys({
					id: k.id,
					isActive: !k.is_active
				});
				setQueue((prev) => prev - 1);
				if (!queue) {
					refetch();
				}
			});
		}
	};

	const swithcParamsHandler = () => {
		if (!keyId) return;
		switchParams(keyId);
	};

	if (requestKeysLoading) {
		return <Loader />;
	}

	return <>
		<div className={cn('card cardContent', styles.keysCard)}>
			{(activatePending || requestKeysPending) && <Loader internal/>}
			<div className={styles.header}>
				<Headline type='h3' color={colors.neutral600}>
					Новые заявки в пул
				</Headline>
				<Text size='m' color={colors.primary800}>
					{requestedKeys.length}
				</Text>
			</div>
			<Button variant='primary' text='Принять заявки в пул' fill onClick={activateKeysHandler} />
		</div>

		<div className={cn('card cardContent', styles.keysCard)}>
			{(activatePending || requestKeysPending) && <Loader internal/>}
			<div className={styles.header}>
				<Headline type='h3' color={colors.neutral600}>
					Скопировать настройки
				</Headline>
			</div>
			<Button variant='primary' text='Скопировать' fill onClick={swithcParamsHandler} disabled={isLoading} />
		</div>
	</>;
};
