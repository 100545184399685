import { Button, Headline, Text } from 'forly-kit';
import React from 'react';
import styles from './confirmFuturesOrderModal.module.scss';
import { Modal } from '../Modal';
import { IConfirmFuturesOrderProps } from './confirmFuturesOrderModal.types';
import { colors } from 'forly-kit/dist/colors';

type OrderType = 'LIMIT' | 'MARKET' | 'STOP_MARKET' | 'TAKE_PROFIT_MARKET';

const Component: React.FC<IConfirmFuturesOrderProps> = ({
	isOpen,
	onClose,
	order,
	createOrder,
	isPending,
	errorMessage
}) => {

	const orderTypes = {
		LIMIT: 'Лимитный',
		MARKET: 'Рыночный',
		STOP_MARKET: 'Стоп-лосс',
		TAKE_PROFIT_MARKET: 'Тейк-профит'
	};

	if (!isOpen || !order) {
		return null;
	}

	return <Modal
		onClose={onClose}
	>
		<div className={styles.wrapper}>
			<Headline
				type='h2'
				className={styles.title}
			>
            Выставление ордера
			</Headline>
			<Text
				size='m'
				className={styles.subtitle}
				color={colors.neutral400}
			>
            Будет создан ордер
			</Text>
			<div className={styles.ordersList}>
				{order && order.map((o) => {
					return <div className={styles.table} key={o.type}>
						<Text 
							size='m' 
							color={colors.neutral600}
							className={styles.label}
						>
                Тип
						</Text>
						<Text size='m'>
							{o.side === 'BUY' ? 'Покупка' : 'Продажа'}
						</Text>
            
						<Text 
							size='m' 
							color={colors.neutral600}
							className={styles.label}
						>
                Валютная пара
						</Text>
						<Text size='m'>
							{o.symbol_name}
						</Text>

						<Text 
							size='m' 
							color={colors.neutral600}
							className={styles.label}
						>
                Тип ордера
						</Text>
						<Text size='m'>
							{orderTypes[o.type as OrderType]}
						</Text>

						{o.price && <>
							<Text 
								size='m' 
								color={colors.neutral600}
								className={styles.label}
							>
                Цена
							</Text>
							<Text size='m'>
								{o.price}
							</Text>
						</>}

						<Text 
							size='m' 
							color={colors.neutral600}
							className={styles.label}
						>
                Объем
						</Text>
						<Text size='m'>
							{o.quantity ? `${o.quantity}%` : 'на позицию'}
						</Text>

						{o.stopLoss && <>
							<Text 
								size='m' 
								color={colors.neutral600}
								className={styles.label}
							>
                Стоп-лосс
							</Text>
							<Text size='m'>
								{o.stopLoss}
							</Text>
						</>}

						{o.takeProfit && <>
							<Text 
								size='m' 
								color={colors.neutral600}
								className={styles.label}
							>
                Тейк-профит
							</Text>
							<Text size='m'>
								{o.takeProfit}
							</Text>
						</>}

						{o.stop_price !== undefined && <>
							<Text 
								size='m' 
								color={colors.neutral600}
								className={styles.label}
							>
                Стоп-цена
							</Text>
							<Text size='m'>
								{o.stop_price}
							</Text>
						</>}
					</div>;
				})}
			</div>

			<Button 
				variant='primary'
				text='Выставить ордер'
				onClick={createOrder}
				disabled={isPending}
			/>
			<Text size='m' color={colors.danger}>
				{errorMessage}
			</Text>
		</div>
		
	</Modal>;
};

export const ConfirmFuturesOrderModal = React.memo(Component);
