import React, { useEffect, useMemo, useState } from 'react';
import styles from './main.module.scss';
import { Button, Headline, Text } from 'forly-kit';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate } from 'react-router-dom';
import { Loader } from '../../components/Loader/Loader';
import cn from 'classnames';
import { bankAPI } from 'services/bankService';
import { EBalanceSymbol } from 'types/common.types';
import { Balance } from '../../components/Balance/Balance';
import { symbolIcon } from '../../helpers/symbolIcons';
import { DownArrowIcon } from 'forly-kit/dist/icons';
import { ProductCard } from './components/ProductCard';
import { userAPI } from 'services/userService';

export const Main: React.FC = () => {
	const {t, i18n} = useTranslation();
	const navigate = useNavigate();
	const [showProfileWarning, setShowProfileWarning] = useState<boolean>(false);

	const { data: balances, isLoading: balanceLoading } =
		bankAPI.useFetchBalanceQuery(null);
	const {data: profile, isLoading: profilePending} = userAPI.useFetchProfileQuery('');

	useEffect(() => {
		if (!profile) {
			return;
		}
		if (!profile.phone && !profile.telegram_id) {
			setShowProfileWarning(true);
		}
	}, [profile]);

	const FCCBalance = useMemo(() => {
		return balances?.find((b) => b.token_symbol === EBalanceSymbol.FT);
	}, [balances]);

	const BUSDBalance = useMemo(() => {
		return balances?.find((b) => b.token_symbol === EBalanceSymbol.USDT);
	}, [balances]);

	const navigateToProfile = () => {
		navigate(`/${i18n.language}/profile/`);
	};

	if (balanceLoading || profilePending) {
		return <Loader />;
	}

	return <div className={cn('content', styles.content)}>
		{showProfileWarning &&
			<div className={cn('card cardContent', styles.profileCard)}>
				<Headline type='h2'>
					{t('startWorkProfile')}
				</Headline>
				<Button variant='primary' text={t('editProfile')} fill onClick={navigateToProfile} />
			</div>
		}
		<div className={styles.balances}>
			{FCCBalance &&
				<div className={cn('card cardContent', styles.balanceCard)}>
					<Balance
						symbol={FCCBalance.token_symbol}
						label='Forly Crypto Coin'
						value={FCCBalance.value_free}
						Icon={symbolIcon.FT}
					/>
					<NavLink to={`/${i18n.language}/finance/`} className={styles.balanceLink}>
						<Text size='button'>
							{t('goTo')}
						</Text>
						<DownArrowIcon className={styles.arrowIcon} />
					</NavLink>
				</div>
			}

			{BUSDBalance &&
				<div className={cn('card cardContent', styles.balanceCard)}>
					<Balance
						symbol={BUSDBalance.token_symbol}
						label='BUSD'
						value={BUSDBalance.value_free}
						Icon={symbolIcon.BUSD}
					/>
					<NavLink to={`/${i18n.language}/finance/`} className={styles.balanceLink}>
						<Text size='button'>
							{t('goTo')}
						</Text>
						<DownArrowIcon className={styles.arrowIcon} />
					</NavLink>
				</div>
			}
		</div>

		<span className={styles.divider}></span>

		<Headline type='h1'>
			{t('ourProducts')}
		</Headline>

		<div className={styles.products}>
			<ProductCard
				name='Boom 2.0'
				profit={t('averageMonth', {value: '15,3%'})}
				deposit={100}
				activation={300}
				commission={30}
				description={t('boom.description', {returnObjects: true})}
				videoLink='https://www.youtube.com/watch?v=rM8ech8zAXQ&t=1s'
				productLink='forex'
			/>

			<ProductCard
				name='Forly Crypto'
				profit={t('averageMonth', {value: '17,4%'})}
				deposit={300}
				activation={300}
				commission={30}
				description={t('crypto.description', {returnObjects: true})}
				videoLink='https://www.youtube.com/watch?v=-3jNPToJ5IE'
				productLink='spot'
			/>

			<ProductCard
				name='Forly Futures'
				profit='50—100%'
				deposit={300}
				activation={300}
				commission={30}
				description={t('futures.description', {returnObjects: true})}
				videoLink='https://www.youtube.com/watch?v=gtlw7-oNBnM'
				productLink='futures'
			/>
		</div>
	</div>;
};
