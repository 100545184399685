import React, { useEffect } from 'react';
import styles from './partnerCard.module.scss';
import { IPartnerCard } from './PartnerCard.types';
import cn from 'classnames';

// @ts-ignore
import avatar from 'src/assets/img/fakeAvatar.jpg';
import { Headline, Text } from 'forly-kit';
import { colors } from 'forly-kit/dist/colors';
import { activationKeysAPI } from 'services/activationKeysService';
import { FuturesLogoIcon, RoboforexLogoIcon, SpotIcon, SpotLogoIcon } from 'forly-kit/dist/icons';
import { Loader } from '../Loader/Loader';
import { useTranslation } from 'react-i18next';

const PartnerCardComponent: React.FC<IPartnerCard> = ({
	partner,
	className,
	isActive,
	level,
	click
}) => {
	const {t} = useTranslation();
	const [
		getActivations,
		{isLoading: isPendingActivations, data: activations}
	] = activationKeysAPI.useActivationsPartnerMutation();

	useEffect(() => {
		if (isActive) {
			getActivations(partner.user_profile.uuid);
		}
	}, [isActive]);

	return <div
		className={cn(className, styles.partnerCard, {[styles.active]: isActive})}
		onClick={() => click(partner, level)}
	>
		<img className={styles.avatar} src={partner.user_profile.avatar || avatar} />
		<Headline type='h4' color={isActive ? colors.white : colors.primary900}>
			{(!partner.user_profile.name && !partner.user_profile.surname)
				? t('noData')
				: `${partner.user_profile.name + ' ' || ''}${partner.user_profile.surname || ''}`
			}

		</Headline>
		<Text size='s' color={isActive ? colors.neutral200 : colors.neutral500}>
			{partner.user_profile.phone || t('noData')}
		</Text>
		<div className={styles.assets}>
			{isPendingActivations && <Loader internal /> }
			{!activations && <>
				<span className={styles.assetEmpty}></span>
				<span className={styles.assetEmpty}></span>
				<span className={styles.assetEmpty}></span>
			</>}
			{activations && <>
				<RoboforexLogoIcon color={activations.FRX ? 'red' : '#C1C2C3'} />
				<SpotLogoIcon color={activations.CRP ? '#F3BA2F' : '#C1C2C3'} />
				<FuturesLogoIcon color={activations.FTR ? '#F3BA2F' : '#C1C2C3'} />
			</>
			}
		</div>
	</div>;
};

export const PartnerCard = React.memo(PartnerCardComponent);
