// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".keysCard--QLSyB{width:50%;display:grid;grid-template-columns:1fr;row-gap:16px;position:relative}.header--rEoKp{display:grid;grid-template-columns:1fr auto;column-gap:16px}\n", "",{"version":3,"sources":["webpack://./../src/pages/TradingFutures/ManagementTrading/ManagementTrading.module.scss"],"names":[],"mappings":"AAIA,iBACC,SAAU,CACV,YAAa,CACb,yBAA0B,CAC1B,YAAa,CACb,iBAAkB,CAClB,eAGA,YAAa,CACb,8BAA+B,CAC/B,eAAgB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.keysCard {\n\twidth: 50%;\n\tdisplay: grid;\n\tgrid-template-columns: 1fr;\n\trow-gap: 16px;\n\tposition: relative;\n}\n\n.header {\n\tdisplay: grid;\n\tgrid-template-columns: 1fr auto;\n\tcolumn-gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"keysCard": "keysCard--QLSyB",
	"header": "header--rEoKp"
};
export default ___CSS_LOADER_EXPORT___;
