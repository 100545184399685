import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { RootState } from 'store/store';
import { IRefLinks, IRefLinksResponse } from 'types/partners.types';
import { IAddForexKeyFormData } from '../components/Forms/AddForexKeyForm/addForexKeyForm.types';
import { IForexKey, IForexKeysList } from 'types/apiKeys.types';

export const forexKeysAPI = createApi({
	reducerPath: 'forexKeysAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: `https://${CONFIG.api}/forex_keys/`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).auth.token;
			if (token) {
				headers.set('authorization', `Bearer ${token}`);
			}
			return headers;
		},
	}),
	tagTypes: ['links'],
	endpoints: (build) => ({
		forexKeys: build.query<IForexKeysList, null>({
			query: () => ({
				url: '',
			})
		}),
		forexKeyById: build.query<IForexKey, number>({
			query: (id: number) => ({
				url: `/${id}/`
			})
		}),
		fetchRefLinks: build.query<IRefLinks, null>({
			query: () => ({
				url: '/leaders/'
			}),
			providesTags: result => ['links'],
			transformResponse:
				(response: IRefLinksResponse) => response.results[0],
		}),
		createLeaders: build.mutation({
			query: (body) => ({
				url: '/leaders/',
				method: 'POST',
				body: body.params
			})
		}),
		updateKeys: build.mutation({
			query: (body) => ({
				url: `/leaders/${body.id}/`,
				method: 'PUT',
				body: body.params
			}),
			invalidatesTags: ['links']
		}),
		addKey: build.mutation({
			query: (body: IAddForexKeyFormData) => ({
				url: '',
				method: 'POST',
				body: {
					...body,
					is_delete: false
				}
			})
		})
	})
});
