import { RootState } from 'store/store';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { EBalanceSymbol, IPageWrapper } from 'types/common.types';
import {
	changeAutoExtensionResponse,
	IActivation,
	IActivationBuyRequest,
	IActivationCount,
	TActivationCount,
	TActivationList
} from 'types/activations.types';

export const activationKeysAPI = createApi({
	reducerPath: 'activationKeysAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: `https://${CONFIG.api}/activation_keys/`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).auth.token;
			if (token) {
				headers.set('authorization', `Bearer ${token}`);
			}
			return headers;
		}
	}),
	endpoints: (build) => ({
		fetchActivationList: build.query<TActivationList, null>({
			query: () => ({
				url: 'activations_list/'
			}),
			transformResponse(res: IPageWrapper<IActivation>): TActivationList {
				const {results: activations} = res;

				return activations.reduce((acc , item ):TActivationList => {
					return {
						...acc,
						[item.activation_type]: item
					};
				}, {} as TActivationList);
			}
		}),
		fetchActivationCount: build.query<TActivationCount, null>({
			query: () => ({
				url: 'count/'
			}),
			transformResponse(res: IActivationCount[]):TActivationCount {
				return res.reduce((acc, item) => {
					return {
						...acc,
						[item.activation_type]: item,
					};
				}, {} as TActivationCount);
			}
		}),
		buyActivation: build.mutation<any, IActivationBuyRequest>({
			query: (body) => ({
				url: '/user_activations/',
				method: 'POST',
				body,
			})
		}),
		autoExtension: build.mutation<changeAutoExtensionResponse, any>({
			query: (body) => ({
				url: `/user_activations/${body.id}/`,
				method: 'PATCH',
				body: {
					auto_extension: body.autoExtension
				},
			}),
		}),
		extensionActivation: build.mutation({
			query: (body) => ({
				url: `/activation_extension/${body.id}`,
				method: 'PATCH',
				body: {
					currency: EBalanceSymbol
				}
			})
		}),
		extensionExistingActivation: build.mutation({
			query: (id: number) => ({
				url: `/activation_change/${id}/`,
				method: 'PUT',
			})
		}),
		activationsPartner: build.mutation({
			query: (uuid: string) => ({
				url: `/referrals/${uuid}`,
				method: 'GET'
			})
		})
	})
});
