import { authApi } from 'api/auth';
import { getCookie, setCookie } from '../helpers/cookie';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import baseApi from 'api/base';

export const authService = {
	auth: (username: string, password: string, token: string): Promise<string | boolean> => {
		return authApi.auth(username, password, token)
			.then((res) => {
				const decoded = jwtDecode<JwtPayload>(res.token);
				const expired = decoded.exp ? decoded.exp*1000 : 0;
				setCookie('access', res.token, expired);
				baseApi.defaults.headers.common['authorization'] = 
					`Bearer ${getCookie('access')}`;
				return res.token;
			})
			.catch(() => {
				throw new Error('login failed');
			});
	},
	logout: () => {
		return authApi.logout().then(() => true);
	}
};
