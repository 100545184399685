// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gradientText--AEzXn{background:linear-gradient(92.68deg, #1488CC 0%, #1488CC .01%, #2B32B2 100%);-webkit-background-clip:text;-webkit-text-fill-color:transparent;background-clip:text;text-fill-color:transparent}.balance--djxc6{display:grid;grid-template-columns:16px auto;column-gap:4px;row-gap:8px;align-items:center;justify-items:start}.value--DNWoK{grid-column:1/-1}\n", "",{"version":3,"sources":["webpack://./../src/components/Balance/balance.module.scss"],"names":[],"mappings":"AAIA,qBACE,4EAA8E,CAC9E,4BAA6B,CAC7B,mCAAoC,CACpC,oBAAqB,CACrB,2BAA4B,CAC7B,gBAGC,YAAa,CACb,+BAAgC,CAChC,cAAe,CACf,WAAY,CACZ,kBAAmB,CACnB,mBAAoB,CACrB,cAGC,gBAAiB","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.gradientText {\n  background: linear-gradient(92.68deg, #1488CC 0%, #1488CC 0.01%, #2B32B2 100%);\n  -webkit-background-clip: text;\n  -webkit-text-fill-color: transparent;\n  background-clip: text;\n  text-fill-color: transparent;\n}\n\n.balance {\n  display: grid;\n  grid-template-columns: 16px auto;\n  column-gap: 4px;\n  row-gap: 8px;\n  align-items: center;\n  justify-items: start;\n}\n\n.value {\n  grid-column: 1/-1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"gradientText": "gradientText--AEzXn",
	"balance": "balance--djxc6",
	"value": "value--DNWoK"
};
export default ___CSS_LOADER_EXPORT___;
