import React, { useEffect, useState } from 'react';
import styles from './createFutureOrderForm.module.scss';
import { Button, Checkbox, Dropdown, Headline, Input, Text } from 'forly-kit/dist';
import { CurrencyAda, CurrencyBnb, CurrencyBtc, CurrencyEth, CurrencyLtc, CurrencyXrp } from 'forly-kit/dist/icons';
import { TagTab } from 'src/components/TagTab/TagTab';
import { IIcon } from './../../icons/icons.type';
import { ICreateOrderFormData } from './createFutureOrder.types';
import { useForm } from 'react-hook-form';
import { ITab } from 'src/components/Tabs/tabs.types';
import { Tabs } from 'src/components/Tabs/Tabs';
import { changeNumberInput } from 'src/helpers/patterns';
import { ICreateOrderData } from 'src/pages/TradingFutures/tradingFutures.types';
import { ConfirmFuturesOrderModal } from 'src/components/Modals/ConfirmFuturesOrderModal/ConfirmFuturesOrderModal';
import { IOrder } from 'types/trading.types';
import { ICreateSpotOrderForm } from '../CreateSpotOrderForm/CreateSpotOrderForm.types';

const tabs = [
	{
		keyTab: 'btcusdt',
		Icon: CurrencyBtc,
		title: 'btc/usdt'
	},
	{
		keyTab: 'ethusdt',
		Icon: CurrencyEth,
		title: 'eth/usdt'
	},
	{
		keyTab: 'ltcusdt',
		Icon: CurrencyLtc,
		title: 'ltc/usdt'
	},
	{
		keyTab: 'adausdt',
		Icon: CurrencyAda,
		title: 'ada/usdt'
	},
	{
		keyTab: 'xrpusdt',
		Icon: CurrencyXrp,
		title: 'xrp/usdt'
	},
	{
		keyTab: 'bnbusdt',
		Icon: CurrencyBnb,
		title: 'bnb/usdt'
	},
];

const actionTabs: ITab[] = [
	{
		id: 1,
		label: 'Купить',
		value: 'BUY',
	},
	{
		id: 2,
		label: 'Продать',
		value: 'SELL'
	}
];

const orderTabs: ITab[] = [
	{
		id: 1,
		label: 'Рыночный ордер',
		value: 'MARKET',
	},
	{
		id: 2,
		label: 'Лимитный ордер',
		value: 'LIMIT'
	},
	{
		id: 3,
		label: 'Стоп-лосс',
		value: 'STOP_MARKET'
	},
	{
		id: 4,
		label: 'Тейк-профит',
		value: 'TAKE_PROFIT_MARKET'
	}
];

const Form: React.FC<ICreateSpotOrderForm> = ({
	rerenderParent,
	createOrder
}) => {
	const [activePair, setActivePair] = useState<string>('');
	const [actionType, setActionType] = useState<ITab>(actionTabs[0]);
	const [orderType, setOrderType] = useState<ITab>(orderTabs[0]);
	const [takeProfitError, setTakeProfitError] = useState<string>('');
	const [stopLossError, setstopLossError] = useState<string>('');
	const [createOrderData, setCreateOrderData] = useState<ICreateOrderData[] | null>(null);
	const [isClosedPosition, setIsClosedPosition] = useState<boolean>(false);

	const [isLoading, setIsLoading] = useState<boolean>(false);

	const {
		register,
		formState: { errors, isValid, isDirty },
		setValue,
		handleSubmit,
		reset
	} = useForm<ICreateOrderFormData>({
		mode: 'onChange',
	});

	useEffect(() => {
		if (rerenderParent) {
			setTimeout(() => {
				rerenderParent();
			});
		}
	}, [orderType]);

	const clearErrors = () => {
		setTakeProfitError('');
	};

	const clickTabHandler = (key: string) => {
		setActivePair(key);
		setValue('pair', key);
	};

	const inputPairChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setActivePair(e.target.value);
	};

	const changeActionType = (type: string) => {
		clearErrors();
		const finded = actionTabs.find((t) => t.value === type);
		if (!finded) {
			return;
		}
		setActionType(finded);
	};

	const changeOrderType = (type: string | number) => {
		clearErrors();
		const finded = orderTabs.find((t) => t.value === type);
		if (!finded) {
			return;
		}
		setOrderType(finded);
	};

	const changeTakeProfitHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		clearErrors();
		const value = changeNumberInput(e.currentTarget.value);
		setValue('takeProfit', value);
	};

	const changeStopLossHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		clearErrors();
		const value = changeNumberInput(e.currentTarget.value);
		setValue('stopLoss', value);
	};

	const validateTakeProfit = ({takeProfit, stopLoss}: ICreateOrderFormData): boolean => {
		if (!+takeProfit || !+stopLoss) {
			return true;
		}

		if (actionType.value === 'BUY' && +takeProfit < +stopLoss) {
			setTakeProfitError('Тейк-профит не должен быть ниже Стоп-лосс');
			return false;
		}

		if (actionType.value === 'SELL' && +takeProfit > +stopLoss) {
			setTakeProfitError('Тейк-профит не должен быть выше Стоп-лосс');
			return false;
		}
		return true;
	};

	const validateStopLoss = ({takeProfit, stopLoss}: ICreateOrderFormData): boolean => {
		if (!+takeProfit || !+stopLoss) {
			return true;
		}

		if (actionType.value === 'BUY' && +takeProfit < +stopLoss) {
			setstopLossError('Стоп-лосс не должен быть выше Тейк-профит');
			return false;
		}

		if (actionType.value === 'SELL' && +takeProfit > +stopLoss) {
			setstopLossError('Стоп-лосс не должен быть ниже Тейк-профит');
			return false;
		}

		return true;
	};

	const generateDataToCreateOrder = (data: ICreateOrderData) => {
		setCreateOrderData([data]);
	};

	const finishCreateOrder = () => {
		setCreateOrderData(null);
	};

	const createOrderHandler = () => {
		if (!createOrderData) {
			return;
		}

		const {
			symbol_name,
			quantity,
			type,
			side,
			price,
			stopLoss,
			stop_price,
			takeProfit
		} = createOrderData[0];

		const order: IOrder = {
			symbol_name: symbol_name.toUpperCase(),
			side,
			type,
		};

		if (price) {
			order.price = +price;
		}

		if (quantity) {
			order.quantity = quantity;
		}

		if (stopLoss) {
			order.stop_loss = +stopLoss;
		}

		if (stop_price) {
			order.stop_price = +stop_price;
		}

		if (takeProfit) {
			order.take_profit = +takeProfit;
		}

		if (createOrder) {
			setIsLoading(true);
			createOrder(order);
			setTimeout(() => {
				finishCreateOrder();
				reset();
				setActivePair('');
				setIsLoading(false);
			}, 1000);
		}

	};

	const submitFormHandler = (data: ICreateOrderFormData) => {
		if (
			(orderType.value === 'LIMIT' || orderType.value === 'MARKET') && 
			(!validateTakeProfit(data) || !validateStopLoss(data))
		) {
			return;
		}

		const order: ICreateOrderData = {
			side: actionType.value,
			type: orderType.value,
			symbol_name: activePair,
		};

		if (orderType.value === 'STOP_MARKET' || orderType.value === 'TAKE_PROFIT_MARKET') {
			order.stop_price = data.stopPrice;
			if (isClosedPosition) {
				order.close_position = true;
			} else {
				order.quantity = +data.quantity;
			}
		} else {
			order.quantity = +data.quantity;
		}

		if (orderType.value === 'MARKET') {
			if (+data.stopLoss) {
				order.stopLoss = +data.stopLoss;
			}
			if (+data.takeProfit) {
				order.takeProfit = +data.takeProfit;
			}
		}

		if (orderType.value === 'LIMIT') {
			if (+data.stopLoss) {
				order.stopLoss = +data.stopLoss;
			}
			if (+data.takeProfit) {
				order.takeProfit = +data.takeProfit;
			}
			order.price = data.price;
		}

		generateDataToCreateOrder(order);
	};

	const changeClosedPositions = (value: boolean) => {
		setIsClosedPosition(value);
	};



	return <>
		<form
			className={styles.form}
			onSubmit={handleSubmit(submitFormHandler)}
		>
			<div className={styles.inputPairWrapper}>
				<Input 
					label='Валютная пара'
					register={() =>
						register('pair',{
							onChange: inputPairChangeHandler
						})
					}
				/>
				<div className={styles.pairTabs}>
					{tabs.map((tab) => {
						const Icon = tab.Icon as React.FC<IIcon>;
						return <TagTab 
							className={styles.tab}
							Icon={Icon}
							keyTab={tab.keyTab}
							title={tab.title}
							isActive={tab.keyTab === activePair}
							key={tab.keyTab}
							click={clickTabHandler}
						/>;
					})}
				</div>
			</div>

			<div className={styles.field}>
				<Headline type='h4'>
                Тип действия
				</Headline>

				<Tabs 
					tabs={actionTabs}
					activeTab={actionType}
					action={changeActionType}
				/>
			</div>
		
			<div className={styles.field}>
				<Headline type='h4'>
                Тип ордера
				</Headline>

				<Tabs 
					className={styles.typeTabs}
					tabs={orderTabs}
					activeTab={orderType}
					action={changeOrderType}
				/>

				<Dropdown 
					className={styles.dropdownTypes}
					data={orderTabs}
					activeItem={orderType}
					change={changeOrderType}
				/>
			</div>
		
			<div className={styles.inputsBlock}>
				{orderType.value === 'LIMIT' && <Input 
					label='Цена'
					register={() => register('price')}
				/>}
			
				<Input 
					label='Объем, %'
					register={() => register('quantity', {
						required: {
							value: true,
							message: 'Поле обязательно для заполнения'
						},
						max: {
							value: 100,
							message: 'максимум 100'
						},
						min: {
							value: 0.001,
							message: 'минимум 0.001'
						}
					})}
					status={errors.quantity ? 'error' : null}
					errorMsg={errors.quantity?.message || ''}
				/>
				{(orderType.value === 'LIMIT' || orderType.value === 'MARKET') && <>
					<Input 
						label='Тейк-профит'
						register={() => register('takeProfit', {
							onChange: changeTakeProfitHandler
						})}
						status={takeProfitError ? 'error' : null}
						errorMsg={takeProfitError || ''}
					/>
					<Input 
						label='Стоп-лосс'
						register={() => register('stopLoss', {
							onChange: changeStopLossHandler
						})}
						status={stopLossError ? 'error' : null}
						errorMsg={stopLossError || ''}
					/>
				</>
				}
			
				{
					(orderType.value === 'STOP_MARKET' || orderType.value === 'TAKE_PROFIT_MARKET') && <Input 
						label='Стоп-цена'
						register={() => register('stopPrice')}
					/>
				}
			</div>

			{(orderType.value === 'STOP_MARKET' || orderType.value === 'TAKE_PROFIT_MARKET') && <Checkbox 
				name='closePositionn'
				onChange={changeClosedPositions}
				checked={isClosedPosition}

			>
				<Text size='m'>Вся позиция</Text> 
			</Checkbox>
			}
        
			<Button 
				variant='primary' 
				text='Выставить ордер' 
				fill
				type='submit'
				disabled={!actionTabs || !isValid || !isDirty || !activePair}
			/>
		</form>

		<ConfirmFuturesOrderModal 
			isOpen={!!createOrderData}
			onClose={finishCreateOrder}
			order={createOrderData}
			createOrder={createOrderHandler}
			isPending={isLoading}
			errorMessage={''}
		/>
	</>;
};

export const CreateFutureOrderForm = React.memo(Form);
