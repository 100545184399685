// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header--WKc1i{display:flex;justify-content:center;padding:15px}\n", "",{"version":3,"sources":["webpack://./../src/components/HeaderCommon/headerCommon.module.scss"],"names":[],"mappings":"AAIA,eACE,YAAa,CACb,sBAAuB,CACvB,YAAa","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.header {\n  display: flex;\n  justify-content: center;\n  padding: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "header--WKc1i"
};
export default ___CSS_LOADER_EXPORT___;
