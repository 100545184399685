import React, { useState } from 'react';
import classNames from 'classnames';
import {format} from 'date-fns';
import styles from './header.module.scss';
import { IHeader } from '../layout.types';
import { useWindowSize } from '../../../hooks/useWindowSize';
import {
	BellIcon, ExitIcon,
	LogoBetaDesktopIcon,
	LogoBetaMobileIcon,
	LogoBetaTabletIcon
} from 'forly-kit/dist/icons';
import { colors } from 'forly-kit/dist/colors';
import {Text} from 'forly-kit';
import { userAPI } from 'services/userService';
import { useTranslation } from 'react-i18next';
import { getDateFnsLocale } from '../../../helpers/date';
import {LanguageSwitcher} from '../../LanguageSwitcher/LanguageSwitcher';
import { NavLink, useNavigate } from 'react-router-dom';
import { MobileMenu } from '../../Menu/MobileMenu';
import avatar from 'src/assets/img/fakeAvatar.jpg';
import { authService } from 'services/authService';
import { setCookie } from '../../../helpers/cookie';

const H: React.FC<IHeader> = ({className}) => {
	const {t, i18n} = useTranslation();
	const navigate = useNavigate();
	const {data: profile} =
		userAPI.useFetchProfileQuery('');
	const {windowSize} = useWindowSize();
	const classes = classNames(className, styles.header);
	const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

	const logout = async () => {
		const res = await authService.logout();
		if (res) {
			setCookie('access', '');
			window.location.href = `${CONFIG.sameSite}/${i18n.language}/login/`;
		}
	};
	
	const Logo = React.useMemo(() => {
		if (windowSize >= 1200) {
			return LogoBetaDesktopIcon;
		}
		
		if (windowSize >= 768) {
			return LogoBetaTabletIcon;
		}
		
		return LogoBetaMobileIcon;
	}, [windowSize]);
	
	const toggleMobileMenu = () => {
		setShowMobileMenu((prev) => !prev);	
	};

	const goToIndex = () => {
		navigate(`/${i18n.language}/`);
	};
	
	
	return <header className={classes}>
		<div className={styles.logo} onClick={goToIndex}>
			<Logo color={showMobileMenu ? colors.white : colors.primary900}/>
		</div>
		<Text className={styles.dateText} size='m' color={colors.primary900}>{
			t('date.today')}, {format(new Date(), 'PPP', {locale: getDateFnsLocale()})
		}
		</Text>
		<LanguageSwitcher className={styles.dropdown}/>
		<BellIcon className={styles.bellIcon} color={colors.primary700}/>
		{profile && <NavLink to={`/${i18n.language}/profile/`} className={styles.avatar}>
			<img src={profile.avatar || avatar} alt="" width={40} height={40}/>
			{/*<Text size='m' color={colors.primary900}>*/}
			{/*	{profile.name} {profile.surname}*/}
			{/*</Text>*/}
		</NavLink>
		}

		<div 
			className={classNames(styles.mobileMenuIcon, {
				[styles.active]: showMobileMenu
			})}
			onClick={toggleMobileMenu}
		>
			<span></span>
			<span></span>
			<span></span>
		</div>

		<div className={styles.exitIcon} onClick={logout}>
			<ExitIcon color={colors.primary700}/>
		</div>
		<MobileMenu isOpen={showMobileMenu} closeMenu={toggleMobileMenu}/>
	</header>;
};

export const Header = React.memo(H);
