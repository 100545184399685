// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tabs--uOYu6{display:grid;grid-template-columns:repeat(3, auto);column-gap:24px;align-items:center;justify-content:start;justify-items:start;margin:24px 0}.tabs--uOYu6 span{cursor:pointer}\n", "",{"version":3,"sources":["webpack://./../src/pages/TradingFutures/tradingFutures.module.scss"],"names":[],"mappings":"AAIA,aACC,YAAa,CACb,qCAAsC,CACtC,eAAgB,CAChB,kBAAmB,CACnB,qBAAsB,CACtB,mBAAoB,CACpB,aAAc,CAPf,kBAUE,cAAe","sourcesContent":["\n\t\t\t\t\t\t\t\t@import \"~forly-kit/dist/_colors.scss\";\n\t\t\t\t\t\t\t\t@import \"styles/_typograph.scss\";\n\t\t\t\t\t\t\t\n.tabs {\n\tdisplay: grid;\n\tgrid-template-columns: repeat(3, auto);\n\tcolumn-gap: 24px;\n\talign-items: center;\n\tjustify-content: start;\n\tjustify-items: start;\n\tmargin: 24px 0;\n\n\tspan {\n\t\tcursor: pointer;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabs": "tabs--uOYu6"
};
export default ___CSS_LOADER_EXPORT___;
