import { RootState } from 'store/store';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
	IChangeAutoCommissionRequest,
	ICommission,
	IPayCommissionRequest,
	ISpotBot,
	ISpotKey
} from 'types/apiKeys.types';
import { IAddKeyFormData } from '../components/Forms/AddKeyForm/addKeyForm.types';
import { IUpdateFormData } from '../components/Forms/UpdateKeyForm/updateKeyForm.types';
import { ICreateSpotOrderData, ISpotOrder } from 'types/trading.types';

export const spotAPI = createApi({
	reducerPath: 'spotAPI',
	baseQuery: fetchBaseQuery({
		baseUrl: `https://${CONFIG.api}/binance_keys/`,
		prepareHeaders: (headers, { getState }) => {
			const token = (getState() as RootState).auth.token;
			if (token) {
				headers.set('authorization', `Bearer ${token}`);
			}
			return headers;
		}
	}),
	tagTypes: ['key', 'keys'],
	endpoints: (build) => ({
		fetchSpotKeys: build.query<ISpotKey[], null>({
			query: () => ({
				url: '/keys/'
			}),
			providesTags: result => ['keys']
		}),
		fetchSpotKeyById: build.query<ISpotKey, number>({
			query: (id) => ({
				url: `/keys/${id}/`
			}),
		}),
		fetchCommissionsById: build.query<ICommission[], number> ({
			query: (id) => ({
				url: `/commissions/${id}/`,
				params: {
					is_paid: false
				}
			})
		}),
		changeAutoPayCommission: build.mutation<ISpotKey, IChangeAutoCommissionRequest>({
			query: (data) => ({
				url: `/auto_commission/${data.id}/`,
				method: 'PUT',
				body: {
					auto_commissions: data.autoCommission
				}
			}),
		}),
		startBot: build.mutation<ISpotBot, number>({
			query: (id) => ({
				url: `/bot/${id}/`,
				method: 'PUT',
			}),
			transformResponse(res: ISpotBot): ISpotBot {
				return ({
					id: res.id,
					is_activated: res.is_activated,
					is_active: res.is_active,
					is_blocked: res.is_blocked
				});
			},
			invalidatesTags: ['key']
		}),
		stopBot: build.mutation<ISpotBot, number>({
			query: (id: number) => ({
				url: `/bot/${id}/`,
				method: 'DELETE'
			}),
			transformResponse(res: ISpotBot): ISpotBot {
				return ({
					id: res.id,
					is_activated: res.is_activated,
					is_active: res.is_active,
					is_blocked: res.is_blocked
				});
			},
			invalidatesTags: ['key']
		}),
		addKey: build.mutation<Promise<any>, IAddKeyFormData>({
			query: (data) => ({
				url: '/keys/',
				method: 'POST',
				body: {
					name: data.name,
					binance_key: data.publicKey,
					binance_secret: data.secretKey
				}
			}),
			invalidatesTags: ['keys']
		}),
		updateKey: build.mutation<Promise<any>, IUpdateFormData & {id: number, name: string}>({
			query: (data) => ({
				url: `/keys/${data.id}/`,
				method: 'PUT',
				body: {
					name: data.name,
					binance_key: data.publicKey,
					binance_secret: data.secretKey
				}
			})
		}),
		payCommission: build.mutation<any, IPayCommissionRequest>({
			query: (data) => ({
				url: `/commissions/${data.id}/`,
				method: 'PUT',
				body: {
					currency: data.currency
				}
			})
		}),
		createSpotOrder: build.mutation<any, ICreateSpotOrderData>({
			query: (data) => ({
				url: '/spot_order/',
				method: 'POST',
				body: data
			})
		}),
		getSpotOrders: build.query<ISpotOrder[], null>({
			query: () => ({
				url: '/spot_order/'
			})
		}),
		deleteSpotOrder: build.mutation<any, number>({
			query: (id) => ({
				url: `/spot_order/${id}/`,
				method: 'DELETE'
			})
		}),
		sellAll: build.mutation<any, number>({
			query: (id) => ({
				url: `/sell_all/${id}/`,
				method: 'DELETE'
			})
		}),
		deleteOrders: build.mutation<any, number>({
			query: (id) => ({
				url: `/spot_orders/${id}/`,
				method: 'DELETE'
			})
		}),
		replicate: build.query<any, null>({
			query: () => ({
				url: '/replicate/',
			})
		}),
		replicateToken: build.mutation<any, {id: number, is_replicated: boolean}>({
			query: (data) => ({
				url: `/replicate/${data.id}/`,
				method: 'PUT',
				body: {
					is_replicated: data.is_replicated,
					is_requested: false,
				}
			})
		}),
		requestToReplicate: build.mutation<any, {id: number, is_replicated: boolean}>({
			query: (data) => ({
				url: `/request_replication/${data.id}/`,
				method: 'PUT',
				body: {
					is_requested: data.is_replicated
				}
			})
		}),
		getFilledOrders: build.query<ISpotOrder[], {pair?: string, offset?: number}>({
			query: (data) => {
				let url = '/filled_orders?';
				if (data.pair) {
					url += `pair=${data.pair}`;
				}
				if (data.offset) {
					url += `limit=${data.offset}`;
				}

				return {
					url
				};
			}
		})
	})
});
