import React, { useEffect, useState } from 'react';
import styles from './holds.module.scss';
import { managementAPI } from 'services/managementService';
import { Loader } from '../../components/Loader/Loader';
import { Paginator } from '../../components/Paginator/Paginator';
import { toDate, addMonths, format } from 'date-fns';
import { HeaderCommon } from '../../components/HeaderCommon/HeaderCommon';

export const HoldsPage: React.FC = () => {
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [lastPage, setLastPage]= useState<number>(0);
	const [holdParams, setHoldParams] = useState({
		limit: 10,
		skip: (currentPage - 1)*10
	});

	const {data: holds, isLoading: holdsPending, refetch} = managementAPI.useGetHoldsQuery(holdParams);

	useEffect(() => {
		if (holds) {
			const lastelem = holds[holds.length - 1];
			if (lastelem && lastelem.count) {
				setLastPage(Math.ceil(lastelem.count/10));
			}
		}
	}, [holds]);

	const changePage = (page: number) => {
		setCurrentPage(page);
		setHoldParams(prev => ({
			...prev,
			skip: (page - 1)*10
		}));
	};

	return <div className={styles.wrapper}>
		<HeaderCommon />
		<div className={styles.content}>
			{holdsPending && <Loader internal />}
			{holds && <Paginator lastPage={lastPage} currentPage={currentPage} changePage={changePage}>
				<table className={styles.holdTable}>
					<thead>
						<tr>
							<th className={styles.holdCell}>ID транзакции</th>
							<th className={styles.holdCell}>Объем, FCC</th>
							<th className={styles.holdCell}>Дата разморозки</th>
						</tr>
					</thead>
					{holds && <tbody>
						{holds.map((h, idx) => {
							if (h.count) {
								return;
							}
							return <tr key={h.id}>
								<td className={styles.holdCell}>
									<a
										href={`https://${CONFIG.bsscan}tx/${h.transaction_income.external.blockchain_id}`}
										className={styles.trxLink}
										target="_blank" rel="noreferrer"
									>
										{h.transaction_income.external.blockchain_id.slice(0, 5)}
									</a>
								</td>
								<td className={styles.holdCell}>{h.value}</td>
								{h.period !== 0
									? <td className={styles.holdCell} >
										{format(addMonths(toDate(h.created * 1000), h.period), 'dd.MM.yyyy')}
									</td>
									: <td className={styles.holdCell}>Бессрочно</td>
								}

							</tr>;
						})}
					</tbody>}
				</table>
			</Paginator>}
		</div>
	</div>;
};
