import jwtDecode, { JwtPayload } from 'jwt-decode';

export const checkToken = (token: string): number => {
	try {
		const {exp} = jwtDecode<JwtPayload>(token);
		const current = new Date().getTime();
		if (exp) {
			return exp*1000 - current;
		}
	} catch (e) {
		return 0;
	}
	return 0;
};
