import './styles.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import {App} from './pages/App/App';
import {
	BrowserRouter as Router,
} from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import 'forly-kit/dist/vars.css';
import './i18n';

ReactDOM.render(
	<GoogleReCaptchaProvider
		reCaptchaKey={CONFIG.captcha}
		scriptProps={{
			async: false, // optional, default to false,
			defer: true, // optional, default to false
			appendTo: 'head', // optional, default to "head", can be "head" or "body",
			nonce: undefined // optional, default undefined
		}}
	>
		<Provider store={store}>
			<Router>
				<App/>
			</Router>
		</Provider>
	</GoogleReCaptchaProvider>
	,
	document.getElementById('root'));
